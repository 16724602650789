<template>
  <div v-if="showForgotPasswordSuccesfulResponse" class="d-flex flex-column align-center gap-24">
    <VIcon class="far fa-envelope-circle-check text-primary" size="60" />
    <h4 class="">{{ strapi.LoginForgotPasswordResponseTitle }}</h4>
    <p>{{ strapi.LoginForgotPasswordResponseDescription }}</p>
  </div>
  <div v-else>
    <h4>{{ strapi.LoginForgotPasswordTitle }}</h4>
    <p>{{ strapi.LoginForgotPasswordDescription }}</p>
    <Form grid @submit.prevent="submit">
      <label for="forgotPasswordEmail">{{ strapi.Email }}</label>
      <EmailTextField id="forgotPasswordEmail" v-model="email" name="forgotPassword.email" autofocus :placeholder="strapi.Email" />
      <Button color="white" class="bg-success w-100" type="submit" :loading="loading" :disabled="loading">{{ strapi.LoginForgotPasswordSendLinkLabel }}</Button>
    </Form>
  </div>
</template>

<script>
import { useLoginStore } from '@Store/login';

export default {
  name: 'LoginForgotPasswordPartial',
  data() {
    return {
      loading: false,
      strapi: this.$dom.application.login,
      email: ''
    };
  },
  computed: {
    showForgotPasswordSuccesfulResponse() {
      return useLoginStore().shouldShowForgotPasswordSuccesfulResponse;
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      const response = await this.post('/forgot-password/', { forgotPassword: { email: this.email } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.$emit('onSuccessfulForgotPassword');
    }
  }
};
</script>

<style lang="scss" scoped></style>
