<template>
  <VExpansionPanel ref="expansionPanel" :bgColor="bgColor" class="px-2 px-md-16" elevation="0">
    <VExpansionPanelTitle expand-icon="far fa-plus" collapse-icon="far fa-minus" class="pa-0 py-7" :hide-actions="hideActions">
      <VIcon v-if="icon" :icon="icon" size="24" class="mr-4" />
      <slot v-if="hasSlot('title')" name="title" />
      <template v-else>
        <p class="paragraph -no-margin bold">{{ title }}</p>
      </template>
    </VExpansionPanelTitle>
    <VExpansionPanelText>
      <slot />
    </VExpansionPanelText>
  </VExpansionPanel>
</template>

<script>
export default {
  name: 'ExpansionPanel',
  props: {
    bgColor: {
      type: String,
      default: 'white'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-expansion-panel-title) {
  &:hover {
    i {
      color: $color-primary !important;
    }
  }
  .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }
  .v-expansion-panel-title__icon {
    .v-icon::before {
      height: 24px;
      width: 24px;
      font-size: 24px;
    }
  }
}
</style>
