<template>
  <VTextarea :model-value="modelValue" flat :type="type" hide-details="auto" :label="label" variant="solo" :error-messages="localErrorMessages" persistent-hint :bg-color="finalBgColor" no-resize :class="{ 'no-default-bg-color': !bgColor }" color="black" density="compact" persistent-placeholder clearable :rules="rules" @update:modelValue="$emit('update:modelValue', $event)">
    <slot />
  </VTextarea>
</template>

<script>
export default {
  name: 'Textarea', // eslint-disable-line
  props: {
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    },
    bgColor: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localErrorMessages: this.errorMessages,
      isDirty: false
    };
  },
  computed: {
    finalBgColor() {
      return this.bgColor || (this.isDirty ? 'grey-light' : 'grey-background');
    },
    rules() {
      return this.required ? [v => this.$utils.isNotBlank(v) || 'Field is required'] : [];
    }
  },
  watch: {
    errorMessages: {
      immediate: true,
      handler(newErrorMessages) {
        this.localErrorMessages = newErrorMessages;
      }
    },
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isDirty = this.$utils.isNotBlank(newValue);
        this.localErrorMessages = [];
      }
    }
  },
  created() {
    if (this.name) {
      this.$mitt.on(`set-error-messages-${this.name}`, errors => {
        this.localErrorMessages = errors;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-textarea--error) {
  background-color: $color-validation-red-light !important;
}
</style>
