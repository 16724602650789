<template>
  <div>
    <h2 class="d-none d-md-block h2 mb-10 mb-lg-14">{{ modelValue.label }}</h2>
    <h3 class="d-md-none h3 mb-10 mb-lg-14">{{ modelValue.label }}</h3>
    <ExpansionPanels v-show="!loading" v-model="panel">
      <File v-for="(file, index) in paginatedFiles" :key="index" :model-value="file" :icon="modelValue.icon" />
    </ExpansionPanels>
    <Pagination :model-value="paginationPage" :length="paginationPages" :total-visible="6" class="mt-14" @update:modelValue="onPaginate" />
  </div>
</template>

<script>
import Pagination from '@Components/globals/Pagination.vue';
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import File from '@Components/File.vue';

export default {
  name: 'Files',
  components: { Pagination, ExpansionPanels, File },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    previousTab: {
      type: String,
      default: undefined
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:loading'],
  data() {
    return {
      panel: null,
      itemPerPage: 10,
      paginationPage: 1
    };
  },
  computed: {
    paginatedFiles() {
      const startIndex = (this.paginationPage - 1) * this.itemPerPage;
      const endIndex = startIndex + this.itemPerPage;
      return this.modelValue.files?.slice(startIndex, endIndex) || [];
    },
    totalFiles() {
      return this.modelValue.files?.length || 0;
    },
    paginationPages() {
      return Math.max(Math.ceil(this.totalFiles / this.itemPerPage), 0);
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal === undefined) {
          this.paginationPage = parseInt(this.getURLParameter('page')) || 1;
        } else if (newVal) {
          this.paginationPage = 1;
        }

        if (newVal) {
          this.updateURLParameter('page', this.paginationPage);
        }
      }
    }
  },
  methods: {
    onPaginate(newPage) {
      this.panel = null;
      this.$emit('update:loading', true);
      setTimeout(() => {
        this.paginationPage = newPage;
        this.updateURLParameter('page', newPage);
        this.$emit('update:loading', false);
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped></style>
