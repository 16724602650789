import Snackbar from '@Components/Snackbar.vue';
import { isString, isObject } from '@Utils/default';

export default {
  install(Vue) {
    Vue.config.globalProperties.$snackbar = {
      send(message, options = {}, redirect) {
        Vue.config.globalProperties.$mitt.emit('snackbar', {
          message: isString(message) ? { body: message } : message,
          options: options,
          redirect: redirect
        });
      },
      success(message, options = {}, redirect) {
        this.send(
          {
            title: message.title || 'Success',
            body: isObject(message) ? message.body : message
          },
          { type: 'success', ...options },
          redirect
        );
      },
      warning(message, options = {}, redirect) {
        this.send(
          {
            title: message.title || 'Warning',
            body: isObject(message) ? message.body : message
          },
          { type: 'warning', ...options },
          redirect
        );
      },
      info(message, options = {}, redirect) {
        this.send(
          {
            title: message.title || 'Info',
            body: isObject(message) ? message.body : message
          },
          { type: 'info', ...options },
          redirect
        );
      },
      error(message, options = {}, redirect) {
        this.send(
          {
            title: message.title || 'Error',
            body: isObject(message) ? message.body : message
          },
          { type: 'error', timeOut: -1, ...options },
          redirect
        );
      }
    };

    Vue.component('Snackbar', Snackbar);
  }
};
