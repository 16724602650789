<template>
  <div class="w-100" :class="`h-${strapi.Height || 100}px bg-${strapi.BackgroundColor}`" />
</template>

<script>
export default {
  name: 'StrapiSpacer',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped></style>
