import { createApp, h } from 'vue';
import VueMarkdown from 'vue-markdown-render';

export default {
  install: Vue => {
    Vue.directive('markdown', {
      mounted(el, binding) {
        createMarkdownComponent(el, binding.value || '');
      },
      updated(el, binding) {
        if (binding.oldValue === binding.value) return;
        createMarkdownComponent(el, binding.value || '');
      }
    });
  }
};

function createMarkdownComponent(el, markdownText) {
  const options = {
    html: true,
    breaks: true
  };

  const appInstance = createApp({
    render() {
      return h(VueMarkdown, { source: markdownText, options });
    }
  });

  const component = appInstance.mount(document.createElement('div'));

  // Remove any existing children from the mounting element.
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }

  // Since we're using a fragment, append the children directly to the mounting element.
  Array.from(component.$el.childNodes).forEach(child => {
    el.appendChild(child);
  });
}
