<template>
  <div class="bg-transparent w-100 overflow-hidden">
    <ProductCategoriesSwiper :items="strapi.ProductCategories" />
  </div>
</template>

<script>
import ProductCategoriesSwiper from '@Components/ProductCategoriesSwiper.vue';

export default {
  name: 'ProductCategories',
  components: { ProductCategoriesSwiper },
  props: {
    strapi: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped></style>
