<template>
  <VDialog v-model="dialog" class="" width="auto">
    <template #activator="{ props }">
      <slot v-if="hasSlot('activator')" name="activator" v-bind="{ props }" />
    </template>
    <Card class="pa-6 max-w-800px">
      <div class="d-flex justify-center align-start flex-column">
        <VIcon v-if="icon" :icon="icon" :color="iconColor" size="40px" class="mx-auto" />
        <VCardTitle class="mt-3">
          <span v-if="title">{{ title }}</span>
          <slot v-else-if="hasSlot('title')" name="title" />
        </VCardTitle>
        <VCardText>
          <span v-if="description">{{ description }}</span>
          <slot v-else-if="hasSlot('description')" name="description" />
        </VCardText>
      </div>
      <VCardActions class="d-flex justify-center">
        <slot v-if="hasSlot('actions')" name="actions" />
        <template v-else-if="!noActions">
          <Button block color="secondary" variant="outlined" @click="onCancel">Cancel</Button>
          <Button class="bg-secondary" block color="white" @click="onConfirm">Continue</Button>
        </template>
      </VCardActions>
    </Card>
  </VDialog>
</template>

<script>
export default {
  name: 'Dialog', // eslint-disable-line
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'far fa-square-check'
    },
    iconColor: {
      type: String,
      default: 'primary'
    },
    noActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onCancel', 'onConfirm'],
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    onCancel() {
      this.dialog = false;
      this.$emit('onCancel');
    },
    onConfirm() {
      this.dialog = false;
      this.$emit('onConfirm');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card-title {
  font-size: 1.25rem;
  font-weight: 600;
}
.v-card-text {
  font-size: 1rem;
}
</style>
