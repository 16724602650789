<template>
  <div class="d-flex flex-wrap justify-space-between">
    <VImg :src="strapi.Logo.url" :alt="strapi.Logo.alternativeText" class="mr-6" max-width="105px" width="105px" height="50px" />
    <div class="d-flex flex-wrap">
      <VImg v-for="creditCard in strapi.CreditCards" :key="creditCard.id" class="ml-4" :src="creditCard.url" max-width="51px" width="51px" height="40px" :alt="creditCard.alternativeText" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripeInformation',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return { loading: false };
  }
};
</script>

<style lang="scss" scoped></style>
