<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10 overflow-visible" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerName">{{ strapi.NameLabel }}</label>
        <TextField id="customerName" v-model="customer.name" name="customer.name" autofocus :placeholder="strapi.NamePlaceholder" class="grid-item -col-span-12 -col-span-lg-4" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerWebsite">{{ strapi.WebsiteLabel }}</label>
        <WebsiteTextField id="customerWebsite" v-model="customer.web" name="customer.web" :placeholder="strapi.WebsitePlaceholder" class="grid-item -col-span-12 -col-span-lg-4" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerPhone">{{ strapi.PhoneLabel }}</label>
        <!-- <PhoneTextField id="customerPhone" v-model="customer.phone" name="customer.phone" :placeholder="strapi.PhonePlaceholder" class="grid-item -col-span-12 -col-span-lg-4" /> -->
        <TelInput id="customerPhone" v-model="customer.phone" name="customer.phone" :placeholder="strapi.PhonePlaceholder" :defaultCountry="$country.code" class="grid-item -col-span-12 -col-span-lg-4" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerCustomerContactEmail">{{ strapi.FinanceContactEmailLabel }}</label>
        <EmailTextField id="customerCustomerContactEmail" v-model="customer.finance_email" name="customer.finance_email" :placeholder="strapi.FinanceContactEmailPlaceholder" class="grid-item -col-span-12 -col-span-lg-4" />
      </div>
    </div>
  </Card>
</template>

<script>
import TelInput from '@Components/TelInput.vue';

export default {
  name: 'CompanyInformation',
  components: { TelInput },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customer: { ...this.modelValue }
    };
  },
  watch: {
    // modelValue: {
    //   handler() {
    //     this.customer = this.modelValue;
    //   },
    //   immediate: true
    // },
    customer: {
      handler() {
        this.$emit('update:modelValue', { ...this.modelValue, ...this.customer });
      },
      deep: true
    }
  }
};
</script>
