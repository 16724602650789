<template>
  <div>
    <PageBuilder v-if="strapi.Modules" :modules="strapi.Modules" />
    <ContactForm :recipients="recipients" :offices="offices" :defaultOfficeID="defaultOfficeID" :strapi="strapi" />
  </div>
</template>

<script>
import ContactForm from '@Components/ContactForm.vue';
export default {
  name: 'ContactUsNew',
  components: { ContactForm },
  data() {
    return { loading: false, ...this.$controller.data };
  }
};
</script>

<style lang="scss" scoped></style>
