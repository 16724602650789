<template>
  <div>
    <PageBuilder :modules="strapi.Modules" />
    <ProductListing :categories="categories" :categoryLabel="currentCategory.category_label" :showLinkList="currentCategory.show_subcategories" :filterAttributes="filterAttributes" :sortingStrategies="sortingStrategies" :strapi="strapiFilterSort" />
  </div>
</template>

<script>
import ProductListing from '@Components/ProductListing.vue';

export default {
  name: 'ProductListIndex',
  components: { ProductListing },
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  }
};
</script>
