<template>
  <ProductImageSwiperComponent :images="strapi.Images" />
</template>

<script>
import ProductImageSwiperComponent from '@Components/ProductImageSwiperComponent.vue';

export default {
  name: 'ProductImageCarousel',
  components: { ProductImageSwiperComponent },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  }
};
</script>
