import he from 'he';
export * from './date';

export function isEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  } else if (typeof value === 'string' || Array.isArray(value)) {
    return !value.length;
  } else if (typeof value === 'object') {
    return !Object.keys(value).length;
  }
  return false;
}

export function isNotEmpty(value) {
  return !isEmpty(value);
}

export function isBlank(value) {
  return value === null || value === undefined || value === '' || (Array.isArray(value) && !value.length);
}

export function isNotBlank(value) {
  return !isBlank(value);
}

export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isNotObject(value) {
  return !isObject(value);
}

export function isString(value) {
  return typeof value === 'string';
}

export function isNotString(value) {
  return !isString(value);
}

export function isNumber(value) {
  return typeof value === 'number';
}

export function isNotNumber(value) {
  return !isNumber(value);
}

export function isFunction(value) {
  return typeof value === 'function' || value instanceof Function;
}

export function isNotFunction(value) {
  return !isFunction(value);
}

export function isArray(value) {
  return value instanceof Array || Array.isArray(value);
}

export function isNotArray(value) {
  return !isArray(value);
}

export function isNil(value) {
  return value === null || value === undefined;
}

export function isUndefined(value) {
  return value === undefined;
}

export function isNotUndefined(value) {
  return !isUndefined(value);
}

export function isNotNil(value) {
  return !isNil(value);
}

export function toBoolean(value) {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
    if (value === 'true' || value === 'yes' || value === '1') return true;
    if (value === 'false' || value === 'no' || value === '0') return false;
  }
  return !!value;
}

export function toPascalCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
    .replace(new RegExp(/\w/), s => s.toUpperCase())
    .replace(/\s/g, '');
}

export { he };

/**
 * Formats a numeric value into the specified currency format. The default currency is USD.
 * Supported currencies are SEK, USD, GBP, and EUR.
 *
 * @param {number} value - The numeric value to be formatted.
 * @param {string|Object} [currency='USD'] - The currency code or currency for the desired format (e.g., 'SEK', 'USD', 'GBP', 'EUR').
 * @returns {string} The formatted currency string.
 * @throws {Error} If the specified currency is not supported.
 */
export function moneyFormat(value, currency = window.Techship.application.currency) {
  const supportedCurrencies = {
    SEK: 'sv-SE',
    USD: 'en-US',
    GBP: 'en-GB',
    EUR: 'de-DE'
  };

  if (isNil(value) || isNaN(value)) {
    return '';
  }
  if (isObject(currency)) {
    currency = currency.code;
  }
  const upperCurrency = currency.toUpperCase();
  if (!supportedCurrencies.hasOwnProperty(upperCurrency)) {
    throw new Error('Unsupported currency');
  }

  const formattedValue = new Intl.NumberFormat(supportedCurrencies[upperCurrency], {
    style: 'currency',
    currency: upperCurrency
  }).format(value);

  return formattedValue.replace(/\D00(?=\D*$)/, '');
}

export function debounce(fn, ms = 0) {
  let timeoutId;
  return function (...args) {
    const context = this;
    const later = () => {
      timeoutId = null;
      if (ms) fn.apply(context, args);
    };
    const callNow = !ms && !timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(later, ms);
    if (callNow) fn.apply(context, args);
  };
}

/**
 * each - Iterates over a collection (either an array or an object) and calls the provided callback function.
 *
 * @param {Array|Object} collection The input collection to iterate over.
 * @param {Function} callback The callback function to be called for each item in the collection.
 * The callback function receives three arguments: the value, the index or key, and the original collection.
 * @throws {TypeError} If the input is not an array or an object.
 */
export function each(collection, callback) {
  if (Array.isArray(collection)) {
    collection.forEach((value, index) => {
      callback(value, index, collection);
    });
  } else if (typeof collection === 'object' && collection !== null) {
    Object.entries(collection).forEach(([key, value]) => {
      callback(value, key, collection);
    });
  } else {
    throw new TypeError('Invalid input. Expected an array or an object.');
  }
}

/**
 * find - Searches for an item in a collection (either an array or an object) based on a predicate function.
 *
 * @param {Array|Object} collection The input collection to search through.
 * @param {Function} predicate The predicate function used to find the desired item in the collection.
 * The predicate function receives three arguments: the value, the index or key, and the original collection.
 * @returns {*} The first item that satisfies the predicate function or undefined if no item matches the criteria.
 * @throws {TypeError} If the input is not an array or an object.
 */
export function find(collection, predicate) {
  let result;

  each(collection, (value, keyOrIndex, collection) => {
    if (result === undefined && predicate(value, keyOrIndex, collection)) {
      result = value;
    }
  });

  return result;
}

/**
 * filter - Filters the items in a collection (either an array or an object) based on a predicate function.
 *
 * @param {Array|Object|null} collection The input collection to filter.
 * @param {Function} predicate The predicate function used to filter the items in the collection.
 * The predicate function receives three arguments: the value, the index or key, and the original collection.
 * @returns {Array|Object|null} A new collection containing only the items that satisfy the predicate function or null if the input collection is null.
 * @throws {TypeError} If the input is not an array, an object, or null.
 */
export function filter(collection, predicate) {
  if (collection === null) {
    return null;
  }

  const result = Array.isArray(collection) ? [] : {};

  each(collection, (value, keyOrIndex, collection) => {
    if (predicate(value, keyOrIndex, collection)) {
      if (Array.isArray(result)) {
        result.push(value);
      } else {
        result[keyOrIndex] = value;
      }
    }
  });

  return result;
}

/**
 * reject - Filters out the items in a collection (either an array or an object) based on a predicate function.
 *
 * @param {Array|Object|null} collection The input collection from which to reject items.
 * @param {Function} predicate The predicate function used to filter out items from the collection.
 * The predicate function receives three arguments: the value, the index or key, and the original collection.
 * @returns {Array|Object|null} A new collection containing only the items that do not satisfy the predicate function or null if the input collection is null.
 * @throws {TypeError} If the input is not an array, an object, or null.
 */
export function reject(collection, predicate) {
  if (collection === null) {
    return null;
  }

  return filter(collection, (value, keyOrIndex, collection) => {
    return !predicate(value, keyOrIndex, collection);
  });
}

/**
 * Applies the given iteratee function to each element of the input collection (array or object) and returns a new array
 * containing the results.
 *
 * @param {Array|Object} collection - The collection to iterate over, either an array or an object.
 * @param {Function} iteratee - The function to be invoked on each element of the collection.
 *                              It accepts three arguments: value, key/index, and the original collection.
 * @returns {Array} - A new array containing the results of applying the iteratee function to each element of the collection.
 * @throws {TypeError} - Throws a TypeError if the first argument is not an object or an array.
 * @throws {TypeError} - Throws a TypeError if the second argument is not a function.
 *
 * @example
 * const numbers = [1, 2, 3, 4, 5];
 * const squaredNumbers = map(numbers, (number) => number * number);
 * console.log(squaredNumbers); // [1, 4, 9, 16, 25]
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3, d: 4, e: 5 };
 * const squaredObjValues = map(obj, (value) => value * value);
 * console.log(squaredObjValues); // [1, 4, 9, 16, 25]
 */
export function map(collection, iteratee) {
  if (isNotArray(collection) && isNotObject(collection)) {
    throw new TypeError('First argument must be an object or an array');
  }
  if (isNotFunction(iteratee)) {
    throw new TypeError('Second argument must be a function');
  }

  const keys = isArray(collection) ? null : Object.keys(collection);
  const length = (keys || collection).length;
  const result = new Array(length);

  for (let i = 0; i < length; i++) {
    const key = isArray(collection) ? i : keys[i];
    result[i] = iteratee(collection[key], key, collection);
  }

  return result;
}

/**
 * Reduces the given collection (array or object) to a single value using the provided reducer function.
 *
 * @param {Array|Object} collection - The collection to iterate over, either an array or an object.
 * @param {Function} reducer - The reducer function that reduces the collection to a single value.
 *                             It accepts four arguments: accumulator, value, key/index, and the original collection.
 * @param {*} [initialValue] - The initial value for the accumulator. If not provided, the first element of the collection
 *                             will be used.
 * @returns {*} - The final, reduced value after processing the entire collection.
 * @throws {TypeError} - Throws a TypeError if the first argument is not an object or an array.
 * @throws {TypeError} - Throws a TypeError if the second argument is not a function.
 *
 * @example
 * const numbers = [1, 2, 3, 4, 5];
 * const sum = reduce(numbers, (accumulator, number) => accumulator + number);
 * console.log(sum); // 15
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3, d: 4, e: 5 };
 * const sumObjValues = reduce(obj, (accumulator, value) => accumulator + value);
 * console.log(sumObjValues); // 15
 */
export function reduce(collection, reducer, initialValue) {
  if (isNotArray(collection) && isNotObject(collection)) {
    throw new TypeError('First argument must be an object or an array');
  }
  if (isNotFunction(reducer)) {
    throw new TypeError('Second argument must be a function');
  }

  const keys = isArray(collection) ? null : Object.keys(collection);
  const length = (keys || collection).length;
  let accumulator = initialValue;
  let startIndex = 0;

  if (accumulator === undefined) {
    if (length === 0) {
      throw new TypeError('Cannot reduce empty collection with no initial value');
    }
    accumulator = collection[keys ? keys[0] : 0];
    startIndex = 1;
  }

  for (let i = startIndex; i < length; i++) {
    const key = isArray(collection) ? i : keys[i];
    accumulator = reducer(accumulator, collection[key], key, collection);
  }

  return accumulator;
}

/**
 * Flattens a nested array (the nesting can be to any depth). If the optional parameter 'shallow' is set to true,
 * the array will only be flattened a single level.
 *
 * @param {Array} inputArray - The input array to be flattened.
 * @param {Boolean} [shallow=false] - If true, the array will only be flattened a single level.
 * @returns {Array} - A new flattened array.
 * @throws {TypeError} - Throws a TypeError if the first argument is not an array.
 *
 * @example
 * const nestedArray = [1, [2], [3, [[4]]]];
 * const flattenedArray = flatten(nestedArray);
 * console.log(flattenedArray); // [1, 2, 3, 4]
 *
 * @example
 * const shallowNestedArray = [1, [2], [3, [[4]]]];
 * const shallowFlattenedArray = flatten(shallowNestedArray, true);
 * console.log(shallowFlattenedArray); // [1, 2, 3, [[4]]]
 */
export function flatten(inputArray, shallow = false) {
  if (!Array.isArray(inputArray)) {
    throw new TypeError('First argument must be an array');
  }

  const result = [];

  const flattenHelper = (array, shallow) => {
    for (let i = 0; i < array.length; i++) {
      const value = array[i];
      if (Array.isArray(value)) {
        if (shallow) {
          result.push(...value);
        } else {
          flattenHelper(value);
        }
      } else {
        result.push(value);
      }
    }
  };

  flattenHelper(inputArray, shallow);
  return result;
}

/**
 * Extracts a list of values associated with a given property from an array of objects.
 * Now supports dot notation for nested properties.
 *
 * @param {Array} list - The array of objects to pluck values from.
 * @param {string} propertyName - The property name to retrieve values for, supporting dot notation for nested properties.
 * @returns {Array} - Returns an array of values associated with the propertyName.
 *
 * @example
 * const users = [
 *   { name: "Alice", age: 40, address: { city: "New York" } },
 *   { name: "Bob", age: 20, address: { city: "Los Angeles" } },
 *   { name: "Charlie", age: 30, address: { city: "Chicago" } }
 * ];
 * console.log(pluck(users, 'address.city')); // ['New York', 'Los Angeles', 'Chicago']
 *
 * @example
 * const products = [
 *   { title: "Laptop", specifications: { price: 800, weight: "2kg" } },
 *   { title: "Smartphone", specifications: { price: 500, weight: "150g" } }
 * ];
 * console.log(pluck(products, 'specifications.price')); // [800, 500]
 */
export function pluck(list, propertyName) {
  // Split propertyName into parts if dot notation is used, or use a single-element array otherwise
  const propertyParts = propertyName.split('.');

  // Map each object in the list to its (potentially nested) property value
  return list.map(item => {
    let currentValue = item;
    for (const part of propertyParts) {
      // If part of the property name can't be found, stop early and return undefined for this item
      if (currentValue === null || currentValue === undefined) {
        return undefined;
      }
      currentValue = currentValue[part];
    }
    return currentValue;
  });
}

/**
 * Returns the first n elements of an array or object. If n is not provided, it returns the first element or key-value pair.
 * If the input is neither an array nor an object, it returns the input itself.
 *
 * @param {Array|Object|*} input - The input array, object, or any other type.
 * @param {Number} [n=1] - The number of elements or key-value pairs to return.
 * @returns {Array|Object|*} - A new array containing the first n elements or key-value pairs, the first element or key-value pair if n is not provided, or the input itself if it's neither an array nor an object.
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5];
 * const firstElement = first(inputArray);
 * console.log(firstElement); // 1
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5];
 * const firstThreeElements = first(inputArray, 3);
 * console.log(firstThreeElements); // [1, 2, 3]
 *
 * @example
 * const inputObject = {a: 1, b: 2, c: 3};
 * const firstKeyValue = first(inputObject);
 * console.log(firstKeyValue); // {a: 1}
 *
 * @example
 * const inputObject = {a: 1, b: 2, c: 3};
 * const firstTwoKeyValues = first(inputObject, 2);
 * console.log(firstTwoKeyValues); // {a: 1, b: 2}
 *
 * @example
 * const inputString = "Hello";
 * console.log(first(inputString)); // "Hello"
 */
export function first(input, n = 1) {
  if (Array.isArray(input)) {
    if (n === 1) {
      return input[0];
    }
    return input.slice(0, n);
  } else if (typeof input === 'object' && input !== null) {
    const keys = Object.keys(input);
    const result = {};

    for (let i = 0; i < Math.min(n, keys.length); i++) {
      const key = keys[i];
      result[key] = input[key];
    }

    return n === 1 && keys.length > 0 ? { [keys[0]]: input[keys[0]] } : result;
  } else {
    return input; // return the input itself if it's neither an array nor an object
  }
}

/**
 * Returns the last n elements of an array or object. If n is not provided, it returns the last element or key-value pair.
 *
 * @param {Array|Object} input - The input array or object.
 * @param {Number} [n=1] - The number of elements or key-value pairs to return.
 * @returns {Array|*} - A new array containing the last n elements or key-value pairs, or the last element or key-value pair if n is not provided.
 * @throws {TypeError} - Throws a TypeError if the input is neither an array nor an object.
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5];
 * const lastElement = last(inputArray);
 * console.log(lastElement); // 5
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5];
 * const lastThreeElements = last(inputArray, 3);
 * console.log(lastThreeElements); // [3, 4, 5]
 *
 * @example
 * const inputObject = {a: 1, b: 2, c: 3};
 * const lastKeyValue = last(inputObject);
 * console.log(lastKeyValue); // {c: 3}
 *
 * @example
 * const inputObject = {a: 1, b: 2, c: 3};
 * const lastTwoKeyValues = last(inputObject, 2);
 * console.log(lastTwoKeyValues); // {b: 2, c: 3}
 */
export function last(input, n = 1) {
  if (Array.isArray(input)) {
    if (n === 1) {
      return input[input.length - 1];
    }
    return input.slice(Math.max(0, input.length - n));
  } else if (typeof input === 'object' && input !== null) {
    const keys = Object.keys(input);
    const result = {};

    for (let i = Math.max(0, keys.length - n); i < keys.length; i++) {
      const key = keys[i];
      result[key] = input[key];
    }

    return n === 1 && keys.length > 0 ? { [keys[keys.length - 1]]: input[keys[keys.length - 1]] } : result;
  } else {
    throw new TypeError('Input must be an array or an object');
  }
}

/**
 * Returns true if at least one element in the array or object passes the test implemented by the provided function.
 *
 * @param {Array|Object} input - The input array or object.
 * @param {Function} predicate - Function to test each element of the array or object.
 * @returns {Boolean} - Returns true if at least one element passes the test, else false.
 * @throws {TypeError} - Throws a TypeError if the input is neither an array nor an object.
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5];
 * const isGreaterThanThree = some(inputArray, num => num > 3);
 * console.log(isGreaterThanThree); // true
 *
 * @example
 * const inputObject = {a: 1, b: 2, c: 3};
 * const isGreaterThanTwo = some(inputObject, num => num > 2);
 * console.log(isGreaterThanTwo); // true
 */
export function some(input, predicate) {
  if (Array.isArray(input)) {
    for (let i = 0; i < input.length; i++) {
      if (predicate(input[i])) {
        return true;
      }
    }
  } else if (typeof input === 'object' && input !== null) {
    const keys = Object.keys(input);
    for (let i = 0; i < keys.length; i++) {
      if (predicate(input[keys[i]])) {
        return true;
      }
    }
  } else {
    throw new TypeError('Input must be an array or an object');
  }

  return false;
}
// Aliases for some
export const contains = some;
export const includes = some;
export const include = some;

/**
 * Returns true if every element in the array or object passes the test implemented by the provided function.
 *
 * @param {Array|Object} input - The input array or object.
 * @param {Function} predicate - Function to test each element of the array or object.
 * @returns {Boolean} - Returns true if all elements pass the test, else false.
 * @throws {TypeError} - Throws a TypeError if the input is neither an array nor an object.
 *
 * @example
 * const inputArray = [2, 4, 6];
 * const isEven = every(inputArray, num => num % 2 === 0);
 * console.log(isEven); // true
 *
 * @example
 * const inputObject = {a: 2, b: 4, c: 6};
 * const isEvenObject = every(inputObject, num => num % 2 === 0);
 * console.log(isEvenObject); // true
 */
export function every(input, predicate) {
  if (Array.isArray(input)) {
    for (let i = 0; i < input.length; i++) {
      if (!predicate(input[i])) {
        return false;
      }
    }
  } else if (typeof input === 'object' && input !== null) {
    const keys = Object.keys(input);
    for (let i = 0; i < keys.length; i++) {
      if (!predicate(input[keys[i]])) {
        return false;
      }
    }
  } else {
    throw new TypeError('Input must be an array or an object');
  }

  return true;
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 *
 * @param {*} value - The first value to compare.
 * @param {*} other - The second value to compare.
 * @returns {Boolean} - Returns true if the values are equivalent, else false.
 *
 * @example
 * const obj1 = { a: 1, b: [2, 3] };
 * const obj2 = { a: 1, b: [2, 3] };
 * console.log(isEqual(obj1, obj2)); // true
 *
 * @example
 * const arr1 = [1, 2, 3];
 * const arr2 = [1, 2, 4];
 * console.log(isEqual(arr1, arr2)); // false
 */
export function isEqual(value, other) {
  // Same value equality check
  if (value === other) return true;

  // If they're of a different type, return false
  if (typeof value !== 'object' || value === null || typeof other !== 'object' || other === null) {
    return false;
  }

  // Check arrays
  if (Array.isArray(value) && Array.isArray(other)) {
    if (value.length !== other.length) return false;
    for (let i = 0; i < value.length; i++) {
      if (!isEqual(value[i], other[i])) {
        return false;
      }
    }
    return true;
  }

  // Check objects
  if (!Array.isArray(value) && !Array.isArray(other)) {
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    if (valueKeys.length !== otherKeys.length) return false;

    for (const key of valueKeys) {
      if (!other.hasOwnProperty(key)) return false;
      if (!isEqual(value[key], other[key])) {
        return false;
      }
    }
    return true;
  }

  return false;
}

/**
 * Formats the given file size in bytes to a human-readable format.
 * @param {number} size - The file size in bytes.
 * @returns {string} The formatted file size with appropriate unit (KB, MB, or GB).
 */
export function formatFileSize(size) {
  // Convert bytes to KB
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }

  // Convert bytes to MB
  if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }

  // Convert bytes to GB
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
}

export function getFileIcon(mimeType) {
  // Determine the FontAwesome icon class based on the file's MIME type
  if (isImage(mimeType)) {
    return 'fas fa-file-image';
  } else if (isSpreadsheet(mimeType)) {
    return 'fas fa-file-excel';
  } else if (isCSV(mimeType)) {
    return 'fas fa-file-csv';
  } else if (isTextDocument(mimeType)) {
    return 'fas fa-file-alt';
  } else if (isWord(mimeType)) {
    return 'fas fa-file-word';
  } else if (isPdf(mimeType)) {
    return 'fas fa-file-pdf';
  } else if (isPpt(mimeType)) {
    return 'fas fa-file-powerpoint';
  } else if (isArchive(mimeType)) {
    return 'fas fa-file-archive';
  } else {
    return 'fas fa-file';
  }
}

export function isImage(mimeType) {
  return mimeType.includes('image');
}

export function isSpreadsheet(mimeType) {
  return ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.oasis.opendocument.spreadsheet'].includes(mimeType);
}

export function isCSV(mimeType) {
  return mimeType.includes('text/csv');
}

export function isTextDocument(mimeType) {
  return mimeType.includes('text');
}

export function isWord(mimeType) {
  return ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(mimeType);
}

export function isPdf(mimeType) {
  return mimeType === 'application/pdf';
}

export function isPpt(mimeType) {
  return ['application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(mimeType);
}

export function isArchive(mimeType) {
  return ['application/octet-stream'].includes(mimeType);
}
