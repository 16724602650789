<template>
  <Card class="productCatCard h-100" @mouseover="hover = true" @mouseleave="hover = false">
    <a :href="item.Href" :aria-label="`${item.Name} page`" class="productLink">
      <ProductRoundImage v-if="item.Image" :animateUp="true" :hover="hover" :icon="item.Icon" :image="item.Image.url" />
      <p class="paragraph-large text-center text-black mt-5 mb-10">{{ item.Name }}</p>
    </a>
  </Card>
</template>

<script>
import ProductRoundImage from '@Components/ProductRoundImage.vue';

export default {
  name: 'ProductCategoryItem',
  components: { ProductRoundImage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hover: false
    };
  }
};
</script>

<style lang="scss" scoped>
.productCatCard {
  background: rgba(0, 0, 0, 0);
  overflow: visible;
}

.productLink:hover {
  .productCatImg {
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.2);

    &:after {
      transform: scale(1.2);
    }
  }

  .productIconWrapper {
    transform: scale(0.9) translateY(-5px);
  }
}
</style>
