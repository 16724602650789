export default {
  /**
   * Data properties for the Vue component.
   *
   * This function conditionally sets the `strapi` data property based on whether
   * it's already defined in the component's props. If `strapi` is not defined in props,
   * it initializes `strapi` using a global `$strapi` object and optionally a specific
   * configuration based on the component's name.
   *
   * @returns {Object} - An object containing the `strapi` data property if it's not
   * already defined in props. Otherwise, returns an empty object.
   */
  data() {
    return !this.$options.props || !('strapi' in this.$options.props) ? { strapi: this.$strapi[this.$options.name] || this.$strapi || {} } : {};
  }
};
