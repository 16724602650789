<template>
  <div>
    <VRangeSlider v-bind="$attrs" :model-value="modelValue" :ripple="false" :error-messages="localErrorMessages" elevation="0" :hide-details="hasErrorMessages ? 'auto' : true" density="compact" @update:modelValue="$emit('update:modelValue', $event)" />
    <div>
      <div v-if="inputBoxes" class="d-flex justify-space-between">
        <TextField :value="modelValue[0]" :max="modelValue[1]" :min="Math.floor($attrs.min)" hide-details :hide-spin-buttons="true" single-line type="number" variant="outlined" density="compact" @update:modelValue="emitInputBoxValueIndexZero" @blur="resetInputBoxValues" />
        <p class="my-auto mx-2">–</p>
        <TextField :value="modelValue[1]" :min="modelValue[0]" :max="Math.ceil($attrs.max)" hide-details :hide-spin-buttons="true" single-line type="number" variant="outlined" density="compact" @update:modelValue="emitInputBoxValueIndexOne" @blur="resetInputBoxValues" />
      </div>
    </div>
  </div>
</template>

<script>
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';
import TextField from './TextField.vue';
import {isBlank} from "@/utils/default";

export default {
  name: 'RangeSlider',
  components: { TextField },
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    modelValue: {
      type: Array,
      default: () => [0, 10]
    },
    inputBoxes: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    emitInputBoxValueIndexZero(value) {
      const newValue = [...this.modelValue];
      newValue[0] = value;
      this.$emit('update:modelValue', newValue);
    },
    emitInputBoxValueIndexOne(value) {
      const newValue = [...this.modelValue];
      newValue[1] = value;
      this.$emit('update:modelValue', newValue);
    },
    resetInputBoxValues() {
      if (isBlank(this.modelValue[0])) {
        this.emitInputBoxValueIndexZero(this.$attrs.min)
      }
      if (isBlank(this.modelValue[1])) {
        this.emitInputBoxValueIndexOne(this.$attrs.max)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-slider-thumb__surface) {
  background-color: $color-primary !important;
}

:deep(.v-slider-track__fill) {
  background-color: $color-primary !important;
}

:deep(.v-text-field){
  width: 33%;
}
</style>
