<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex">
      <VIcon v-if="strapi.SectionIcon" :icon="strapi.SectionIcon" size="25px" class="mr-3" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
    </header>
    <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
    <Form :disabled="loading" :isEditing="isEditing" grid :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
      <label for="invoiceAddressAddress">{{ strapi.AddressLabel }}</label>
      <TextField v-if="isEditing" id="invoiceAddressAddress" v-model="customerInvoiceAddress.address" name="invoiceAddress.address" />
      <span v-else>{{ customerInvoiceAddress.address }}</span>
      <label for="invoiceAddressAddress2">{{ strapi.Address2Label }}</label>
      <TextField v-if="isEditing" id="invoiceAddressAddress2" v-model="customerInvoiceAddress.address2" name="invoiceAddress.address2" />
      <span v-else>{{ customerInvoiceAddress.address2 }}</span>
      <label for="invoiceAddressZip">{{ strapi.PostalCodeLabel }}</label>
      <TextField v-if="isEditing" id="invoiceAddressZip" v-model="customerInvoiceAddress.zip" name="invoiceAddress.zip" />
      <span v-else>{{ customerInvoiceAddress.zip }}</span>
      <label for="invoiceAddressCity">{{ strapi.CityLabel }}</label>
      <TextField v-if="isEditing" id="invoiceAddressCity" v-model="customerInvoiceAddress.city" name="invoiceAddress.city" />
      <span v-else>{{ customerInvoiceAddress.city }}</span>
      <label for="invoiceAddressCountry">{{ strapi.CountryLabel }}</label>
      <SelectCountry v-if="isEditing" id="invoiceAddressCountry" v-model="customerInvoiceAddress.country" name="invoiceAddress.country_id" returnObject />
      <span v-else>
        <VAvatar size="x-small" class="me-2 mb-1"><VImg :src="customerInvoiceAddress.country.flag" /></VAvatar>
        {{ customerInvoiceAddress.country.name }}
      </span>
      <template v-if="customerInvoiceAddress.country?.hasStates">
        <label for="invoiceAddressState">{{ strapi.StateLabel }}</label>
        <SelectState v-if="isEditing" id="invoiceAddressState" :key="customerInvoiceAddress.country_id" v-model="customerInvoiceAddress.state_id" :queryBuilder="qb => qb.where('freight_country_id', customerInvoiceAddress.country_id).limit(100)" name="invoiceAddress.state_id" />
        <span v-else>{{ `${customerInvoiceAddress.state.name} (${customerInvoiceAddress.state.code})` }}</span>
      </template>
      <template v-if="!customer.isPushedToBC" #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer class="d-flex" :class="{ 'flex-column': isMobile }">
          <template v-if="isEditing">
            <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" color="secondary" variant="outlined" :disabled="loading" :loading="loading" type="submit">
              {{ strapi.SaveButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="cancel">
              {{ strapi.CancelButtonLabel }}
            </Button>
          </template>
          <template v-else>
            <Button color="secondary" :block="isMobile" variant="outlined" :disabled="editingIsDisabled" :prependIcon="strapi.EditButtonIcon" @click="edit">
              {{ strapi.EditButtonLabel }}
            </Button>
          </template>
        </footer>
      </template>
    </Form>
  </Card>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'InvoiceAddress',
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customerInvoiceAddress: { ...this.modelValue },
      loading: false
    };
  },
  watch: {
    'customerInvoiceAddress.country'(country) {
      this.customerInvoiceAddress.country_id = country?.id;
    }
  },
  methods: {
    edit() {
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.customerInvoiceAddress = { ...this.modelValue };
    },
    async save() {
      this.loading = true;
      const response = await this.put('invoice-address', { invoiceAddress: { ...this.customerInvoiceAddress } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    }
  }
};
</script>
