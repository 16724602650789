<template>
  <VAutocomplete
    :id="id"
    ref="selectApi"
    v-model="value"
    v-model:search="search"
    v-userTyping="onUserTyping"
    :bg-color="finalBgColor"
    :disabled="disabled"
    :items="items"
    :clearable="clearable"
    :no-data-text="noDataText"
    open-on-clear
    :error-messages="localErrorMessages"
    color="secondary"
    :chips="multiple"
    single-line
    persistent-hint
    autocomplete="off"
    :hide-details="hasErrorMessages ? 'auto' : true"
    density="compact"
    no-filter
    :multiple="multiple"
    return-object
    :item-value="itemValue"
    :item-title="itemTitle"
    variant="solo"
    :loading="loading"
    @focus="onFocus"
    @click:clear="onClear"
    @blur="onBlur"
  >
    <template v-if="hasSlot('item')" #item="{ props, item }">
      <slot :item="{ props, item }" name="item" />
    </template>
    <template #selection="{ props, item }">
      <span :class="{ 'ts-md-link': $utils.isNotBlank(href) }" @click.stop="redirectToItem(item.raw)">
        <slot v-if="hasSlot('selection')" :item="{ props, item }" name="selection" />
        <span v-else>{{ $utils.isFunction(itemTitle) ? itemTitle(item.raw) : item.raw[itemTitle] }}</span>
      </span>
    </template>
    <template v-if="hasSlot('append')" #append>
      <slot name="append" />
    </template>
  </VAutocomplete>
</template>

<script>
import { debounce } from '@Utils/default.js';
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'SelectApi',
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    modelValue: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    queryBuilder: {
      type: Function,
      default: qb => qb
    },
    clearable: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemTitle: {
      type: [String, Function],
      default: 'name'
    },
    endpoint: {
      type: String,
      default: null
    },
    href: {
      type: Function,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectFirstItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialValue: this.modelValue,
      search: '',
      // newQuery: null,
      loading: false,
      defaultItemsFromApi: [],
      itemsBySearchFromApi: [],
      itemsByValueFromApi: [],
      initialDefaultItemsLoaded: false,
      userTriggeredSearch: false,
      userTriggeredFocus: false,
      hasSearched: false,
      isFocused: false,
      isDirty: false,
      // previousValue: null,
      getOptionKey: opt => {
        return this.$utils.isObject(opt) && Object.hasOwn(opt, this.itemValue) ? opt[this.itemValue] : opt;
      }
    };
  },
  computed: {
    finalBgColor() {
      return this.bgColor || (this.isDirty ? 'grey-light' : 'grey-background');
    },
    value: {
      get() {
        if (this.loading) return;

        let value = this.getItemsFromAnySourceOfList(this.modelValue);

        if (this.$utils.isEmpty(value)) {
          value = this.modelValue;
        }
        return this.multiple || this.$utils.isNotArray(value) ? value : this.$utils.first(value);
      },
      set(value) {
        this.$nextTick(() => {
          // console.log(`endpoint = ${this.endpoint}, value = ${value}`);
          const input = this.$refs.selectApi;
          if (this.$utils.isNotBlank(value)) {
            this.userTriggeredSearch = false; // Set the flag to false when an item is selected
            input?.blur();
            // console.log(this.returnObject ? value : this.$utils.isArray(value) ? this.$utils.map(value, val => this.getOptionKey(val)) : this.getOptionKey(value));
            this.$emit('update:modelValue', this.returnObject ? value : this.$utils.isArray(value) ? this.$utils.map(value, val => this.getOptionKey(val)) : this.getOptionKey(value));
          }
        });
      }
    },
    items() {
      // If a search has been made and there are no results, return an empty array.
      if (this.hasSearched && this.$utils.isEmpty(this.itemsBySearchFromApi)) {
        // console.log(`endpoint = ${this.endpoint}, this.hasSearched && this.$utils.isEmpty(this.itemsBySearchFromApi)`, this.hasSearched && this.$utils.isEmpty(this.itemsBySearchFromApi));
        return [];
      }
      // If there are search results, return them.
      if (this.$utils.isNotEmpty(this.itemsBySearchFromApi)) {
        // console.log(`endpoint = ${this.endpoint},this.$utils.isNotEmpty(this.itemsBySearchFromApi)`, this.$utils.isNotEmpty(this.itemsBySearchFromApi));
        return this.itemsBySearchFromApi;
      }
      // If no search has been made, return the default items.
      // console.log(`endpoint = ${this.endpoint}, this.defaultItemsFromApi`, this.defaultItemsFromApi);
      return this.defaultItemsFromApi;
    },
    noDataText() {
      // console.log(`endpoint = ${this.endpoint}, this.loading = ${this.loading}, this.initialDefaultItemsLoaded = ${this.initialDefaultItemsLoaded}`);
      if (this.loading || !this.initialDefaultItemsLoaded) return 'Loading...';
      return this.$utils.isNotEmpty(this.items) ? 'Loading...' : 'No results found';
    }
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        // console.log(newValue);
        // console.log(oldValue);
        // this.previousValue = this.$utils.isEqual(String(newValue), String(this.initialValue)) ? this.initialValue : oldValue;
        // console.log(`endpoint = ${this.endpoint}, newValue = ${newValue}, oldValue = ${oldValue}`);
        this.isDirty = this.$utils.isNotBlank(newValue);
        // console.log(`endpoint = ${this.endpoint}, value = ${value}`);
        if (this.$utils.isNotBlank(newValue) && (this.multiple ? !this.$utils.some(newValue, value => this.$utils.isObject(newValue)) : this.$utils.isNotObject(newValue))) {
          if (this.$utils.isEmpty(this.getItemsFromAnySourceOfList(newValue))) {
            this.getValueViaApi(newValue);
          }
        }
      },
      immediate: true
    },
    search: debounce(async function (newQuery, oldQuery) {
      // console.log(`endpoint = ${this.endpoint}, newQuery = ${newQuery}, oldQuery = ${oldQuery}`);

      // console.log(`endpoint = ${this.endpoint}, this.isFocused = ${this.isFocused}`);
      // console.log(`endpoint = ${this.endpoint}, this.userTriggeredSearch = ${this.userTriggeredSearch}`);
      // console.log(`endpoint = ${this.endpoint}, this.hasSearched = ${this.hasSearched}`);

      if (this.isFocused && !this.userTriggeredSearch) {
        // console.log(`endpoint = ${this.endpoint}, this.isFocused = ${this.isFocused}, this.userTriggeredSearch = ${this.userTriggeredSearch}`);
        this.itemsBySearchFromApi = [];
        await this.getDefaultItemsViaApi();
        return;
      }

      if (!this.userTriggeredSearch) {
        // console.log(`endpoint = ${this.endpoint}, this.userTriggeredSearch = ${this.userTriggeredSearch}`);
        return; // Exit early if the search was not triggered by the user
      }

      if (!this.userTriggeredSearch && this.$utils.isBlank(oldQuery) && this.$utils.isBlank(this.value)) {
        // console.log(`endpoint = ${this.endpoint}, this.userTriggeredSearch = ${this.userTriggeredSearch}, this.oldQuery = ${oldQuery}, this.value = ${this.value}`);
        await this.getDefaultItemsViaApi();
        return;
      }

      if (this.$utils.isBlank(newQuery)) {
        // console.log(`endpoint = ${this.endpoint}, this.$utils.isBlank(newQuery) = ${this.$utils.isBlank(newQuery)}`);
        this.itemsBySearchFromApi = [];
        await this.getDefaultItemsViaApi();
        this.hasSearched = false; // Reset the hasSearched flag when the query is blank
        return;
      }

      // console.log(`endpoint = ${this.endpoint},at the end of the search function`);
      await this.getItemsBySearchFromApi(newQuery);
    }, 500),
    items(val) {
      // console.log(`endpoint = ${this.endpoint}, at items watch`);
      // console.log(val);
      if (this.selectFirstItem && this.$utils.isNotEmpty(val)) {
        this.value = this.$utils.first(val);
      }
    }
  },
  async mounted() {
    // console.log(`endpoint = ${this.endpoint}, at mounted`);
    if (this.selectFirstItem && !this.disabled) {
      await this.getDefaultItemsViaApi();
    }
  },
  beforeUnmount() {
    // console.log(`endpoint = ${this.endpoint}, at before unmount, this.initialValue = ${this.initialValue}`);
    this.$emit('update:modelValue', this.initialValue);
    this.$emit('onBeforeUnmount');
  },
  methods: {
    async getValueViaApi(value) {
      // console.log(`endpoint = ${this.endpoint}, at get value via api, value = ${value}`);
      this.loading = true;
      const qb = this.$query(this.endpoint);
      this.queryBuilder(qb);
      if (this.multiple) {
        qb.whereIn(this.itemValue, value || []);
      } else {
        qb.where(this.itemValue, this.getOptionKey(value) || null);
      }

      qb.params({ withTrashed: true });

      const response = await (this.multiple ? qb.get() : qb.first());
      if (this.$utils.isEmpty(response)) {
        // console.log(`endpoint = ${this.endpoint}, at get value via api, this.$utils.isEmpty(response) = ${this.$utils.isEmpty(response)}`);
        if (this.$utils.isBlank(this.value)) {
          this.$emit('update:modelValue', null);
        }
      } else {
        this.itemsByValueFromApi = this.$utils.isArray(response.data || response) ? response.data || response : [response.data || response];
      }
      this.loading = false;
    },
    getItemsFromAnySourceOfList(value) {
      let items = this.getItemFromItems(value, this.itemsByValueFromApi);

      if (this.$utils.isEmpty(items)) {
        items = this.getItemFromItems(value, this.defaultItemsFromApi);
      }

      if (this.$utils.isEmpty(items)) {
        items = this.getItemFromItems(value, this.itemsBySearchFromApi);
      }
      return items;
    },
    getItemFromItems(item, items) {
      return this.$utils.filter(items, _item => {
        return this.multiple ? this.$utils.some(item, __item => this.$utils.isEqual(String(__item), String(this.getOptionKey(_item)))) : this.$utils.isEqual(String(item), String(this.getOptionKey(_item)));
      });
    },
    onBlur() {
      this.onLostFocus();
    },
    // Alias for onBlur
    onLostFocus() {
      // console.log(`endpoint = ${this.endpoint}, at lost focus, this.search = ${this.search}`);
      this.isFocused = false;
      this.userTriggeredSearch = false;
      this.itemsBySearchFromApi = [];
      // console.log(this.previousValue);
      // this.value = this.previousValue;
    },
    async onFocus() {
      // console.log(`endpoint = ${this.endpoint}, at focus, this.search = ${this.search}`);
      this.isFocused = true;
      this.hasSearched = false; // Reset the hasSearched flag when focused
      await this.getDefaultItemsViaApi();
    },
    async onClear() {
      // console.log(`endpoint = ${this.endpoint}, at clear, this.search = ${this.search}`);
      this.search = '';
      this.$emit('update:modelValue', null);
      await this.getDefaultItemsViaApi();
    },
    onUserTyping(val) {
      // console.log(`endpoint = ${this.endpoint}, at user typing, val = ${val}`);
      this.userTriggeredSearch = true;
    },
    async getDefaultItemsViaApi() {
      // console.log(`endpoint = ${this.endpoint}, at default items via api, this.initialDefaultItemsLoaded = ${this.initialDefaultItemsLoaded}`);
      if (this.initialDefaultItemsLoaded) return;
      this.loading = true;
      const qb = this.$query(this.endpoint);
      this.queryBuilder(qb);
      const response = await qb.get();
      this.defaultItemsFromApi = response.data || response;
      this.loading = false;
      this.initialDefaultItemsLoaded = true;
    },
    async getItemsBySearchFromApi(query) {
      // console.log(`endpoint = ${this.endpoint}, at search, query = ${query}`);
      this.loading = true;
      const qb = this.$query(this.endpoint);
      this.queryBuilder(qb);

      if (query) {
        qb.params({ query: query });
      }
      const response = await qb.get();
      this.itemsBySearchFromApi = response.data || response;
      this.loading = false;
      this.hasSearched = true; // Set the hasSearched flag to true when a search is made
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-field) {
  box-shadow: none !important;
}

:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-select--error) {
  background-color: $color-validation-red-light !important;
}
</style>
