import { defineStore } from 'pinia';

export const useLoginStore = defineStore('login', {
  state: () => ({
    open: localStorage.getItem('login.open') === 'true',
    showLoginSuccesfulResponse: localStorage.getItem('login.open.showLoginSuccesfulResponse') === 'true',
    showForgotPasswordSuccesfulResponse: localStorage.getItem('login.open.showForgotPasswordSuccesfulResponse') === 'true',
    isLoggedIn: window.Techship.application.isLoggedIn
  }),
  getters: {
    isLoginOpen: state => state.open,
    shouldShowLoginSuccesfulResponse: state => state.showLoginSuccesfulResponse,
    shouldShowForgotPasswordSuccesfulResponse: state => state.showForgotPasswordSuccesfulResponse
  },
  actions: {
    toggle() {
      setTimeout(() => {
        this.showLoginSuccesfulResponse = false;
        this.showForgotPasswordSuccesfulResponse = false;
      }, 600); // 600ms is the same as the transition duration in the css file for the sidebar

      this.open = !this.open;
      if (!this.open) {
        this.clearLocalStorage();
      }
    },
    clearLocalStorage() {
      localStorage.removeItem('login.open');
      localStorage.removeItem('login.open.showLoginSuccesfulResponse');
      localStorage.removeItem('login.open.showForgotPasswordSuccesfulResponse');
    }
  }
});
