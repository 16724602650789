<template>
  <div class="d-flex ga-2 ga-md-4">
    <Avatar class="w-40px h-40px w-sm-55px h-sm-55px w-md-70px h-md-70px">
      <VImg :src="props.author.ProfileImage?.src || props.author.ProfileImage?.data?.attributes?.url" />
    </Avatar>
    <div class="my-auto">
      <p class="bold -no-margin" rel="author">{{ `${props.author?.Name}` }}</p>
      <p class="bold text-gray -no-margin">{{ props.author?.JobTitle }}</p>
    </div>
  </div>
</template>

<script setup>
import Avatar from '@/components/Avatar.vue';

const props = defineProps({
  author: {
    type: Object,
    required: true,
    default: () => {}
  }
});
</script>
