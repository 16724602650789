<template>
  <div class="quote container">
    <div class="grid pt-16">
      <div class="relative grid-item -col-span-12 -col-span-md-10 -col-start-md-2" :class="{ '-col-span-lg-7 -col-start-lg-5': strapi.HorizontalAlignment === 'Right', '-col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4': strapi.HorizontalAlignment === 'Center' }">
        <VIcon icon="fa-solid fa-quote-right" size="120px" class="text-primary" />
        <div class="quoteContent">
          <blockquote>"{{ strapi.QuoteText }}"</blockquote>
          <p class="paragraph-small">/ {{ strapi.Author }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quote',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.v-icon {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  opacity: 0.4;
  transform: translate(-20%, -30%);

  @include is-screen-lg() {
    transform: translate(-45%, -35%);
  }
}
.quoteContent {
  position: relative;
}
</style>
