<template>
  <div ref="slider" class="d-flex align-center slider">
    <div v-for="(item, index) in items" :key="index" ref="slides" class="slide"><slot :item="item" /></div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      required: true
    },
    speed: {
      type: Number,
      default: 1
    },
    direction: {
      type: String,
      default: 'Left'
    }
  },
  data() {
    return {
      loop: null,
      timeout: null,
      isWindowResizing: false
    };
  },
  watch: {
    isWindowResizing(resizing) {
      if (resizing && this.loop) {
        this.loop.revert();
        this.loop = null;
      } else if (!resizing && !this.loop) {
        this.rollText(this.$refs.slides);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.rollText(this.$refs.slides);

      window.addEventListener('resize', this.onResize);
    });
  },
  unmounted() {
    window.removeEventListener(this.onResize);
  },
  methods: {
    onResize() {
      clearTimeout(this.timeout);
      this.isWindowResizing = true;
      this.timeout = setTimeout(() => {
        this.isWindowResizing = false;
      }, 1000);
    },
    rollText(items) {
      if (this.loop) return;
      this.loop = this.horizontalLoop(items, { speed: this.speed || 1 });
    },
    horizontalLoop(items, config) {
      return gsap.context(() => {
        items = gsap.utils.toArray(items);
        config = config || {};

        const tl = gsap.timeline({
          repeat: -1,
          paused: true,
          defaults: { ease: 'none' },
          onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
        });
        const length = items.length;
        const startX = items[0]?.offsetLeft;
        const times = [];
        const widths = [];
        const xPercents = [];
        const pixelsPerSecond = (config.speed || 1.25) * 100;
        const snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1);
        let curX;
        let distanceToStart;
        let distanceToLoop;
        let item;

        gsap.set(items, {
          xPercent: (i, el) => {
            const w = (widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px')));
            xPercents[i] = snap((parseFloat(gsap.getProperty(el, 'x', 'px')) / w) * 100 + gsap.getProperty(el, 'xPercent'));

            return xPercents[i];
          }
        });
        gsap.set(items, { x: 0 });

        const totalWidth = items[length - 1]?.offsetLeft + (xPercents[length - 1] / 100) * widths[length - 1] - startX + items[length - 1]?.offsetWidth * gsap.getProperty(items[length - 1], 'scaleX') + (parseFloat(config.paddingRight) || 0);

        for (let i = 0; i < length; i++) {
          item = items[i];
          curX = (xPercents[i] / 100) * widths[i];
          distanceToStart = item.offsetLeft + curX - startX;
          distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, 'scaleX');
          tl.to(
            item,
            {
              xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
              duration: distanceToLoop / pixelsPerSecond
            },
            0
          )
            .fromTo(
              item,
              {
                xPercent: snap(((curX - distanceToLoop + totalWidth) / widths[i]) * 100)
              },
              {
                xPercent: xPercents[i],
                duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                immediateRender: false
              },
              distanceToLoop / pixelsPerSecond
            )
            .add(`label${i}`, distanceToStart / pixelsPerSecond);
          times[i] = distanceToStart / pixelsPerSecond;
        }
        tl.times = times;

        tl.progress(1, true).progress(0, true); // pre-render for performance

        if (this.direction === 'Right') {
          tl.vars.onReverseComplete();
          tl.reverse();
        } else {
          tl.play();
        }
        return () => {
          gsap.set(this.$refs.slides, { clearProps: 'all' });
          tl.kill();
        };
      }, this.$refs.slider);
    }
  }
};
</script>

<style lang="scss" scoped>
.slider {
  padding: 36px 0;
  position: relative;
  display: flex;
  width: 100vw;
  justify-content: flex-start;

  @include is-screen-lg() {
    padding: 64px 0;
  }
}
.slide {
  padding: 0 24px;

  @include is-screen-lg() {
    padding: 0 48px;
  }

  :deep(.v-responsive) {
    max-width: unset;
    max-height: unset;
  }
}
</style>
