<template>
  <VDialog v-model="dialog" width="auto" min-width="500">
    <template #activator="{ props }">
      <Button color="secondary" :disabled="editingIsDisabled" variant="outlined" v-bind="props" :prepend-icon="triggerIcon">
        {{ triggerLabel }}
      </Button>
    </template>
    <Card class="pa-12">
      <VCardTitle>
        <h4>{{ strapiAddDeliveryAddress.DialogTitle }}</h4>
      </VCardTitle>
      <Form :disabled="loading" @submit.prevent="save">
        <VCardText>
          <label for="deliveryAddressAddress">{{ strapiAddDeliveryAddress.AddressLabel }}</label>
          <TextField id="deliveryAddressAddress" v-model="deliveryAddress.address" autofocus name="customerDeliveryAddress.address" class="mb-4" />
          <label for="deliveryAddressAddress2">{{ strapiAddDeliveryAddress.Address2Label }}</label>
          <TextField id="deliveryAddressAddress2" v-model="deliveryAddress.address2" name="customerDeliveryAddress.address2" class="mb-4" />
          <label for="deliveryAddressPostalCode">{{ strapiAddDeliveryAddress.PostalCodeLabel }}</label>
          <TextField id="deliveryAddressPostalCode" v-model="deliveryAddress.zip" name="customerDeliveryAddress.zip" class="mb-4" />
          <label for="deliveryAddressCity">{{ strapiAddDeliveryAddress.CityLabel }}</label>
          <TextField id="deliveryAddressCity" v-model="deliveryAddress.city" name="customerDeliveryAddress.city" class="mb-4" />
          <label for="deliveryAddressCountry">{{ strapiAddDeliveryAddress.CountryLabel }}</label>
          <SelectCountry id="deliveryAddressCountry" v-model="deliveryAddress.country" name="customerDeliveryAddress.country_id" returnObject class="mb-4" />
          <template v-if="deliveryAddress.country?.hasStates">
            <label for="deliveryAddressState">{{ strapiAddDeliveryAddress.StateLabel }}</label>
            <SelectState id="deliveryAddressState" :key="deliveryAddress.country" v-model="deliveryAddress.state" :queryBuilder="qb => qb.where('freight_country_id', deliveryAddress.country.id).limit(100)" name="customerDeliveryAddress.state_id" returnObject class="mb-4" />
          </template>
          <template v-if="deliveryAddress.country?.requiresVat">
            <label for="deliveryAddressVAT">{{ strapiAddDeliveryAddress.VATLabel }}</label>
            <TextField id="deliveryAddressVAT" v-model="deliveryAddress.vat" :items="$controller.data.vat" name="customerDeliveryAddress.vat" />
          </template>
        </VCardText>
        <VCardActions>
          <Button class="bg-secondary" variant="text" :loading="loading" :disabled="loading" color="white" type="submit">
            {{ strapiAddDeliveryAddress.SaveButtonLabel }}
          </Button>
          <Button variant="outlined" color="secondary" :disabled="loading" @click="cancel">{{ strapiAddDeliveryAddress.CancelButtonLabel }}</Button>
        </VCardActions>
      </Form>
    </Card>
  </VDialog>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'AddDeliveryAddress',
  mixins: [singleSectionEditingHandling],
  props: {
    triggerLabel: {
      type: String,
      default: null
    },
    triggerIcon: {
      type: String,
      default: 'fas fa-plus'
    },
    buttonSize: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      deliveryAddress: {},
      strapiAddDeliveryAddress: this.$controller.data.strapiAddDeliveryAddress
    };
  },
  watch: {
    dialog(val) {
      this.deliveryAddress = {};
      this.isEditing = val;
      this.$emit('onUpdateDialog', val);
    }
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.post('/account/delivery-address/', { customerDeliveryAddress: this.deliveryAddress });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    cancel() {
      this.isEditing = false;
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input {
  height: 47px !important;
}
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -78px;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  display: block;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  &.-visible {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.container-wrapper {
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 15;
  transform: translateY(100%);
  transition:
    opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out 0.2s;
  &.-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
