<template>
  <SelectApi endpoint="tickets" :itemTitle="title">
    <template #item="{ item }">
      <VListItem v-bind="item.props" :title="title(item.item.raw)" />
    </template>
    <template #selection="{ item }">
      {{ title(item.item.raw) }}
    </template>
  </SelectApi>
</template>

<script>
export default {
  name: 'SelectTicket',
  methods: {
    title(opt) {
      return `#${opt.id} - ${opt.title}`;
    }
  }
};
</script>
