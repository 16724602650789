<template>
  <div class="d-flex" style="gap: 0.5rem">
    <Button class="tag-button" :class="{ '-selected': selected }" @click="onToggle(true)">Yes</Button>
    <Button class="tag-button" :class="{ '-selected': noSelected }" @click="onToggle(false)">No</Button>
  </div>
</template>
<script>
export default {
  name: 'FilterButtonBoolean',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      selected: null
    };
  },
  computed: {
    noSelected() {
      return !this.selected && this.$utils.isNotBlank(this.selected);
    }
  },
  created() {
    const searchURL = new URL(window.location);
    const groupParam = searchURL.searchParams.get(this.data.key_name);
    if (groupParam === true || groupParam === false) {
      this.selected = groupParam;
    }
  },
  methods: {
    onToggle(value) {
      if (this.selected === value) {
        this.selected = null;
      } else {
        this.selected = value;
      }
      this.$emit('onFilterUpdate', { filterKey: this.data.key_name, selected: this.selected });
    },
    clear() {
      this.selected = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-button {
  display: inline-grid;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 12px;
  padding: 0 4px;
  background-color: transparent;
  color: $color-black;
  border: 1px solid $color-grey;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;

  &:hover {
    background-color: $color-white;
    border: 1px solid $color-white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.03);
  }

  &.-selected {
    background-color: $color-primary;
    color: $color-white;
    border: 1px solid $color-primary;
  }
}
</style>
