<template>
  <div class="productHero bg-black pt-0" :class="{ '-expanded': viewMoreExpanded, '-blurred': isBlurred }">
    <div class="gradient-light rounded-circle" />
    <img class="background-img" cover :style="`background-image: url('${strapi.Image.url}');`" :class="{ '-expanded': viewMoreExpanded, '-blurred': isBlurred }" />
    <div class="after" />
    <div class="before" />
    <div class="container">
      <div class="grid w-100 pb-20 pt-20 margin-menu">
        <div class="mr-4 grid-item -col-span-12 -col-span-lg-7">
          <h4 v-if="strapi?.Parent" class="h4">{{ strapi?.Parent }}</h4>
          <h1 class="h1 bold text-white">{{ strapi.Heading }}</h1>
          <div ref="wrapper" class="description-wrapper" :class="{ '-expanded': viewMoreExpanded }">
            <p v-if="strapi.Description" class="preamble description" :class="{ '-hidden': !showShortDescription }">{{ strapi.Description }}</p>
            <p v-if="strapi.Description" class="preamble description-space" :class="{ '-expanded': showFullDescription }">{{ strapi.Description }}</p>
          </div>
          <Button textButton class="d-flex align-center h-20px view-more-btn" :class="{ '-expanded': viewMoreExpanded }" @click="onExpandToggle">
            <VIcon :icon="viewMoreIcon" />
            <p class="paragraph -no-margin text-primary ml-2">{{ viewMore }}</p>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductHero',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewMoreExpanded: false,
      showFullDescription: false,
      showShortDescription: true
    };
  },
  computed: {
    isBlurred() {
      return this.isMobile || this.$controller.data?.currentCategory?.is_leaf;
    },
    viewMore() {
      if (this.viewMoreExpanded) {
        return this.strapi?.ViewLess?.Label || 'View less';
      }
      return this.strapi?.ViewMore?.Label || 'View more';
    },
    viewMoreIcon() {
      if (this.viewMoreExpanded) {
        return this.strapi?.ViewLess?.Icon || 'fas fa-chevrons-up';
      }
      return this.strapi?.ViewMore?.Icon || 'fas fa-chevrons-down';
    }
  },
  created() {
    this.setBreadcrumbsBackground('dark');
  },
  methods: {
    onExpandToggle() {
      if (this.viewMoreExpanded) {
        this.showShortDescription = true;
        this.showFullDescription = false;
        const to = setTimeout(() => {
          clearTimeout(to);
          this.$refs.wrapper.style.height = '180px';
          this.viewMoreExpanded = false;
        }, 200);
      } else {
        this.$refs.wrapper.style.height = `${this.$refs.wrapper.scrollHeight}px`;
        this.viewMoreExpanded = true;

        const to = setTimeout(() => {
          clearTimeout(to);
          this.showShortDescription = false;
          this.showFullDescription = true;
        }, 200);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.margin-menu {
  margin-top: $menu-height-desktop;
}
.productHero {
  position: relative;
  overflow: hidden;

  @include is-screen-md() {
    min-height: 20vh;
  }

  .after,
  .before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition:
      height 1.2s ease-in-out,
      opacity 1.2s ease-in-out;
  }

  .before {
    height: 100px;
    opacity: 0;
    background-blend-mode: darken;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .after {
    content: ' ';
    position: absolute;
    opacity: 1;
    height: 100px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(222, 222, 222, 0) 100%);
    transition:
      height 0.4s ease-in-out,
      opacity 0.8s ease-in-out;
  }

  &.-blurred {
    .before {
      height: 300%;
      opacity: 1;
    }
  }

  &.-expanded {
    .after {
      opacity: 0;
    }
    .before {
      height: 300%;
      opacity: 1;
    }
  }
}

.description-wrapper {
  overflow: hidden;
  position: relative;
  height: 180px;
  transition: height 1s ease-in-out;

  .description-space {
    position: absolute;
    top: 0;
    opacity: 0;
    padding-bottom: 20px;
    transition: opacity 0.5s 0s ease-in-out;

    &.-expanded {
      opacity: 1;
    }
  }

  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
    opacity: 1;
    transition: opacity 0.5s 0s ease-in-out;

    &.-hidden {
      opacity: 0;
    }
  }
}

.background-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center 0;
  filter: blur(0px);
  transition:
    transform 1s ease-in-out,
    filter 1s ease-in-out;

  &.-expanded {
    filter: blur(10px);
  }

  &.-blurred {
    filter: blur(10px);
  }
}

.view-more-btn {
  line-height: 20px;
  border-bottom: unset !important;

  .icon {
    position: relative;
  }

  &.-expanded {
    > .icon {
      transform: scaleY(-1);
      top: 3px;
    }
  }
}

.gradient-light {
  position: absolute;
  width: 100vw;
  height: 100vw;
  right: -80px;
  top: 0;
  transform: translateY(-50%);
  background: radial-gradient(50% 50% at 50% 50%, rgba(245, 158, 0, 0.28) 0%, rgba(245, 158, 0, 0) 100%);

  @include is-screen-md() {
    width: 80vw;
    height: 80vw;
  }
}

.container {
  position: relative;
  z-index: 10;
  margin-top: auto;
}

.content {
  position: relative;

  @include is-screen-lg() {
    padding: 128px 0 80px;
  }
}
</style>
