<template>
  <RangeSlider v-model="selected" class="px-2" :step="0.1" :inputBoxes="true" @update:modelValue="emitRange" />
</template>

<script>
import { debounce, isBlank } from '@Utils/default';
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      selected: []
    };
  },
  created() {
    const searchURL = new URL(window.location);

    const groupParam = searchURL.searchParams.get(this.data.key_name);
    const groupParamArray = groupParam?.split(',');

    if (groupParamArray?.length === 2) {
      const minVal = Math.floor(Math.min(...groupParamArray));
      const maxVal = Math.ceil(Math.max(...groupParamArray));
      this.selected.push(minVal < this.$attrs.min ? this.$attrs.min : minVal);
      this.selected.push(maxVal > this.$attrs.max ? this.$attrs.max : maxVal);
    } else {
      this.selected.push(this.$attrs.min);
      this.selected.push(this.$attrs.max);
    }
  },
  methods: {
    emitRange: debounce(async function () {
      if( isBlank(this.selected[0]) || isBlank(this.selected[1]) ) {
        return;
      }
      this.$emit('onFilterUpdate', { filterKey: this.data.key_name, selected: this.selected });
    }, 300),
    clear() {
      this.selected = [];
      this.selected.push(this.$attrs.min);
      this.selected.push(this.$attrs.max);
    }
  }
};
</script>
