<template>
  <ul v-scroll-lock="scrollLock" class="cart-content h-screen d-flex flex-column align-center w-100">
    <CartItem v-for="cartItem in cartItems" :key="cartItem.id" :cartItem="cartItem" />
  </ul>
</template>

<script>
import CartItem from '@Components/CartItem.vue';

export default {
  name: 'CartContent',
  components: { CartItem },
  props: {
    cartItems: {
      type: Array,
      required: true
    },
    scrollLock: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  border-bottom: 1px solid $color-grey-light;
}
li:last-child {
  border-bottom: none;
}
.cart-content {
  overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  padding: 80px 40px 175px; // NOTE: Bottom padding should be equal to the current height of .cart-summary. If its smaller you cant scroll down to the last element in .cart-content
  list-style-type: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.cart-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
</style>
