<template>
  <VTextField ref="input" :model-value="modelValue" hide-details="auto" :error-messages="localErrorMessages" :bg-color="finalBgColor" flat :class="{ 'no-default-bg-color': !bgColor }" color="black" persistent-hint variant="solo" density="compact" persistent-placeholder clearable :rules="rules" @update:modelValue="$emit('update:modelValue', $event)">
    <slot />
    <template v-if="hasSlot('append-inner')" #append-inner>
      <slot name="append-inner" />
    </template>
  </VTextField>
</template>

<script>
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'TextField',
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue', 'onBeforeUnmount'],
  data() {
    return {
      isDirty: false,
      initialValue: this.modelValue
    };
  },
  computed: {
    finalBgColor() {
      return this.bgColor || (this.isDirty ? 'grey-light' : 'grey-background');
    },
    rules() {
      return this.required ? [v => this.$utils.isNotBlank(v) || 'Field is required'] : [];
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isDirty = this.$utils.isNotBlank(newValue);
      }
    }
  },
  beforeUnmount() {
    // this.$emit('update:modelValue', this.initialValue);
    this.$emit('onBeforeUnmount');
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-field) {
  border-radius: 8px;
}
:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-field--error) {
  background-color: $color-validation-red-light !important;
}
</style>
