<template>
  <div>
    <!-- <PageBuilder :modules="strapi.Modules" /> -->
    <HeroDark :strapi="strapi.PageHeroDark" />
    <ProductListing :categories="stockClearanceCategories" :filterAttributes="filterAttributes" :sortingStrategies="sortingStrategies" :strapi="strapiFilterSort" />
  </div>
</template>

<script>
import ProductListing from '@Components/ProductListing.vue';
import HeroDark from '@Components/HeroDark.vue';

export default {
  name: 'ProductListBrand',
  components: { ProductListing, HeroDark },
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  },
  computed: {
    stockClearanceCategories() {
      return this.$controller.data.categories.map(category => {
        const salesReasonKey = `${this.$controller.data.salesReasonsKey}`;
        return {
          ...category,
          queryParams: {
            [salesReasonKey]: true
          }
        };
      });
    }
  },
  mounted() {
    console.log(this.$controller.data);
  }
};
</script>
