<template>
  <div>
    <section class="container my-8 bg-white">
      <h1>Style guide</h1>
      <h2>Typography</h2>
      <div class="grid">
        <div class="grid-item -full-span border pa-2">
          <h1>Heading H1XL</h1>
          <h1>Heading H1</h1>
          <h2>Heading H2</h2>
          <h3>Heading H3</h3>
          <h4>Heading H4</h4>
          <span class="pre-heding">Pre heading</span>
          <p class="preamble">preamble</p>
          <p class="caption">caption</p>
          <p class="paragraph">paragraph M</p>
          <p class="paragraph bold">paragraph M bold</p>
          <p class="paragraph-large">paragraph L</p>
          <p class="paragraph-large bold">paragraph L bold</p>
          <p class="paragraphall">paragraph S</p>
          <p class="paragraph-small bold">paragraph S bold</p>
          <p><a href="#">Link</a></p>
          <blockquote>Block quote</blockquote>
          <button class="button">button text</button>
        </div>
      </div>
    </section>
    <section class="container my-8">
      <h2>Colors in grid</h2>
      <div class="grid border pa-2">
        <div class="grid-item -col-span-6 bg-primary color" />
        <div class="grid-item -col-span-6 bg-primary-light color" />
        <div class="grid-item -col-span-3 bg-primary-semi-light color" />
        <div class="grid-item -col-span-3 bg-secondary color" />
        <div class="grid-item -col-span-3 bg-secondary-dark color" />
        <div class="grid-item -col-span-3 bg-secondary-light color" />
        <div class="grid-item -col-start-3 -col-span-8 bg-accent color" />
        <div class="grid-item -col-span-2 bg-white color border" />
        <div class="grid-item -col-start-2 bg-black color" />
        <div class="grid-item -col-start-4 bg-grey-light color" />
        <div class="grid-item -col-start-6 bg-grey color" />
        <div class="grid-item -col-start-8 bg-grey-dark color" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Styleguide',
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.color {
  height: 40px;
  width: 100%;
}
</style>
