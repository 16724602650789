<template>
  <div :class="{ 'bg-secondary': strapi.DarkBackground }">
    <div class="container">
      <figure class="grid">
        <Card class="grid-item -full-span" elevation="21">
          <video v-if="strapi.VideoFile" class="w-100" :controls="strapi.ShowControls" :autoplay="strapi.AutoPlay" :muted="strapi.AutoPlay || strapi.Muted" :poster="strapi.PosterImage?.url || ''" :loop="strapi.Loop">
            <source :src="strapi.VideoFile.url" />
          </video>
        </Card>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleVideo',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  padding: 40px 0;

  @include is-screen-lg() {
    padding: 80px 0;
  }
}
</style>
