<template>
  <div class="w-100 mb-0 h-100">
    <VList v-scroll-lock="scrollLock" class="menu pt-34 pb-0 bg-secondary d-flex flex-column mb-0">
      <!-- <VList class="menu pt-34 pb-0 bg-secondary d-flex flex-column mb-0"> -->
      <div v-if="topMenu.length > 0" class="top-menu pb-6 flex-grow-1">
        <VListItem v-for="item in topMenu" :key="item">
          <a :href="item.route.show" :aria-label="`${item.title} page`" class="d-flex align-center content-container pa-3">
            <VIcon :icon="item.icon" size="24px" class="text-primary mr-3 pl-0" />
            <VListItemTitle>{{ item.title }}</VListItemTitle>
          </a>
        </VListItem>
      </div>
      <div v-if="bottomMenu.length > 0" class="bg-secondary-dark bottom-menu w-100 d-flex flex-column pt-6 pb-6">
        <VListItem v-for="item in bottomMenu" :key="item" class="pr-6 pl-6 py-3">
          <a :href="item.route.show" :aria-label="`${item.title} page`" class="d-flex">
            <VIcon :icon="item.icon" class="text-primary mr-3 mt-2" size="24px" />
            <span>
              <VListItemTitle>{{ item.title }}</VListItemTitle>
              <p class="mb-0">{{ item.description }}</p>
            </span>
          </a>
        </VListItem>
      </div>
    </VList>
  </div>
</template>

<script>
export default {
  name: 'HamburgerMenuCategoryList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    scrollLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      navigation: this.$dom.application.navigation
    };
  },
  computed: {
    bottomMenu() {
      return this.items.filter(item => item.attach_to_bottom === true);
    },
    topMenu() {
      return this.items.filter(item => item.attach_to_bottom === false);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  border-radius: 8px;
  &:not(.hover-dark) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba($color-secondary-dark, 0.8);
      border-radius: 8px;
      z-index: 0;
      transform: scale(0);
      transition: transform $transition-duration $transition-timing;
    }

    i,
    .v-list-item-title {
      z-index: 1;
      font-weight: 600;
      line-height: 20px;
      transition: color $transition-duration $transition-timing;
    }

    @media (hover: hover) {
      &:not(:disabled):hover:after {
        transform: scale(1);
      }
    }
  }
}
p {
  opacity: 50%;
}

.menu {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
  height: calc(100% + 1px);
}
</style>
