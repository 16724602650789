<script setup>
import { useSlots, computed } from 'vue';

const slots = useSlots();

const isDefaultSlotEmpty = computed(() => !!slots.default);
</script>

<template>
  <VAvatar :class="{ 'bg-grey': isDefaultSlotEmpty }">
    <slot />
  </VAvatar>
</template>
