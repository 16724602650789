<template>
  <Dialog v-if="$utils.isNotNil(data)" v-model="active" :title="data.title" :description="data.body" :icon="icon" :iconColor="iconColor" :persistent="data.persistent" @onCancel="onCancel" @onConfirm="onConfirm" />
</template>

<script>
export default {
  name: 'DialogListener',
  data() {
    return {
      backendData: this.$dom.application.dialog || null,
      data: null,
      active: true
    };
  },
  computed: {
    icon() {
      if (this.data.icon) return this.data.icon;
      switch (this.data.type) {
        case 'success':
          return 'far fa-square-check';
        case 'error':
          return 'far fa-square-exclamation';
        case 'warning':
          return 'far fa-exclamation-triangle';
        case 'info':
          return 'far fa-square-info';
        default:
          return null;
      }
    },
    iconColor() {
      if (this.data.iconColor) return this.data.iconColor;
      switch (this.data.type) {
        case 'success':
          return 'success';
        case 'error':
          return 'error';
        case 'warning':
          return 'warning';
        case 'info':
          return 'info';
        default:
          return 'primary';
      }
    }
  },
  created() {
    this.$mitt.on('dialog', this.trigger);
    if (this.backendData) {
      this.trigger(this.backendData);
    }
  },
  methods: {
    trigger(payload) {
      console.log(payload);
      this.data = { ...payload.message, ...payload.options };
      console.log(this.data);
      this.active = true;
    },
    onCancel() {
      this.active = false;
      if (this.data.id) {
        this.$mitt.emit(`dialog:cancel:${this.data.id}`);
      }
    },
    onConfirm() {
      if (this.data.id) {
        this.$mitt.emit(`dialog:confirm:${this.data.id}`);
      }
    }
  }
};
</script>
