<template>
  <SelectApi endpoint="freight_countries" itemTitle="name" :queryBuilder="queryBuilder">
    <template #item="{ item }">
      <VListItem v-bind="item.props" :prepend-avatar="item.item.raw.flag" density="compact" :title="formatDisplay(item.item.raw)" />
    </template>
    <template #selection="{ item }">
      <VAvatar size="x-small" class="me-2">
        <VImg :src="item.item.raw.flag" />
      </VAvatar>
      {{ formatDisplay(item.item.raw) }}
    </template>
  </SelectApi>
</template>

<script>
export default {
  name: 'SelectCountry',
  props: {
    queryBuilder: {
      type: Function,
      default: qb => qb.limit(300)
    }
  },
  methods: {
    formatDisplay(opt) {
      return `${opt.name} (${opt.code})`;
    }
  }
};
</script>
