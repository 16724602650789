<template>
  <Card class="" :shadowHover="hover" @mouseover="hover = true" @mouseleave="hover = false">
    <a :href="item.strapi.Href" :aria-label="`${item.strapi.Name} page`" class="pr-10 pl-10 pt-5 d-block">
      <ProductRoundImage :hover="hover" :icon="item.strapi.Icon" :image="item.strapi.Image.url" />
      <h4 class="h4 text-center mt-5 mb-3">{{ item.strapi.Name }}</h4>
      <div v-markdown="item.strapi.Description || item.database.description" class="text-grey-dark text-center mb-10 pl-5 pr-5" />
    </a>
    <div v-if="item.database.children" class="pr-10 pl-10 pb-10 d-block">
      <div v-for="child in item.database.children" :key="child.id" class="d-flex justify-space-between mb-3">
        <a class="paragraph -no-margin underline-primary" :aria-label="`${child.name} page`" :href="child.route.publicShow">{{ child.name }}</a>
        <p class="paragraph -no-margin text-grey">{{ child.childArticleCount }}</p>
      </div>
    </div>
  </Card>
</template>

<script>
import ProductRoundImage from '@Components/ProductRoundImage.vue';

export default {
  name: 'ProductCategoriesListItem',
  components: { ProductRoundImage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hover: false
    };
  }
};
</script>

<style lang="scss" scoped></style>
