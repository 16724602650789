<template>
  <div class="bg-grey-background">
    <div class="container pb-10 d-flex align-center flex-column">
      <!-- TODO: Strapify and stylize if we decide to use this -->

      <div v-show="succeded">
        <h1 class="text-center mb-5">{{ strapi.ThankYouHeader }}</h1>
        <!-- <h4 class="text-center mb-5">We will reach out soon, please check your email for confirmation.</h4> -->
        <h4 class="text-center mb-5">{{ strapi.ThankYouText }}</h4>
        <div class="h-200px" />
      </div>

      <div v-show="!succeded" class="form">
        <VForm ref="form" v-model="valid" class="bg-white pa-7 rounded-lg" :disabled="loading || succeded" @submit.prevent="checkValidAndStartCaptcha">
          <div>
            <label class="mr-5 paragraph mb-1 opacity" for="contactUsOffice">{{ strapi.OfficeLabel }}</label>
            <Select id="contactUsOffice" v-model="selectedOffice" return-object :items="offices" placeholder="Please select an office *" item-title="country" item-value="id" />
            <label class="mr-5 paragraph mb-1 opacity" for="contactUsName">{{ strapi.NameLabel }}</label>
            <TextField id="contactUsName" v-model="name" name="contactUs.name" class="mb-5" />
            <label class="mr-5 paragraph mb-1 opacity" for="contactUsEmail">{{ strapi.EmailLabel }}</label>
            <EmailTextField id="contactUsEmail" v-model="email" name="contactUs.email" class="mb-5" />
            <label class="mr-5 paragraph mb-1 opacity" for="contactUsRecipient">{{ strapi.RecipientLabel }}</label>
            <Select id="contactUsRecipient" v-model="selectedRecipient" return-object placeholder="Please select a recipient *" :items="mapRecipientWithKeys()" item-title="name" />
            <label class="mr-5 paragraph mb-1 opacity" for="contactUsDescription">{{ strapi.DescriptionLabel }}</label>
            <Textarea id="contactUsDescription" v-model="description" name="Description" class="mb-5" />
          </div>
          <p>
            {{ strapi.AgreementText }}
            <!-- TODO: Maybe flash the form info to the session instead so that this works better on mobile -->
            <a href="/privacy-policy" target="_blank" aria-label="go to privacy policy">{{ strapi.PrivacyPolicyLink }}</a>
          </p>
          <Captcha ref="captcha" @verify="submit" @expired="resetCaptcha" />
          <Button class="bg-primary" color="text-white" :loading="loading" :disabled="sent" type="submit">
            <VIcon icon="fa-regular fa-paper-plane" class="pr-5 text-white" />
            {{ strapi.SendButtonLabel }}
          </Button>
        </VForm>
        <p class="mt-1 mb-0 text-grey text-right fs-11" :class="{ 'd-flex justify-center': isMobile }">
          <span class="mr-1">{{ strapi.ReCaptchaText }}</span>
          <a href="https://policies.google.com/privacy" aria-label="go to googles privacy policy" class="text-grey">{{ strapi.PrivacyPolicyLink }}</a>
          <span class="mr-1 ml-1">{{ strapi.AndText }}</span>
          <a href="https://policies.google.com/terms" aria-label="go to googles policy terms" class="text-grey">{{ strapi.TermsOfServiceLink }}</a>
          <span class="ml-1">{{ strapi.ApplyText }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Captcha from '@Components/Captcha.vue';
export default {
  name: 'ContactUsForm',
  components: {
    Captcha
  },
  props: {
    recipients: {
      type: Array,
      default: () => []
    },
    offices: {
      type: Array,
      default: () => []
    },
    defaultOfficeID: {
      type: Number,
      default: 0
    }
  },
  emits: ['onSuccessfulContact'],
  data() {
    return {
      succeded: false,
      loading: false,
      valid: false,
      name: '',
      email: '',
      description: '',
      captcha: '',
      selectedRecipient: null,
      selectedOffice: this.offices.length > 0 ? this.offices.find(({ id }) => id === this.defaultOfficeID) : null,
      sent: false
    };
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.name = this.$user.name;
      this.email = this.$user.email;
    }
  },
  methods: {
    checkValidAndStartCaptcha() {
      if (!this.valid) return;
      this.loading = true;
      // If the captcha is empty, start the captcha from vue-recaptcha
      if (this.captcha === '') {
        this.$refs.captcha.execute();
      } else {
        this.submit(this.captcha);
      }
    },
    async submit(captcha) {
      this.captcha = captcha;
      const response = await this.post('/contact/create/', { office: this.selectedOffice?.id, recipient: this.selectedRecipient?.id, name: this.name, email: this.email, description: this.description, captcha: this.captcha });
      this.loading = false;
      if (!response) {
        return;
      }
      this.sent = true;
      this.$emit('onSuccessfulContact');
      this.succeded = true;
    },
    resetCaptcha() {
      this.$refs.captcha.reset();
      this.captcha = '';
    },
    mapRecipientWithKeys() {
      return this.recipients.map((name, id) => ({ id, name }));
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  @include is-screen-md() {
    width: 85%;
  }
  @include is-screen-lg() {
    width: 70%;
  }
}

:deep(.grecaptcha-badge) {
  visibility: hidden;
}
</style>
