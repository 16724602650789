<template>
  <VExpansionPanelText>
    <Form :disabled="loading" :isEditing="isEditing" grid :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
      <!-- Address Row -->
      <label for="deliveryAddressAddress">{{ strapi.AddressLabel }}</label>
      <div>
        <TextField v-if="isEditing" id="deliveryAddressAddress" v-model="customerDeliveryAddress.address" name="customerDeliveryAddress.address" />
        <span v-else>{{ customerDeliveryAddress.address }}</span>
      </div>

      <!-- Address 2 Row -->
      <label for="deliveryAddressAddress2">{{ strapi.Address2Label }}</label>
      <div>
        <TextField v-if="isEditing" id="deliveryAddressAddress2" v-model="customerDeliveryAddress.address2" name="customerDeliveryAddress.address2" />
        <span v-else>{{ customerDeliveryAddress.address2 }}</span>
      </div>
      <!-- Post Code/Zip Row -->
      <label for="deliveryAddressPostalCode">{{ strapi.PostalCodeLabel }}</label>
      <div>
        <TextField v-if="isEditing" id="deliveryAddressPostalCode" v-model="customerDeliveryAddress.zip" name="customerDeliveryAddress.zip" />
        <span v-else>{{ customerDeliveryAddress.zip }}</span>
      </div>

      <!-- City Row -->
      <label for="deliveryAddressCity">{{ strapi.CityLabel }}</label>
      <div>
        <TextField v-if="isEditing" id="deliveryAddressCity" v-model="customerDeliveryAddress.city" name="customerDeliveryAddress.city" />
        <span v-else>{{ customerDeliveryAddress.city }}</span>
      </div>

      <!-- Country Row -->
      <label for="deliveryAddressCountry">{{ strapi.CountryLabel }}</label>
      <div>
        <SelectCountry v-if="isEditing" id="deliveryAddressCountry" v-model="customerDeliveryAddress.country" name="customerDeliveryAddress.country_id" returnObject @update:modelValue="onCountryUpdate" />
        <span v-else>
          <VAvatar size="x-small" class="me-2 mb-1"><VImg :src="customerDeliveryAddress.country.flag" /></VAvatar>
          {{ customerDeliveryAddress.country.name }}
        </span>
      </div>

      <!-- State Row -->
      <template v-if="customerDeliveryAddress.country?.hasStates">
        <label for="deliveryAddressState">{{ strapi.StateLabel }}</label>
        <div>
          <SelectState v-if="isEditing" id="deliveryAddressState" :key="customerDeliveryAddress.country_id" v-model="customerDeliveryAddress.state" :queryBuilder="qb => qb.where('freight_country_id', customerDeliveryAddress.country_id).limit(100)" name="customerDeliveryAddress.state_id" returnObject @update:modelValue="onStateUpdate" />
          <span v-else>{{ customerDeliveryAddress.state?.name }}</span>
        </div>
      </template>

      <!-- VAT Row -->
      <template v-if="customerDeliveryAddress.country?.requiresVat">
        <label for="deliveryAddressVAT">{{ strapi.VATLabel }}</label>
        <div>
          <TextField v-if="isEditing" id="deliveryAddressVAT" :model-value="customerDeliveryAddress.vat?.vat_number" name="customerDeliveryAddress.vat.vat_number" @update:modelValue="onUpdateVatNumber" />
          <span v-else>{{ customerDeliveryAddress.vat?.vat_number }}</span>
        </div>
      </template>
      <template #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer class="d-flex" :class="{ 'flex-column': isMobile }">
          <template v-if="isEditing">
            <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" color="secondary" variant="outlined" :loading="loading || editingIsDisabled" :disabled="loading" :block="isMobile" type="submit">
              {{ strapi.SaveButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :disabled="loading || editingIsDisabled" :block="isMobile" @click="cancel">
              {{ strapi.CancelButtonLabel }}
            </Button>
          </template>
          <template v-else>
            <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" color="secondary" variant="outlined" :disabled="loading || editingIsDisabled" :prepend-icon="strapi.EditButtonIcon" @click="edit">
              {{ strapi.EditButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :disabled="loading || editingIsDisabled" :block="isMobile" :loading="loading" @click="_delete">{{ strapi.DeleteButtonLabel }}</Button>
          </template>
        </footer>
      </template>
    </Form>
  </VExpansionPanelText>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'DeliveryAddress',
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    strapi: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      customerDeliveryAddress: { ...this.modelValue }
    };
  },
  methods: {
    onCountryUpdate(country) {
      if (this.isEditing) {
        this.customerDeliveryAddress.country_id = country?.id;
        this.customerDeliveryAddress.state = null;
      }
    },
    onStateUpdate(state) {
      if (this.isEditing) {
        this.customerDeliveryAddress.state_id = state?.id;
      }
    },
    onUpdateVatNumber(number) {
      /**
       * If the customer delivery address does not have a VAT number, it sets the VAT number to the given number.
       * Otherwise, it updates the existing VAT number with the given number.
       * @param {number} number - The VAT number to set or update.
       */
      if (!this.customerDeliveryAddress.vat) {
        this.customerDeliveryAddress.vat = { vat_number: number };
      } else {
        this.customerDeliveryAddress.vat.vat_number = number;
      }
    },
    /**
     * Saves the customer delivery address by sending a PUT request to the server.
     * Emits an 'update:loading' event with a value of true before sending the request.
     * Emits an 'update:loading' event with a value of false if the response is falsy.
     * Reloads the page with a timeout after a successful response.
     */
    async save() {
      this.$emit('update:loading', true);
      const response = await this.put('delivery-address', { customerDeliveryAddress: this.customerDeliveryAddress });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }

      this.reloadWithTimeout();
    },
    edit() {
      this.isEditing = true;
    },
    /**
     * Cancels the editing of the delivery address and reverts the changes made to the original values.
     */
    cancel() {
      this.isEditing = false;
      this.customerDeliveryAddress = { ...this.modelValue };
    },
    /**
     * Deletes the customer delivery address and emits an event to update the loading state.
     * @emits update:loading
     */
    async _delete() {
      this.$emit('update:loading', true);
      const response = await this.delete(`delivery-address/`, { customerDeliveryAddress: this.customerDeliveryAddress });
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }

      this.reloadWithTimeout();
    }
  }
};
</script>

<style lang="scss" scoped></style>
