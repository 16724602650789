<template>
  <div class="d-flex flex-column">
    <p class="paragraph-small -no-margin bold">You will receive an invoice by email for payment according to agreed terms {{ customer.paymentTermsName }}.</p>
    <p class="caption -no-margin">The order will be processed immediately.</p>
    <p class="caption -no-margin">No additional fees will be charged.</p>
  </div>
</template>

<script>
export default {
  name: 'InvoiceInformation',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return { loading: false };
  }
};
</script>

<style lang="scss" scoped></style>
