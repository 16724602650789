<template>
  <VDataTableServer hover v-bind="options" :loading="loading" @update:options="fetch" @click:row="onRowClick">
    <template #item.number="{ item }">
      <a class="d-md-none underline-primary" :href="`/order/order_confirmation/${item.id}/`" target="_blank" aria-label="download order confirmation">{{ item.number }}</a>
      <span class="d-none d-md-block bold">{{ item.number }}</span>
    </template>
    <template #item.orderConfirmation="{ item }">
      <a class="underline-primary" :href="`/order/order_confirmation/${item.id}/`" target="_blank" aria-label="download order confirmation">Download</a>
    </template>
    <template #tfoot>
      <tr>
        <td>
          <TextField v-model="query" type="search" placeholder="Search" class="position-md-absolute w-auto min-w-md-275px min-w-lg-350px bottom-5px mt-2" />
        </td>
      </tr>
    </template>
  </VDataTableServer>
</template>

<script>
import { debounce } from '@Utils/default';

export default {
  name: 'CurrentOrders',
  data() {
    return {
      loading: false,
      query: '',
      options: {
        search: '',
        itemsLength: 0,
        itemsPerPage: 25
      }
    };
  },
  computed: {
    headers() {
      const baseHeaders = [
        {
          title: 'No.',
          sortable: true,
          key: 'number'
        },
        {
          title: 'Date',
          sortable: true,
          key: 'created_at',
          value: item => this.$formatDateAsDayMonthYear(item.created_at, this.isNotMobile ? 'full' : '')
        },
        {
          title: 'Total',
          sortable: true,
          key: 'totalPrice',
          value: item => this.$moneyFormat(item.order.totalPrice, item.currency)
        },
        {
          title: 'Status',
          sortable: true,
          key: 'status'
        }
      ];

      if (this.isNotMobile) {
        baseHeaders.push({
          title: 'User',
          sortable: true,
          key: 'user'
        });
        baseHeaders.push({
          title: 'Order Confirmation',
          sortable: false,
          key: 'orderConfirmation'
        });
      }

      return baseHeaders;
    }
  },
  watch: {
    query: debounce(function (query) {
      this.options.search = query;
    }, 500),
    isMobile() {
      this.options.headers = this.headers; // Update headers when isMobile changes
    }
  },
  created() {
    this.options.headers = this.headers; // Initialize headers
  },
  methods: {
    async fetch(payload) {
      this.loading = true;
      const response = await this.get('/account/order-history/current-orders/', payload);
      this.loading = false;
      if (!response) return;
      this.options = { ...this.options, ...payload, ...response };
    },
    onRowClick(event, data) {
      this.$redirect(`/order/order_confirmation/${data.item.id}/`, { newTab: true });
    }
  }
};
</script>

<style lang="scss" scoped></style>
