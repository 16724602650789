import * as _Sentry from '@sentry/vue';

export default class Sentry {
  static init(Vue) {
    const data = window.Techship.application;
    const isProduction = window.Techship.env.sentry_environment == 'production';
    if (!window.Techship.env.enable_sentry) return;
    _Sentry.init({
      Vue,
      dsn: window.Techship.env.sentry_dsn,
      environment: window.Techship.env.sentry_environment,
      release: '1.0.0',
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      // replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      // replaysOnErrorSampleRate: 1.0,
      integrations: [
        new _Sentry.Replay({
          maskAllText: isProduction ? true : false,
          blockAllMedia: isProduction ? true : false
        })
      ],
      tracePropagationTargets: ['localhost', /^https?:\/\/(dev\.|test\.|)techship\.com/],
      // Session Replay
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      // eslint-disable-next-line prettier/prettier
      ignoreErrors: [
        'ResizeObserver loop completed with undelivered notifications.',
        'Event `CustomEvent` (type=error) captured as exception',
        "Cannot read properties of undefined (reading 'site_id')",
        'ResizeObserver loop limit exceeded',
        'U.at is not a function',
        'q.at is not a function',
      ],
      beforeSend(event, hint) {
        // Get the user agent from the request context (if available)
        const userAgent = event.request?.headers?.['User-Agent'] || event.request?.headers?.['user-agent'];
        // List of known bot user agents
        const botUserAgents = [
          'googlebot', // Google's web crawler
          'bingbot', // Bing's web crawler
          'slurp' // Yahoo's web crawler
        ];
        // Check if the user agent matches any known bots
        const isBot = botUserAgents.some(botAgent => userAgent.includes(botAgent));
        // If the user agent is identified as a bot, do not send the report to Sentry
        if (isBot) {
          return null;
        }
        // Otherwise, return the event for Sentry to handle
        return event;
      }
    });

    if (data.user) {
      _Sentry.setTag('User', `${data.user.id} (${data.user.name})`);
      _Sentry.setContext('User', { id: data.user.id, name: data.user.name });
    }
  }
}
