<template>
  <VPagination :model-value="modelValue" :length="length" :total-visible="totalVisible" rounded="lg" variant="text" @update:modelValue="$emit('update:modelValue', $event)" />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    modelValue: {
      type: Number,
      default: 1
    },
    totalVisible: {
      type: Number,
      default: 6
    },
    length: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue(page) {
      this.updateURLParameter('page', page);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-pagination__item--is-active) {
  .v-btn {
    background-color: $color-secondary !important;
    color: $color-white !important;
  }
  .v-pagination__item--is-active .v-btn__overlay {
    display: none;
  }
}

:deep(.v-btn) {
  &:hover {
    background-color: $color-white !important;
    color: $color-black !important;
    .v-btn__overlay {
      display: none;
    }
  }
}
</style>
