<template>
  <div class="container justify-space-around justify-center py-20">
    <MasonryWall :items="items" :ssr-columns="3" :column-width="400" :gap="49">
      <template #default="{ item }">
        <ProductCategoriesListItem v-if="item.strapi && item.database" :key="item.Name" :item="item" class="" />
        <ProductCategoriesListCounter v-else key="counter" class="" :strapi="strapi.ProductCounter" :count="item.count" :mainCategoriesCount="item.mainCategoriesCount" />
      </template>
    </MasonryWall>
  </div>
</template>

<script>
import ProductCategoriesListItem from '@Components/ProductCategoriesListItem.vue';
import ProductCategoriesListCounter from '@Components/ProductCategoriesListCounter.vue';
import MasonryWall from '@yeger/vue-masonry-wall';

export default {
  name: 'ProductCategoriesList',
  components: { ProductCategoriesListItem, ProductCategoriesListCounter, MasonryWall },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    totalProductsInMainCategories() {
      return this.$reduce(this.$pluck(this.strapi.ProductCategories, 'database.childArticleCount'), (a, b) => a + b, 0);
    },
    items() {
      const itemsWithPlaceholder = [...this.strapi.ProductCategories];
      itemsWithPlaceholder.splice(2, 0, { count: this.totalProductsInMainCategories, mainCategoriesCount: itemsWithPlaceholder.length });
      return itemsWithPlaceholder;
    }
  }
};
</script>

<style lang="scss" scoped></style>
