<template>
  <div class="d-flex justify-center">
    <Button class="bg-secondary text-white h-40px mb-25" color="color-white" :href="strapi.Href">
      <VIcon v-if="Icon" :icon="strapi.Icon" size="medium" />
      {{ strapi.Label }}
    </Button>
  </div>
</template>

<script>
export default {
  name: 'SingleButton',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped></style>
