<template>
  <div class="navigation-container" :class="{ 'bg-secondary': hasBackground, 'is-hidden': !isVisible, 'overlay-is-visible': overlayIsVisible }">
    <div class="d-flex" :class="{ 'border-white': !hasBackground }">
      <div class="container">
        <div class="border-b d-flex align-center justify-center h-80px">
          <VAppBarNavIcon variant="text" class="text-white fs-25 ml-n-4" size="large" @click="toggleHamburgerMeny" />
          <a href="/" class="d-inline-block ml-1 mt-1" style="line-height: 0" aria-label="to go home"><Logo alt="Techship Inverse Logo - Click to return to the homepage" inverse width="150px" /></a>
          <div class="d-flex justify-end w-100">
            <Button iconButton class="mr-2" aria-label="search icon" icon="far fa-search" color="white" iconSize="20px" @click.stop="drawer = !drawer" />
            <MyAccountButton class="mr-1" :class="{ 'order-1 mr-3': $isNotLoggedIn }" />
            <CartButton class="order-3" />
          </div>
        </div>
      </div>
      <!-- <VNavigationDrawer :model-value="drawer" order="-1" location="top" permanent class="bg-secondary h-screen" disable-resize-watcher disable-route-watcher @update:modelValue="OnDrawerUpdate($event)"> -->
      <VNavigationDrawer v-model="drawer" order="-1" location="top" permanent class="bg-secondary h-screen" disable-resize-watcher disable-route-watcher>
        <!-- <div v-scroll-lock="isScrollLocked" class=""> -->
        <div class="h-64px d-flex align-center topbar bg-secondary">
          <VIcon size="24px" icon="far fa-xmark" class="ml-6" color="white" ariaLabel="Close mobile menu" @click="closeMenu" />
        </div>
        <div v-scroll-lock="isScrollLocked" class="drawer-one-content-container d-flex flex-column justify-space-between mt-16">
          <div class="mt-4 px-7">
            <SearchBar class="" @onMenuChange="searchBarIsOpen = $event" />
            <div class="d-flex flex-column">
              <Button v-for="(item, index) in items" :key="index" plainNoAnimation color="white" variant="plain" class="h2 mb-0 text-white bold mt-5 d-flex justify-space-between category-btn" @click="setActiveMenu(index)">
                <VIcon :icon="item.icon" size="24px" class="text-primary mr-3" />
                {{ item.title }}
                <span class="d-flex justify-end w-100">
                  <VIcon icon="fas fa-arrow-right" class="text-primary" size="24px" />
                </span>
              </Button>
            </div>
          </div>
          <CurrencyDropdown class="px-7 mb-30" :class="{ 'order-2': $isNotLoggedIn }" />
        </div>
        <!-- </div> -->
      </VNavigationDrawer>
      <VNavigationDrawer v-model="secondDrawer" order="-2" location="right" permanent disable-resize-watcher disable-route-watcher class="bg-secondary secondary-menu w-100">
        <!-- <div class="h-100 w-100 overflow-auto"> -->
        <div class="topbar bg-secondary">
          <div class="d-flex h-64px align-center">
            <VIcon size="24px" icon="far fa-xmark" class="ml-5" color="white" ariaLabel="Close mobile menu" @click="closeMenu" />
            <div class="flex-grow-1 text-center pr-10">
              <p class="mb-0 paragraph bold">{{ activeMenu?.title }}</p>
            </div>
          </div>
          <div class="d-flex px-7">
            <Button class="d-flex align-center back-btn" color="white" variant="plain" @click="goBackToStartMenu">
              <VIcon icon="fas fa-arrow-left" size="24px" class="text-primary mr-3" />
              <p class="paragraph-small -no-margin">Go Back</p>
            </Button>
          </div>
        </div>
        <!-- </div> -->
        <HamburgerMenuCategoryList :scrollLock="isScrollLocked" :items="activeMenu?.children" :index="activeMenuIndex" />
      </VNavigationDrawer>
    </div>
  </div>
</template>

<script>
import MyAccountButton from '@Components/MyAccountButton.vue';
import CartButton from '@Components/CartButton.vue';
import SearchBar from '@Components/SearchBar.vue';
import CurrencyDropdown from '@Components/CurrencyDropdown.vue';
import HamburgerMenuCategoryList from '@Components/HamburgerMenuCategoryList.vue';
// import { useScrollLock } from '@vueuse/core'; // Import useScrollLock from @vueuse/core

export default {
  name: 'MobileNavigation',
  components: { MyAccountButton, CartButton, SearchBar, CurrencyDropdown, HamburgerMenuCategoryList },
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    hasBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      drawer: false,
      secondDrawer: false,
      activeMenuIndex: null,
      activeMenu: null,
      navigation: this.$dom.application.navigation,
      items: [],
      isScrollLocked: false,
      searchBarIsOpen: false
    };
  },
  computed: {
    overlayIsVisible() {
      return this.drawer || this.secondDrawer;
    }
    // isScrollLocked() {
    //   return this.overlayIsVisible;
    // }
  },
  watch: {
    // searchBarIsOpen(val) {
    //   console.log(val);
    // },
    overlayIsVisible(val) {
      if (val) {
        // console.log('Overlay is visible');
        // setTimeout(() => {
        // if(this.searchBarIsOpen)
        this.isScrollLocked = true;
        // console.log(this.$refs.navContainer);
        // this.$disableBodyScroll(this.$refs.navContainer);
        // }, 800);
      } else {
        // console.log('Overlay is not visible');
        this.isScrollLocked = false;
        this.$clearAllBodyScrollLocks();
      }
    },
    drawer(val) {
      // console.log('First Drawer', val);
    },
    secondDrawer(val) {
      // console.log('Second Drawer', val);
    },
    isMobile: {
      handler(newVal, oldVal) {
        if (!newVal) {
          // console.log('isMobile', newVal);
          this.drawer = false;
          this.secondDrawer = false;
          this.activeMenu = null;
        }
      },
      immediate: true
    }
  },
  created() {
    this.$utils.each(this.navigation, (value, index) => {
      if (this.$utils.isEmpty(value.children)) return;
      this.$utils.each(value.children, (item, index) => {
        this.items.push(item);
      });
    });
  },
  methods: {
    OnDrawerUpdate(val) {
      // console.log('Drawer update', val);
    },
    toggleHamburgerMeny() {
      // console.log('Toggle hamburger menu');
      this.drawer = !this.drawer;
    },
    setActiveMenu(index) {
      if (this.activeMenuIndex !== index) {
        this.activeMenuIndex = index;
        this.activeMenu = this.items[index];
        this.secondDrawer = true;
      }
    },
    goBackToStartMenu() {
      // console.log('Go back to start menu');
      this.activeMenu = null;
      this.activeMenuIndex = null;
      this.secondDrawer = false;
      this.drawer = true;
    },
    closeMenu() {
      // console.log('Close menu');
      this.drawer = false;
      this.secondDrawer = false;
      this.activeMenuIndex = null;
      this.activeMenu = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer-one-content-container {
  height: calc(100vh - 64px);
  // overflow-y: auto;
  // overflow-x: hidden;
}
.secondary-menu {
  height: 100vh !important;
  height: 100svh !important;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  width: 100vw;
}

.category-btn {
  line-height: 24px;
  :deep(span .button) {
    font-size: 2rem !important;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
  :deep(.v-btn__content) {
    width: 100% !important;
  }
}

.back-btn {
  padding-left: 0 !important;
}
</style>
