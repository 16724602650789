export default {
  data() {
    return {
      isEditing: false,
      editingIsDisabled: false
    };
  },
  watch: {
    isEditing(val) {
      this.$mitt.emit('account.updateIsEditing', { component: this.$options.name, isEditing: val });
    }
  },
  created() {
    this.$mitt.on('account.updateIsEditing', payload => {
      if (payload.component !== this.$options.name) {
        this.editingIsDisabled = payload.isEditing;
        if (payload.isEditing) {
          this.isEditing = false;
        }
      }
    });
  }
};
