<template>
  <div class="container pt-10">
    <div class="d-flex flex-wrap justify-space-around mb-10 pa-15 bg-white rounded-lg">
      <div v-for="info in strapi.Company" :key="info" class="">
        <h2>{{ info.Title }}</h2>
        <p>{{ info.Location }}</p>
        <a :href="`mailto:${info.Email}`" aria-label="open email">
          <p>
            <VIcon class="email-icon mr-3" size="x-large" icon="far fa-circle-envelope" />
            {{ info.Email }}
          </p>
        </a>
        <p>
          <VIcon icon="far fa-circle-phone" class="phone-icon mr-3" size="x-large" />
          {{ info.PhoneNumber }}
        </p>
        <h6>{{ info.Company }}</h6>
        <p>{{ info.Address }}</p>
        <p class="mb-1 font-weight-bold">{{ companyIdTitle(info.Company) }}</p>
        <p>{{ info.CompanyID }}</p>
        <template v-if="info.VATNo">
          <p class="mb-1 font-weight-bold">VAT No.</p>
          <p>{{ info.VATNo }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    companyIdTitle(companyName) {
      if (companyName.toLowerCase().includes('inc')) {
        return 'Company ID/EIN No.';
      }
      return 'Corporate ID No.';
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-icon {
  opacity: 0.5;
}
.email-icon {
  opacity: 0.5;
}
</style>
