import axios from 'axios';

export default {
  methods: {
    async refreshCsrfToken() {
      try {
        const response = await this.get('/refresh-csrf_token/');
        if (!response) return;

        window.Techship.application.csrfToken = response.csrfToken;
        axios.defaults.headers.common['X-CSRF-TOKEN'] = response.csrfToken;
      } catch (error) {
        console.error('Error refreshing CSRF token', error);
      }
    }
  },
  created() {
    setInterval(this.refreshCsrfToken, 3600000); // Refresh token every hour (3600000 milliseconds)
  }
};
