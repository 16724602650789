import axios from 'axios';
import { Model as BaseModel } from 'vue-api-query';
import { isArray } from '@Utils/default';

BaseModel.$http = axios;

export default class GenericModel extends BaseModel {
  baseURL() {
    return window.Techship.application.apiEndpoint;
  }
  request(config) {
    return this.$http.request(config);
  }
  whereWithOperator(field, operator, value) {
    if (isArray(value)) {
      this.whereIn(field, addOperatorToValues(value, operator));
    } else {
      this.where(field, addOperatorToValue(value, operator));
    }
    return this;
  }
  whereNot(field, value) {
    this.where(field, addOperatorToValue(value, 'neq'));
    return this;
  }
  whereNotIn(field, values) {
    this.whereIn(field, addOperatorToValues(values, 'nin'));
    return this;
  }
  whereBetween(field, values) {
    this.whereIn(field, addOperatorToValues(values, 'bt'));
    return this;
  }
  whereNotBetween(field, values) {
    this.whereIn(field, addOperatorToValues(values, 'nbt'));
    return this;
  }
  whereGreaterThan(field, value) {
    this.where(field, addOperatorToValue(value, 'gt'));
    return this;
  }
  whereGreaterThanOrEqual(field, value) {
    this.where(field, addOperatorToValue(value, 'gte'));
    return this;
  }
  whereLessThan(field, value) {
    this.where(field, addOperatorToValue(value, 'lt'));
    return this;
  }
  whereLessThanOrEqual(field, value) {
    this.where(field, addOperatorToValue(value, 'lte'));
    return this;
  }
  new(attributes = null) {
    this.custom(`${this.resource()}/stub`);

    if (attributes) {
      this.params({
        attributes: attributes
      });
    }

    return this.first();
  }
}

function addOperatorToValue(value, operator) {
  return `${operator}|${value}`;
}

function addOperatorToValues(values, operator) {
  const $values = [...values];
  $values[0] = addOperatorToValue($values[0], operator);

  return $values;
}

/**
 * Returns a generic model targeting given endpoint
 *
 * @param {string} endpoint of the resource to query
 * @returns GenericModel
 */
export function query(endpoint) {
  endpoint = endpoint.endsWith('/') ? endpoint : `${endpoint}/`;

  return new (class GenereratedModel extends GenericModel {
    // Implement a default request method
    request(config) {
      return this.$http.request(config);
    }

    resource() {
      return endpoint;
    }
  })();
}

/**
 * Returns a generic model targeting given endpoint
 *
 * @param {string} endpoint of the resource to query
 * @returns GenericModel
 */
export function newModel(props, endpoint) {
  endpoint = endpoint.endsWith('/') ? endpoint : `${endpoint}/`;
  return new (class GenereratedModel extends GenericModel {
    // Implement a default request method
    request(config) {
      return this.$http.request(config);
    }

    resource() {
      return endpoint;
    }
  })({ ...props });
}
