<template>
  <div class="pr-10 pl-10 pt-20 d-flex flex-column justify-center align-center h-100">
    <VIcon icon="fa-regular fa-basket-shopping" size="56" color="primary" class="mb-12" />
    <h2 class="paragraph-large bold mb-1">{{ cart.CartEmptyTitle }}</h2>
    <p class="text-center mb-5">{{ cart.CartEmptyDescription }}</p>
    <p class="mt-10">
      <a :href="cart.CartEmptyHref">{{ cart.CartEmptyButtonLabel }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CartEmpty',
  data() {
    return {
      cart: this.$dom.application.cart
    };
  }
};
</script>
