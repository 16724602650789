<template>
  <ExpansionPanel :title="_user.name" :icon="strapi.UserIcon" :disabled="editingIsDisabled">
    <VExpansionPanelText>
      <Form :disabled="loading" grid :isEditing="isEditing" :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
        <template v-if="!isEditing">
          <label>{{ strapi.PositionLabel }}</label>
          <p>{{ _user.position || 'N/A' }}</p>
          <label>{{ strapi.LastLoginLabel }}</label>
          <p>{{ $utils.date(_user.last_login, 'full') }}</p>
          <label>{{ strapi.LoginsLabel }}</label>
          <p>{{ _user.logins }}</p>
          <label>{{ strapi.CreatedLabel }}</label>
          <p>{{ $utils.date(_user.created_at, 'full') }}</p>
        </template>
        <template v-else>
          <label for="userName">{{ strapi.NameLabel }}</label>
          <TextField id="userName" v-model="_user.name" name="user.name" autocomplete="name" />
          <label for="userEmail">{{ strapi.EmailLabel }}</label>
          <EmailTextField id="userEmail" v-model="_user.email" name="user.email" />
          <label for="userPhone">{{ strapi.PhoneLabel }}</label>
          <PhoneTextField id="userPhone" v-model="_user.phone" name="user.phone" />
          <label for="userMobile">{{ strapi.MobilePhoneLabel }}</label>
          <PhoneTextField id="userMobile" v-model="_user.mobile" name="user.mobile" />
          <label for="userPosition">{{ strapi.PositionLabel }}</label>
          <TextField id="userPosition" v-model="_user.position" name="user.position" />
        </template>
        <template #actions>
          <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
          <footer class="d-flex" :class="{ 'flex-column': isMobile }">
            <template v-if="isEditing && isMe">
              <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" color="secondary" :block="isMobile" variant="outlined" :disabled="loading" :loading="loading" type="submit">{{ strapi.SaveButtonLabel }}</Button>
              <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="cancel">{{ strapi.CancelButtonLabel }}</Button>
            </template>
            <template v-else>
              <Button v-if="isMe" :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" color="secondary" :block="isMobile" variant="outlined" :disabled="loading" :prependIcon="strapi.EditButtonIcon" @click="edit">
                {{ strapi.EditButtonLabel }}
              </Button>
              <Button v-if="!isMe" color="secondary" variant="outlined" :disabled="loading" :block="isMobile" :loading="loading" @click="_delete">{{ strapi.DeleteButtonLabel }}</Button>
            </template>
          </footer>
        </template>
      </Form>
    </VExpansionPanelText>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@Components/ExpansionPanel.vue';
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'User',
  components: { ExpansionPanel },
  mixins: [singleSectionEditingHandling],
  props: {
    user: {
      type: Object,
      required: true
    },
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      _user: { ...this.user }
    };
  },
  computed: {
    isMe() {
      return this.user.id === this.$user.id;
    }
  },
  methods: {
    edit() {
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this._user = { ...this.user };
    },
    async save() {
      this.loading = true;
      const response = await this.put('user/', { user: this._user });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    async _delete() {
      this.loading = true;
      const response = await this.delete('user/', { user: this._user });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    }
  }
};
</script>

<style lang="scss" scoped></style>
