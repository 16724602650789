<template>
  <div>
    <Styleguide v-if="showStyleGuide" />
    <HomeHero v-if="strapi.Hero" :strapi="strapi.Hero" />
    <PageBuilder v-if="strapi.Modules" :modules="strapi.Modules" disabledHeroCheck />
  </div>
</template>

<script>
import Styleguide from '@Components/Styleguide.vue';
import HomeHero from '@Components/HomeHero.vue';

export default {
  name: 'HomeIndex',
  components: { Styleguide, HomeHero },
  props: {
    showStyleGuide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  }
};
</script>
