<template>
  <VTooltip>
    <template #default>
      <slot name="default" />
    </template>
  </VTooltip>
</template>
<script></script>
<style lang="scss" scoped>
:deep(.tooltip-font-size) {
  font-size: $base-font-size;
}
</style>
