<template>
  <!-- TODO: Strapify text, stylize text -->
  <div class="d-flex align-center">
    <div class="stock-badge" :class="stockBadge.style" />
    <p class="paragraph-small bold my-auto ml-2 w-auto">{{ stockBadge.text }}</p>
  </div>
</template>
<script>
export default {
  name: 'StockIndicator',
  props: {
    estRestockAt: {
      type: String,
      default: null
    },
    inStock: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    stockBadge() {
      switch (true) {
        case this.inStock:
          return { style: 'in-stock', text: 'In Stock' };
        case this.$isNotBlank(this.estRestockAt):
          return { style: 're-stock', text: `Back in Stock ${this.$date(this.estRestockAt)}` };
        default:
          return { style: 'out-of-stock', text: 'Out of Stock' };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.in-stock {
  border-color: $color-validation-green !important;
}

.out-of-stock {
  border-color: $color-validation-red !important;
}

.re-stock {
  border-color: $color-validation-yellow !important;
}

.stock-badge {
  height: 0px;
  width: 0px;
  border-radius: 100%;
  border-width: 8px; // This controlls the badge size
  border-style: solid;
  border-color: $color-grey;
}
</style>
