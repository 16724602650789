<template>
  <img v-if="inverse" :src="logo.url" :alt="logo.alternativeText" />
  <img v-else :src="logo.url" :alt="logo.alternativeText" />
</template>

<script>
export default {
  name: 'Logo',
  props: {
    inverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logo: this.inverse ? this.$logos.inverse : this.$logos.default
    };
  }
  // computed: {
  //   aspectRatio() {
  //     return this.logo.width / this.logo.height;
  //   }
  // }
};
</script>
