<template>
  <div class="cart-topbar bg-secondary pr-10 pl-10 d-flex align-center">
    <div class="cart-icon">
      <VIcon icon="fa-regular fa-basket-shopping" size="small" />
      <span v-if="hasCartItems" class="cart-count caption -no-margin text-center rounded-circle bg-accent">{{ cartCount }}</span>
    </div>
    <h1 class="paragraph bold ml-3 mb-0 flex-grow-1">{{ cart.CartTitle }}</h1>
    <Button iconButton iconSize="small" icon="fa-regular fa-xmark" color="white" ariaLabel="Close shop cart" @click="closeCart" />
  </div>
</template>

<script>
export default {
  name: 'CartTopbar',
  data() {
    return {
      cartCount: 0,
      cart: this.$dom.application.cart
    };
  },
  computed: {
    hasCartItems() {
      return this.cartCount > 0;
    }
  },
  created() {
    this.$mitt.on('cart:count', count => {
      this.cartCount = count;
    });
  },
  methods: {
    closeCart() {
      this.$mitt.emit('cart:close');
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-topbar {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
}

.cart-icon {
  position: relative;
}

.cart-count {
  min-width: 16px;
  height: 16px;
  position: absolute;
  top: -4px;
  right: -6px;
  padding: 1px;
  line-height: 1.1;
}
</style>
