<template>
  <Card data-menu-bg="true" adjustToMenu>
    <template #title>
      <div class="container d-flex pt-20 pb-26">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon class="w-55px h-55px" icon="fas fa-comments-question-check" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ strapi.Heading }}</h1>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-20" :class="{ 'pt-10': isMobile }">
      <div class="container" :class="{ 'pl-0 pr-0': isMobile }">
        <FilterDropDown v-if="isMobile" :strapi="strapi" :data="FaqCategories" :class="{ 'ml-10': isMobile }" />
        <div class="grid">
          <div v-if="isNotMobile" class="grid-item -col-span-3">
            <div class="d-flex flex-column mr-10">
              <CategoryLinks :data="FaqCategories" class="tag" :strapi="strapi" />
            </div>
          </div>
          <div class="grid-item -col-span-9" :class="{ '-col-span-12': isMobile }">
            <ExpansionPanels v-model="panel">
              <Faq v-for="(faq, index) in faqs" :key="index" :faq="faq" :strapi="{ question: { label: strapi.QuestionLabel }, solution: { label: strapi.SolutionLabel }, permanentLink: { text: strapi.PermanentLinkText } }" />
            </ExpansionPanels>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import Faq from '@Components/Faq.vue';
import CategoryLinks from './components/CategoryLinks.vue';
import FilterDropDown from './components/FilterDropDown.vue';

export default {
  name: 'FaqIndex',
  components: { ExpansionPanels, Faq, CategoryLinks, FilterDropDown },
  data() {
    return {
      loading: false,
      panel: null,
      ...this.$controller.data
    };
  },
  mounted() {
    console.log(this.faqs);
  }
};
</script>

<style lang="scss" scoped></style>
