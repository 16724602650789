import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-CSRF-TOKEN'] = window.Techship.application.csrfToken;

export const methods = {
  GET: get,
  POST: post,
  PUT: put,
  DELETE: _delete
};

const errorHandler = (error, resolve, reject) => {
  if (error.response) {
    return responseHandler(error.response, resolve, reject);
  } else if (error.request) {
    return reject(new RequestAbortedError());
  } else {
    return reject({ errors: [internalServerError().message] });
  }
};

const responseHandler = (response, resolve, reject) => {
  if (!response) {
    return;
  }
  if (response.status >= 200 && response.status < 300) {
    return resolve(response.data);
  }
  const responseData = response.data;

  if (!responseData.errors && !responseData.warnings) {
    return reject({ errors: [responseData.message || internalServerError().message] });
  }
  return reject({ errors: responseData.errors || [], warnings: responseData.warnings || [] });
};

export function get(url, params = {}, cancelToken) {
  return new Promise((resolve, reject) => {
    const config = { params: params.data || params };

    if (cancelToken) {
      config.cancelToken = cancelToken;
    }

    axios
      .get(url, config)
      .then(response => {
        responseHandler(response, resolve, reject);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          reject(createError(`Request canceled: ${error.message}`));
        } else {
          errorHandler(error, reject, reject);
        }
      });
  });
}

export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params.data || params)
      .then(response => {
        responseHandler(response, resolve, reject);
      })
      .catch(error => {
        errorHandler(error, resolve, reject);
      });
  });
}

export function put(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params.data || params)
      .then(response => {
        responseHandler(response, resolve, reject);
      })
      .catch(error => {
        errorHandler(error, resolve, reject);
      });
  });
}

export function _delete(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params: params.data || params })
      .then(response => {
        responseHandler(response, resolve, reject);
      })
      .catch(error => {
        errorHandler(error, resolve, reject);
      });
  });
}

export class RequestAbortedError extends Error {
  constructor(...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestAbortedError);
    }

    this.name = 'RequestAbortedError';
  }
}

function createError(message) {
  return new Error(message);
}

export const internalServerError = () => new Error('Something went wrong when trying to load the content, please refresh the page and try again.');
