<template>
  <VAutocomplete :label="label" :items="formattedItems" :error-messages="localErrorMessages" :bg-color="finalBgColor" :class="{ 'no-default-bg-color': !bgColor }" color="black" :item-value="itemValue" density="compact" persistent-placeholder :rules="rules" variant="solo" @update:modelValue="$emit('update:modelValue', $event)" />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    items: {
      default: () => []
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    },
    bgColor: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      localErrorMessages: this.errorMessages,
      isDirty: false
    };
  },
  computed: {
    formattedItems() {
      if (this.$utils.isObject(this.items) || this.$utils.some(this.items, item => this.$utils.isNotObject(item))) {
        return this.$utils.map(this.items, (value, key) => ({
          id: parseInt(key),
          name: value
        }));
      } else {
        return this.items;
      }
    },
    finalBgColor() {
      return this.bgColor || (this.isDirty ? 'grey-light' : 'grey-background');
    },
    rules() {
      return this.required ? [v => !!v || 'Field is required'] : [];
    }
  },
  watch: {
    errorMessages: {
      immediate: true,
      handler(newErrorMessages) {
        this.localErrorMessages = newErrorMessages;
      }
    },
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isDirty = this.$utils.isNotBlank(newValue);
        this.localErrorMessages = [];
      }
    }
  },
  created() {
    if (this.formattedItems) {
      this.$mitt.on(`set-error-messages-${this.formattedItems}`, errors => {
        this.localErrorMessages = errors;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-select--error) {
  background-color: $color-validation-red-light !important;
}
</style>
