<template>
  <div class="main-wrapper bg-white mt-20 pt-10">
    <div class="container">
      <div class="d-flex flex-row align-center py-30">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div class="bg-primary d-flex align-center justify-center rounded-circle px-3 py-3 mr-6">
              <VIcon color="white" icon="fa-light fa-file" size="x-large" />
            </div>
            <h1 class="text-secondary my-0">
              {{ strapi.Title }}
              <span class="text-grey">{{ ticket.id }}</span>
            </h1>
          </div>
          <p class="text-black mr-2 fs-32">{{ ticket.title }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex bg-grey-background container justify-space-between">
      <div class="wrapper w-620px h-570px bg-white py-10 px-10 mt-10 mr-10 mb-10 rounded-lg">
        <div>
          <Vicon :icon="strapi.DetailSummaryIcon" />
          <p class="paragraph-large">
            {{ strapi.DetailSummaryTitle }}
          </p>
        </div>
        <VDivider />
        <div v-for="(detail, index) in detailSummary" :key="index">
          <div class="d-flex mt-10 justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.Title }}</p>
            <p>{{ detail.title }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.Type }}</p>
            <p>{{ detail.type }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.DateAndTime }}</p>
            <p>{{ detail.dateAndTime }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.ArticleProduct }}</p>
            <p>{{ detail.articleProduct }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.Status }}</p>
            <p>{{ detail.status }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.MailingList }}</p>
            <p>{{ detail.mailingList }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.GeograpichalRegion }}</p>
            <p>{{ detail.geograpichalRegion }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.NetworkProvider }}</p>
            <p>{{ detail.networkProvider }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.FirmwareVersion }}</p>
            <p>{{ detail.firmwareVersion }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="opacity">{{ strapi.DetailSummary.OperationSystem }}</p>
            <p>{{ detail.operationSystem }}</p>
          </div>
        </div>
      </div>
      <div class="w-620px h-570px bg-white py-10 px-10 mt-10 mb-10 rounded-lg">
        <div class="d-flex">
          <VIcon :icon="strapi.DescriptionIcon" class="mr-2" />
          <p class="paragraph-large">{{ strapi.DescriptionTitle }}</p>
        </div>
        <VDivider />
        <p class="mt-10">{{ descriptionText }}</p>
      </div>
    </div>

    <div class="bg-grey-background container">
      <div class="py-10 px-10 bg-white rounded-lg mb-10">
        <div class="d-flex">
          <VIcon :icon="strapi.FilesIcon" class="mr-3" />
          <p class="paragraph-large">{{ strapi.FilesTitle }}</p>
        </div>
        <VDivider />
        <div class="file-upload" :class="{ 'has-files': files.length > 0 }">
          <div class="dropzone" @dragover.prevent @drop="handleDrop">
            <p v-if="files.length === 0">Drag and drop files here</p>
            <div v-for="(file, index) in files" v-else :key="index" class="image-preview">
              <img :src="file.preview" />
            </div>
          </div>
          <input ref="fileInput" type="file" style="display: none" @change="handleFileInput" />
        </div>
      </div>
      <div class="d-flex bg-white justify-space-between py-10 px-10 rounded-lg mb-10">
        <div class="d-flex">
          <VIcon :icon="strapi.AddCommentIcon" class="mr-3" />
          <p class="paragraph-large">{{ strapi.AddCommentTitle }}</p>
        </div>
        <VIcon icon="fa-solid fa-plus" />
      </div>
      <div class="py-10 px-10 bg-white rounded-lg">
        <div class="d-flex">
          <VIcon :icon="strapi.CommentsHistoryIcon" class="mr-3" />
          <p class="paragraph-large">{{ strapi.CommentsHistoryTitle }}</p>
        </div>
        <VDivider />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCreateRequest: false,
      loading: false,
      ...this.$controller.data,
      files: [],
      rmaNo: 'R43298',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed eros facilisis, consectetur tortor eu, condimentum ipsum. Nunc a ipsum et justo commodo interdum vel a lectus. ',
      detailSummary: [
        {
          title: 'Lorem ipsum dolor sit amet par lopseia lano?',
          type: 'Technical Support',
          dateAndTime: '22 Maj 2023 15:53:26',
          articleProduct: 'John Smith',
          status: 'open',
          mailingList: 'John Smith',
          geograpichalRegion: 'Gothenburg, Sweden',
          networkProvider: 'Tele2',
          firmwareVersion: '34513451345',
          operationSystem: 'Mac OS'
        }
      ],
      descriptionText: `Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam quis risus eget urna mollis ornare vel eu leo.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean lacinia bibendum nulla sed consectetur. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper. 
                        Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.`
    };
  },
  methods: {
    handleFileInput() {
      const files = this.$refs.fileInput.files;
      this.uploadFiles(files);
    },
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.uploadFiles(files);
    },
    uploadFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = event => {
          this.files.push({
            name: file.name,
            size: file.size,
            type: file.type,
            preview: event.target.result
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  transition: height 0.2s ease-in-out;
  height: 200px;
}
.has-files .dropzone {
  height: auto;
}
.image-preview {
  margin: 10px;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.image-preview img {
  width: 262px;
  height: 306px;
}
</style>
