<template>
  <SelectApi endpoint="purchased-articles" :itemTitle="title">
    <template #item="{ item }">
      <VListItem v-bind="item.props" :title="title(item.item.raw)" :subtitle="variant(item.item.raw)" />
    </template>
    <template #selection="{ item }">
      {{ titleWithVariant(item.item.raw) }}
    </template>
  </SelectApi>
</template>

<script>
export default {
  name: 'SelectPurchasedArticle',
  methods: {
    title(opt) {
      return `${opt.name} (${opt.part_number})`;
    },
    titleWithVariant(opt) {
      return opt.variant ? `${opt.name} (${opt.part_number}) - ${opt.variant}` : `${opt.name} (${opt.part_number})`;
    },
    variant(opt) {
      return opt.variant || '';
    }
  }
};
</script>
