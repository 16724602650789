import { internalServerError } from '@/request';
export default {
  methods: {
    setHideNavigation(bool) {
      this.$mitt.emit('hideNavigation', bool);
    },
    setBreadcrumbsBackground(theme) {
      this.$mitt.emit('breadcrumbsBackground', theme);
    },
    handleContentErrors(errors) {
      if (this.$utils.isEmpty(errors)) this.$snackbar.error(internalServerError());
      if (this.$utils.isObject(errors)) {
        let errorMsg = '';
        this.$utils.each(errors, (error, attribute) => {
          errorMsg += `<p class="ma-0 pa-0">${error}</p>`;
          if (this.$utils.isString(attribute)) {
            this.$mitt.emit(`set-error-messages-${attribute}`, error);
          }
        });
        this.$snackbar.error(errorMsg);
      } else this.$snackbar.error(errors);
    },
    hasSlot(slot) {
      return !!this.$slots[slot];
    },
    scrollToTop() {
      this.$gsap.to(window, { duration: 0.8, scrollTo: 0 });
    },
    getURLParameter(paramName) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(paramName);
    },
    updateURLParameter(paramName, paramValue, createNewState = false) {
      const url = new URL(window.location.href);
      url.searchParams.set(paramName, paramValue);

      if (createNewState) {
        window.history.pushState({}, '', url);
      } else {
        window.history.replaceState({}, '', url);
      }
    },
    deleteURLParameter(paramName, createNewState = false) {
      const url = new URL(window.location.href);
      url.searchParams.delete(paramName);

      if (createNewState) {
        window.history.pushState({}, '', url);
      } else {
        window.history.replaceState({}, '', url);
      }
    },
    reloadWithTimeout(timeOut = 1000) {
      setTimeout(() => this.$reload(), timeOut);
    },
    redirectWithTimeout(url = '/', timeOut = 1000) {
      setTimeout(() => this.$redirect(url), timeOut);
    }
  }
};
