<template>
  <div class="hero">
    <div ref="heroImage" class="hero-image" :style="`background-image: url('${src}');`" :aria-label="alt" role="img" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HeroImage',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes zoomEffect {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.hero {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;

  .hero-image::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    animation-name: zoomEffect;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    z-index: -1;
  }
}
</style>
