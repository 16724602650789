<template>
  <div id="downloads-tab">
    <ExpansionPanels v-model="panel">
      <File v-for="(item, index) in items" :key="index" :model-value="item" icon="far fa-file-lines" :title="item.name" />
    </ExpansionPanels>
    <Pagination v-model="paginationPage" :length="paginationPages" :total-visible="paginationTotalVisible" @update:modelValue="paginate" />
  </div>
</template>

<script>
import Pagination from './Pagination.vue';
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import File from '@Components/File.vue';

export default {
  name: 'Downloads',
  components: { Pagination, ExpansionPanels, File },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    records: {
      type: Number,
      required: true
    },
    paginationTotalVisible: {
      type: Number,
      required: true
    },
    itemsOnPage: {
      type: Number,
      required: true
    },
    previousTab: {
      type: String,
      default: undefined
    }
  },
  emits: ['paginate'],
  data() {
    return {
      paginationPage: 1,
      panel: null,
      ...this.$controller.data
    };
  },
  computed: {
    paginationPages() {
      return Math.max(Math.ceil(this.records / this.itemsOnPage), 0);
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.updateURLParameter('page', this.paginationPage);
      }
    }
  },
  created() {
    if (!this.previousTab) {
      this.paginationPage = parseInt(this.getURLParameter('page')) || this.paginationPage;
    } else {
      this.updateURLParameter('page', this.paginationPage);
    }
  },
  methods: {
    paginate() {
      this.panel = null;
      this.$emit('paginate', this.paginationPage);
    }
  }
};
</script>
