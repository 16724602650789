<template>
  <div class="box-links container">
    <div class="grid card-container">
      <div v-for="(boxLink, index) in strapi.Cards" :key="index" class="grid-item -col-span-12 -col-span-md-4">
        <VCard :href="boxLink.Href" class="box-link-card h-150px h-xs-140px h-sm-120px h-md-150px h-lg-200px" :class="{ hover: hover === index }" @mouseenter="hover = index" @mouseleave="hover = null">
          <div class="align-end box-link-image" height="100%">
            <div class="box-link-content text-primary content-wrapper">
              <h3 class="box-link-title d-flex">
                {{ boxLink.Title }}
                <VIcon icon="far fa-arrow-right bold pt-lg-3 pl-2" class="more-details-arrow ml-1" color="primary" size="24" />
              </h3>
              <p class="box-link-description text-black paragraph-large">{{ boxLink.Description }}</p>
            </div>
          </div>
        </VCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxLinks',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hover: null,
      animateUp: null
    };
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  position: relative;
  width: 100%;
  border-radius: 10px;
  box-shadow: none;

  &:hover span {
    color: $color-primary;
  }
}

.content-wrapper {
  padding: 1rem;

  @include is-screen-lg {
    padding: 2.5rem;
  }
}

.box-links {
  margin-top: 50px;
}

.box-link-card {
  position: relative;
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0) !important;
  transition:
    transform 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.box-link-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.4) !important;

  .box-image {
    transform: scale(1.05);
    &::after {
      transform: translateY(-100px);
    }
  }
}

.box-link-image {
  position: relative;
  display: block;
  height: 100%;
  transform: translateY(0);
  transition:
    box-shadow 0.25s ease-in-out,
    transform 0.25s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform 0.8s ease-in-out;
  }
}
.box-link-image.animate-up {
  transform: translateY(-5px);
  .productCatImg {
    &:after {
      transform: scale(1.2);
    }
  }
}

.box-link-title {
  margin-top: 0;
}

.box-link-description {
  margin-bottom: 20px;
}
</style>
