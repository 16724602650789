export default class GlobalMixins {
  static init(Vue) {
    // Require in a mixin context
    const requireMixin = require.context('.', false, /[\w]+\.js$/);
    requireMixin.keys().forEach(fileName => {
      if (fileName == './_globals.js') return;
      // Get mixin
      const mixin = requireMixin(fileName);
      // Register mixin globally
      Vue.mixin(mixin.default || mixin);
    });
  }
}
