<template>
  <div>
    <h5 class="mb-3">{{ strapi.FilterTitle }}</h5>
    <div v-for="link in data" :key="link.id" class="link-wrapper mb-1">
      <a v-if="link.count >= 1" :href="`${link.route}`" class="link">{{ link.name }}</a>
      <span v-if="link.count >= 1" class="ml-auto text-grey">{{ link.count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryLinks',
  props: {
    count: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => []
    },
    strapi: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  width: 100%;
}

.link {
  border-bottom: 1px solid $color-primary-semi-light;
  &:hover {
    border-bottom: 1px solid $color-primary;
  }
}
</style>
