<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12">
        <Checkbox v-if="strapi.NDAAndPrivacyPolicyText" baseline name="customer.nda" @update:modelValue="onUpdateNDAAndPrivacyPolicy">
          <template #label>
            <div v-markdown="strapi.NDAAndPrivacyPolicyText" />
          </template>
        </Checkbox>
      </div>
      <div class="grid-item -col-span-12">
        <Checkbox v-if="strapi.SubscriptionText" v-model="_user.has_newsletter" name="customer.has_newsletter" baseline>
          <template #label>
            <div v-markdown="strapi.SubscriptionText" />
          </template>
        </Checkbox>
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'AgreementAndSecurity',
  props: {
    customer: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _customer: {
      get() {
        return this.customer;
      },
      set(customer) {
        this.$emit('update:customer', customer);
      }
    },
    _user: {
      get() {
        return this.user;
      },
      set(user) {
        this.$emit('update:user', user);
      }
    }
  },
  methods: {
    onUpdateNDAAndPrivacyPolicy(val) {
      this._customer = { ...this.customer, nda: val, accepted_privacy_at: val ? this.$now() : null };
    }
  }
};
</script>

<style lang="scss" scoped></style>
