<template>
  <div :class="{ 'v-input--error': hasErrorMessages }">
    <VueTelInput :inputOptions="{ id: id, name: name, placeholder: placeholder }" :model-value="modelValue" :defaultCountry="defaultCountry" :mode="mode" :class="{ 'v-field--error': hasErrorMessages }" @update:modelValue="$emit('update:modelValue', $event)" />
    <div v-if="hasErrorMessages" class="v-input__details">
      <div class="v-messages">
        <div v-for="error in localErrorMessages" :key="error" class="v-messages__message">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'TelInput',
  components: { VueTelInput },
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    modelValue: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'international'
    },
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    defaultCountry: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  background-color: $color-grey-background !important;
  border: none !important;
  border-radius: 8px !important;
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
}

:deep(.vti__dropdown) {
  border-radius: 8px !important;
}

.v-field--error {
  outline: 1px solid $color-validation-red;
}
</style>
