<template>
  <HeroImage v-if="strapi.Image" :src="strapi.Image.url" :alt="strapi.Image.alternativeText">
    <div class="hero-content d-flex flex-column justify-center h-100">
      <div class="container">
        <div class="grid">
          <div class="grid-item -col-span-12 -col-span-sm-10">
            <h1 v-if="strapi.Heading" class="h1 bold text-white" v-html="strapi.Heading" />
            <Button v-if="strapi.Button" :href="strapi.Button.Href" icon="fa-regular fa-chevron-right" variant="outlined" color="white">
              {{ strapi.Button.Label }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </HeroImage>
</template>

<script>
import HeroImage from '@Components/HeroImage.vue';

export default {
  name: 'HomeHero',
  components: { HeroImage },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-content {
  position: relative;
  z-index: 2;
  @include fade-in;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 38.07%), linear-gradient(90deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0) 84.95%);
}
</style>
