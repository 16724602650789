<template>
  <Card class="bg-secondary w-100">
    <VList class="bg-secondary" :class="{ ' d-flex flex-column justify-center': isMobile }" :aria-label="`List of links to subcategories of category ${title}`">
      <VListItem class="pl-0">
        <VListItemTitle class="title paragrapy-large">{{ title }}</VListItemTitle>
      </VListItem>
      <VDivider class="border-opacity-50 mb-3 mt-3" aria-hidden="true" />
      <div class="list-container">
        <VListItem v-for="(item, index) in filteredItems" :key="index" class="list-item pa-0" :class="{ 'two-column-layout': isLarge, 'single-column-layout': !isLarge }">
          <a :href="item.route.show" role="option" :aria-label="`Go to ${item.title} page`">
            <div class="d-flex list-item-container pr-0 align-center">
              <VIcon class="text-primary slide-icon pr-2 pt-1" icon="fa-solid fa-chevron-right" size="x-small" />
              <VListItemTitle class="slide-text w-180px">{{ item.title }}</VListItemTitle>
            </div>
          </a>
        </VListItem>
      </div>
    </VList>
  </Card>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    isLarge: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      navigation: this.$dom.application.navigation
    };
  },
  computed: {
    filteredItems() {
      return this.items?.filter(item => item.attach_to_footer) || [];
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  opacity: 50%;
  font-size: 1.25rem;
}

.list-container {
  display: flex;
  flex-wrap: wrap;

  .two-column-layout {
    flex: 0 0 50%; /* Each item takes up 50% of the container width */
    box-sizing: border-box;
  }

  // .single-column-layout {
  // flex: 0 0 100%; /* Each item takes up the full width of the container */
  // box-sizing: border-box;
  // }
}

.list-item-container {
  position: relative;

  &:hover {
    .slide-text,
    .slide-icon {
      transform: translateX(15px);
    }
  }
  .slide-text {
    position: relative !important;
  }

  .slide-icon,
  .slide-text {
    transition: all 0.3s;
    transform: translateX(0);
    white-space: pre-line;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .slide-icon {
    left: -15px;
    top: 3px;
    position: absolute !important;
  }
}
</style>
