<template>
  <div aria-live="polite" aria-atomic="true" class="alert-container">
    <TransitionGroup name="alert" tag="div" appear>
      <div v-for="(alert, index) in alerts" :key="alert.id" class="alert show text-light" :class="[{ 'mt-2': index > 0 }]" role="alert" aria-live="assertive" aria-atomic="true" @mouseover="pauseProgress(alert)" @mouseout="resumeProgress(alert)">
        <VAlert :model-value="visible" :type="alert.type" :closable="alert.closeable" @update:modelValue="onClose(alert)">
          <span v-html="alert.text" />
          <VProgressLinear v-if="alert.timeOut > 0 && alert.showProgress" :model-value="getCurrentProgress(alert)" class="position-absolute start-0 bottom-0 w-100" height="10" />
        </VAlert>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    showProgress: {
      type: Boolean,
      default: true
    },
    maxAlerts: {
      type: Number,
      default: 5
    },
    timeOut: {
      type: Number,
      default: 2000
    },
    closeable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      alerts: [],
      data: {},
      count: 0,
      visible: true
    };
  },
  created() {
    this.$mitt.on('clearOtherAlerts', () => {
      this.$utils.each(this.alerts, alert => {
        if (alert?.type != 'success') this.remove(alert);
      });
    });
    this.$mitt.on('alert', this.handleMessageEvent);
  },
  methods: {
    isDuplicate(payloadMessage) {
      return this.$utils.some(this.alerts, alert => alert.text == payloadMessage.text && alert.title == payloadMessage.title);
    },

    handleMessageEvent(payload) {
      if (payload.message) {
        if (this.isDuplicate(payload.message)) return;
        this.addAlert(payload.message, payload.type, payload.options);
      }
    },
    addAlert(message, type, options = {}) {
      this.data = {
        id: this.count++,
        title: message.title,
        text: message.text,
        type: type,
        showProgress: this.showProgress,
        timeOut: this.timeOut,
        closeable: this.closeable,
        progress: 0,
        paused: false,
        animationFrame: null
      };
      this.data = { ...this.data, ...options };
      this.alerts.unshift(this.data);
      if (this.alerts.length > this.maxAlerts) {
        this.alerts.splice(this.maxAlerts);
      }
      if (this.data.timeOut > 0) {
        this.startTimeout(this.data);
      }
    },

    startTimeout(data, startFrom = 0) {
      const self = this;
      const startTime = performance.now();
      const start = () => {
        data.animationFrame = requestAnimationFrame(timestamp => {
          const timeElapsed = timestamp + startFrom - startTime;
          if (!self.exists(data) || data.paused) {
            cancelAnimationFrame(data.animationFrame);
            return;
          } else if (timeElapsed < data.timeOut) {
            const progress = timeElapsed / data.timeOut;
            data.progress = progress;
            start();
          } else {
            data.progress = 1;
            cancelAnimationFrame(data.animationFrame);
            self.remove(data);
            return;
          }
        });
      };
      start();
    },

    pauseProgress(data) {
      if (data.timeOut <= 0) return;
      data.paused = true;
    },

    resumeProgress(data) {
      if (data.timeOut <= 0) return;
      data.paused = false;
      this.startTimeout(data, data.timeOut * data.progress);
    },
    getCurrentProgress(data) {
      if (Math.round(data.progress * 100) >= 100) {
        return 100;
      }
      return data.progress * 100;
    },
    onClose(alert) {
      this.remove(alert);
    },
    remove(data) {
      const index = this.alerts.findIndex(alert => alert.id === data?.id);
      this.alerts.splice(index, 1);
    },

    exists(data) {
      const index = this.alerts.findIndex(alert => alert.id === data?.id);
      if (index === -1) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.v-alert__close {
  margin-inline-start: 10px !important;
}
.v-progress-linear {
  transition: 0.1s cubic-bezier(0.2, 0, 0.1, 1) !important;
}
</style>

<style lang="scss" scoped>
.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 350px;
  z-index: 15;
}

.alert-enter {
  opacity: 0.5 !important;
}
.alert-enter-active {
  transition: opacity 0.5s ease-in;
}
.alert-enter-to {
  opacity: 1;
}
.alert-leave {
  opacity: 0.5;
}
.alert-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.alert-leave-to {
  opacity: 0;
}
.progress {
  border-radius: 0;
}
</style>
