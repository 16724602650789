<template>
  <Card class="pa-5 pa-md-10">
    <div class="d-flex flex-column flex-md-row justify-space-between">
      <div class="d-flex">
        <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
        <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
      </div>
      <Button class="paragraph rounded-lg py-2 d-flex justify-center bg-secondary px-5 px-md-10 min-w-200px min-w-md-300px" color="white" :href="strapi.CreateTicketButtonHref">
        <VIcon :icon="strapi.CreateTicketButtonIcon" size="20px" class="mr-1" />
        {{ strapi.CreateTicketButtonLabel }}
      </Button>
    </div>
    <VCardText class="px-0 pb-0 position-relative">
      <VDataTableServer hover v-bind="options" :loading="loading" @update:options="loadTickets" @click:row="onRowClick">
        <template #item.id="{ item }">
          <span class="bold">{{ `${item.id} - ${item.title}` }}</span>
        </template>
        <template #tfoot>
          <tr>
            <td>
              <TextField v-model="query" type="search" placeholder="Search" class="position-md-absolute w-auto min-w-md-275px min-w-lg-350px bottom-5px" />
            </td>
          </tr>
        </template>
      </VDataTableServer>
    </VCardText>
  </Card>
</template>

<script>
import { debounce } from '@Utils/default';

export default {
  name: 'SupportRequests',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      query: '',
      options: {
        search: '',
        itemsLength: 0,
        headers: [
          {
            title: this.strapi.ListTitleLabel,
            sortable: true,
            key: 'id'
          },
          {
            title: this.strapi.TypeLabel,
            sortable: true,
            key: 'combinedTypeName'
          },
          {
            title: this.strapi.DateLabel,
            sortable: true,
            key: 'updated_at',
            value: item => this.$formatDateAsDayMonthYear(item.updated_at, this.isMobile ? '' : 'full')
          },
          {
            title: this.strapi.StatusLabel,
            sortable: true,
            key: 'status'
          }
        ]
      }
    };
  },
  watch: {
    query: debounce(function (query) {
      this.options.search = query;
    }, 500)
  },
  methods: {
    async loadTickets(payload) {
      this.loading = true;
      const response = await this.get('/account/support/list/', payload);
      this.loading = false;
      if (!response) return;
      this.options = { ...this.options, ...payload, ...response };
    },
    onRowClick(event, data) {
      this.$redirect(data.item.route.publicShow);
    }
  }
};
</script>

<style lang="scss" scoped></style>
