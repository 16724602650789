<template>
  <div ref="tabs" class="tabs-container align-center justify-space-between">
    <!-- <a v-if="downloadBtn" class="download-btn -mobile font-weight-bold align-center pb-20" :href="downloadBtn.url">
      <span>{{ downloadBtn.label }}</span>
      <i class="icon fa-light fa-file-arrow-down text-secondary ml-3" />
    </a> -->
    <VTabs v-model="tab" class="mb-4 sticky-container">
      <div class="container d-flex tabs">
        <VTab v-for="_tab in tabs" :key="_tab.key" :value="_tab.key" sliderColor="primary" variant="plain" class="px-0" hideSlider>
          <p class="paragraph -no-margin">{{ _tab.label }}</p>
        </VTab>
        <a v-if="article.datasheet" class="download-btn -desktop font-weight-bold align-center" :href="article.datasheet.route.download">
          <span>Download Datasheet</span>
          <i class="icon fa-light fa-file-arrow-down text-secondary ml-3" />
        </a>
      </div>
    </VTabs>
    <VCardText class="py-10 py-md-20 py-lg-30 bg-grey-background">
      <div class="container">
        <VWindow v-model="tab">
          <VWindowItem v-for="_tab in tabs" :key="_tab.key" :value="_tab.key" eager>
            <VProgressCircular v-if="loading" :size="75" width="5" color="secondary" bg-color="white" indeterminate class="d-block ma-auto mb-16 mt-12" />
            <Details v-if="_tab.key == 'details'" v-model:loading="loading" :active="_tab.key === tab" :model-value="_tab" />
            <Faqs v-else-if="_tab.key == 'faq'" v-model:loading="loading" :active="_tab.key === tab" :model-value="_tab" />
            <Files v-else v-model:loading="loading" :model-value="_tab" :active="_tab.key === tab" :previousTab="previousTab" />
          </VWindowItem>
        </VWindow>
      </div>
    </VCardText>
  </div>
</template>
<script>
import Details from './Details.vue';
import Files from './Files.vue';
import Faqs from './Faqs.vue';
import { gsap } from 'gsap';

export default {
  name: 'Tabs',
  components: { Details, Files, Faqs }, // eslint-disable-line
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 'details',
      loading: false,
      previousTab: undefined
    };
  },
  computed: {
    tabs() {
      const baseTabs = [
        { label: 'Details', key: 'details', description: this.article.description, attributes: this.article.attributes },
        { label: 'Technical Documentation', key: 'technical-documentation', files: this.getFilesByType('Technical Documentation'), icon: 'far fa-file-lines' },
        { label: 'Certifications', key: 'certifications', files: this.getFilesByType('Certification'), icon: 'far fa-file-shield' },
        { label: 'Software', key: 'software', files: this.getFilesByType('Software'), icon: 'far fa-file-code' },
        { label: 'Firmware', key: 'firmware', files: this.getFilesByType('Firmware'), icon: 'far fa-file-code' },
        { label: 'PCN', key: 'pcn', files: this.getFilesByType('PCN'), icon: 'far fa-file-lines' },
        { label: 'FAQ', key: 'faq', faqs: this.article.faqs, icon: 'far fa-message-question' }
      ];

      // Filter out tabs that have no files or faqs
      return this.$utils.filter(baseTabs, tab => (!tab.files || tab.files.length > 0) && (!tab.faqs || tab.faqs.length > 0));
    }
  },
  watch: {
    tab: {
      handler(newVal, oldVal) {
        if (this.$utils.isNotNil(oldVal)) {
          if (this.$refs.tabs) {
            gsap.to(window, { duration: 0.8, scrollTo: this.$refs.tabs.offsetTop });
          }
        }
        this.previousTab = oldVal;
        this.updateURLParameter('tab', newVal);
      }
    }
  },
  created() {
    this.tab = this.getURLParameter('tab') || 'details';
  },
  methods: {
    getFilesByType(type) {
      return this.$utils.filter(this.article.files, file => file.typeName === type);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-tabs) {
  .v-btn {
    font-size: initial;
    position: relative;
    transition: opacity 0.3s;

    &:not(:first-of-type) {
      margin-left: 40px !important;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transform: translateX(-50%);
      transition: width 0.3s;
    }

    &:hover::before,
    &.v-tab--selected::before {
      width: 100%;
    }
  }

  .v-tab--selected {
    opacity: 1;
  }
}
.wrapper {
  background-color: $color-grey-light;
  border-radius: 8px;
  justify-content: space-between;
}
.col {
  display: block;
  > span {
    display: block;
  }
}

.download-btn {
  margin-left: 20px;
  height: 48px;
  &.-desktop {
    display: none;
  }

  &.-mobile {
    justify-content: center;
    display: flex;
  }
  @include is-screen-lg() {
    margin-left: auto;
    &.-desktop {
      display: flex;
    }

    &.-mobile {
      display: none;
    }
  }

  span {
    text-wrap: nowrap;
  }
}

.sticky-container {
  position: sticky;
  top: 0;
  background-color: $color-white;
  opacity: 0.9;
  z-index: 3;
  height: 48px;
  border-top: 1px solid $color-grey-light;

  @include is-screen-lg() {
    border: 0;
  }

  &::before,
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    background: red;
    width: 30px;
    height: 100%;
    top: 0;
  }

  &::after {
    right: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
  &::before {
    left: 0;
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}

:deep(.v-slide-group__content) {
  width: 100%;
  height: 100px;
  overflow-x: scroll;

  @include is-screen-lg() {
    height: auto;
    width: auto;

    overflow: hidden;
  }
}
</style>
