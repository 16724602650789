<template>
  <Card data-menu-bg="true" adjustToMenu>
    <template #title>
      <div class="container d-flex pt-20 pb-26">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon class="w-55px h-55px" icon="fas fa-file-lines" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ file.name }}</h1>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-20">
      <div class="container" :class="{ 'pl-0 pr-0': isMobile }">
        <div class="grid">
          <div class="grid-item -col-span-12">
            <ExpansionPanels v-model="openOnLoad">
              <File v-model="file" icon="far fa-file-lines" :title="file.name" value="focused" eager />
            </ExpansionPanels>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import File from '@Components/File.vue';

export default {
  name: 'FileShow',
  components: { ExpansionPanels, File },
  data() {
    return {
      loading: false,
      openOnLoad: 'focused',
      ...this.$controller.data
    };
  },
  mounted() {
    if (this.$isNotBlank(this.downloadAfterLogin?.url)) {
      this.$redirect(this.downloadAfterLogin.url);
    }
  }
};
</script>

<style lang="scss" scoped></style>
