<template>
  <PageBuilder v-if="strapi.Modules" :modules="strapi.Modules" />
</template>

<script>
export default {
  name: 'PublicNewsIndex',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tab: 'All News',
      loading: false,
      ...this.$controller.data,
      previousTab: undefined,
      items: [],
      newsType: [],
      paginationTotalVisible: 6
    };
  },
  computed: {
    types() {
      const typeData = new Set(['All News']);
      this.newsType.forEach(tab => {
        typeData.add(tab.Type);
      });
      return [...typeData].map(type => {
        return {
          Type: type
        };
      });
    }
  },
  watch: {
    tab(newVal, oldVal) {
      // this.scrollToTop();
      this.previousTab = oldVal;
      this.updateURLParameter('type', newVal);
    }
  },
  async created() {
    const types = this.getURLParameter('type');
    if (types) {
      this.tab = types;
    }
  },
  methods: {
    onUpdateItems(items) {
      console.log(items);
      this.items = items;
    },
    typeValues(val) {
      if (this.tab === 'All News') {
        this.items = val;
        console.log(this.items);
      } else {
        this.items = val.filter(item => item.Type === this.tab);
        console.log(this.items);
      }
    },
    async paginate(page) {
      this.scrollToTop();
      this.loading = true;
      const response = await this.post('/news/', {
        types: this.tab,
        page: page
      });
      if (!response) return;
      setTimeout(() => {
        this.results[this.tab].items = response.items;
        this.loading = false;
      }, 250);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-tabs) {
  .v-btn {
    font-size: initial;
    position: relative;
    transition: opacity 0.3s;

    &:not(:first-of-type) {
      margin-left: 40px !important;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transform: translateX(-50%);
      transition: width 0.3s;
    }

    &:hover::before,
    &.v-tab--selected::before {
      width: 100%;
    }
  }

  .v-tab--selected {
    opacity: 1;
  }
}

.news-tabs-container {
  position: sticky;
  top: 0;
  background-color: $color-white;
  opacity: 0.9;
  z-index: 3;
}

:deep(.news-content) {
  & > div {
    width: 720px;

    @include is-screen-lg() {
      width: 1280px;
    }

    @include is-screen-xl() {
      width: 1440px;
    }
  }
  .v-pagination {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.news-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-card-text {
  background-color: $color-grey-light;
}

.news-card {
  z-index: unset !important;
  overflow: unset !important;
}
</style>
