<template>
  <div>
    <VList v-if="topMenu.length > 0" class="bg-secondary top-menu h-auto d-flex pt-8 pb-8 pl-5 pr-5">
      <span class="arrow d-block w-20px h-0" :style="{ right: `${arrowRightPosition}px` }" />
      <VListItem class="h-auto">
        <div v-for="item in topMenu.slice(0, Math.ceil(topMenu.length / 2))" :key="item.id" class="d-flex">
          <a :href="item.route.show" :aria-label="`${item.title} page`" class="d-flex flex-row align-center content-container pa-4">
            <VIcon :icon="item.icon" class="text-primary mr-3 pl-1" size="20px" />
            <VListItemTitle class="category-title w-180px">{{ item.title }}</VListItemTitle>
          </a>
        </div>
      </VListItem>
      <VListItem class="w-50" height="100%">
        <div v-for="item in topMenu.slice(Math.ceil(topMenu.length / 2))" :key="item.id" class="d-flex">
          <a :href="item.route.show" :aria-label="`${item.title} page`" class="d-flex flex-row align-center content-container pa-4">
            <VIcon :icon="item.icon" class="text-primary mr-3 pl-1" size="20px" />
            <VListItemTitle class="category-title w-180px">{{ item.title }}</VListItemTitle>
          </a>
        </div>
      </VListItem>
    </VList>
    <VList v-if="bottomMenu.length > 0" class="bg-secondary-dark bottom-menu pa-7 h-auto">
      <VListItem>
        <div class="d-flex flex-column">
          <a v-for="item in bottomMenu" :key="item" :href="item.route.show" :aria-label="`${item.title} page`" class="d-flex justify-start align-center bottom pa-3">
            <div class="d-flex flex-row">
              <VIcon :icon="item.icon" class="text-primary mr-3" size="20px" />
              <VListItemTitle>{{ item.title }}</VListItemTitle>
            </div>
            <p class="mb-0 ml-3">{{ item.description }}</p>
          </a>
          <div class="d-flex align-center" />
        </div>
      </VListItem>
    </VList>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      navigation: this.$dom.application.navigation
    };
  },
  computed: {
    bottomMenu() {
      return this.items?.filter(item => item.attach_to_bottom);
    },
    topMenu() {
      return this.items?.filter(item => !item.attach_to_bottom);
    },
    arrowRightPosition() {
      const activeButton = document.querySelectorAll('.menu-button')[this.index];
      const menubar = document.querySelector('.menu-bar');
      if (!menubar || !activeButton) return 0;
      const xDiff = menubar.getBoundingClientRect().right - activeButton.getBoundingClientRect().right;
      const buttonCenter = activeButton.getBoundingClientRect().width / 2;
      return Math.floor(xDiff + buttonCenter + 70); // compensate for v-menu translateX 70px
    }
  }
};
</script>

<style lang="scss" scoped>
.arrow {
  position: absolute;
  transform: translate(0, -100%);
  right: 0;
  top: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid $color-secondary;
}

.top-menu {
  overflow: visible;
  position: relative;
  border-radius: 8px 8px 0 0;
}
.bottom-menu {
  border-radius: 0 0 8px 8px;
}
.v-list {
  .content-container {
    border-radius: 8px;
    &:not(.hover-dark) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba($color-secondary-dark, 0.8);
        border-radius: 8px;
        z-index: 0;
        transform: scale(0);
        transition: transform $transition-duration $transition-timing;
      }

      i,
      .v-list-item-title {
        z-index: 1;
        transition: color $transition-duration $transition-timing;
      }

      @media (hover: hover) {
        &:not(:disabled):hover:after {
          transform: scale(1);
        }
      }
    }
  }
  .bottom {
    border-radius: 8px;
    &:not(.hover-dark) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba($color-secondary, 0.8);
        border-radius: 8px;
        z-index: 0;
        transform: scale(0);
        transition: transform $transition-duration $transition-timing;
      }

      i,
      p,
      .v-list-item-title {
        z-index: 1;
        transition: color $transition-duration $transition-timing;
      }

      @media (hover: hover) {
        &:not(:disabled):hover:after {
          transform: scale(1);
        }
      }
    }
    p {
      opacity: 50%;
    }
  }
  .category-title {
    white-space: pre-line;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .v-list-item__title {
    @include is-screen-xxl() {
      font-size: 2rem;
    }
  }
}
</style>
