<template>
  <div class="richText container">
    <div class="w-100 d-flex flex-column">
      <h2 v-if="strapi.TitleText" class="h2 text-secondary mw-940px mb-20 mt-10">{{ strapi.TitleText }}</h2>
      <div class="d-flex mb-20" :class="{ 'flex-column': isMobile }">
        <div class="h-600px rounded-lg">
          <VImg :src="strapi.Image?.url" height="600" width="510" cover class="mr-30 rounded-lg" :aspect-ratio="imageRatio" />
        </div>
        <div v-markdown="strapi.Text" class="mt-10 mt-md-4 mt-lg-11" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: false,
      expandLabels: {
        open: 'View more',
        close: 'View less'
      }
    };
  },
  computed: {
    imageRatio() {
      return this.strapi.ImageOutsideContainer ? 7 / 9 : 1 / 1;
    }
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.expandable {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
  }

  &.-expanded:after {
    content: none;
  }
}

:deep(h1),
:deep(h2) {
  color: $color-secondary;

  span {
    color: $color-primary;
  }
}

:deep(img) {
  width: 100%;
  border-radius: 8px;
  margin: rem(16) 0;

  @include is-screen-lg() {
    margin: rem(48) 0;
  }
}

:deep(p) {
  @include paragraph('large');
}

:deep(ul),
:deep(ol) {
  @include paragraph('large');
  margin: 0 0 rem(32);
}

:deep(code) {
  display: block;
  background: $color-white;
  border-radius: 8px;
  margin-bottom: rem(32);
  padding: rem(40) rem(48);
}

:deep(blockquote) {
  p {
    @include blockquote;

    margin: 0 0 rem(32);
  }
}
</style>
