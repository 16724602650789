<template>
  <Card adjustToMenu data-menu-bg="true">
    <template #title>
      <div class="container d-flex flex-column pt-20 pb-26 bg-white">
        <div class="d-flex">
          <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
            <VIcon :icon="strapi.RmaIcon" size="x-large" />
          </div>
          <h1 class="text-secondary -no-margin">
            {{ strapi.Title }}
            <span class="text-grey">{{ rma.number }}</span>
          </h1>
        </div>
      </div>
    </template>

    <div class="wrapper bg-grey-background py-20">
      <div class="container grid">
        <!-- Details Section -->
        <Card class="grid-item" :class="[{ 'pa-5 -col-span-12': isMobile }, { 'pa-10 -col-span-6': isNotMobile }]">
          <header class="d-flex">
            <VIcon :icon="strapi.RmaSummary.SummaryIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ strapi.RMASummaryLabel }}</h6>
          </header>

          <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />

          <Form :disabled="loading" grid :isEditing="false" columns="1fr 1fr">
            <label>{{ strapi.RmaSummary.OrderLabel }}</label>
            <span>{{ rma.order_number ?? 'Unknown' }}</span>

            <label>{{ strapi.RmaSummary.ArticleLabel }}</label>
            <span>{{ rma.article?.name ?? 'Unknown' }}</span>

            <label>{{ strapi.RmaSummary.QuantityLabel }}</label>
            <span>{{ rma.article_quantity ?? 'Unknown' }}</span>

            <label>{{ strapi.RmaSummary.CreatedAtLabel }}</label>
            <span>{{ $utils.formatDateAsDayMonthYear(rma.created_at, 'full') }}</span>

            <label>{{ strapi.RmaSummary.LastUpdatedAtLabel }}</label>
            <span>{{ $utils.formatDateAsDayMonthYear(rma.updated_at, 'full') }}</span>

            <!-- TODO: Add when the trait is fixed -->
            <!-- <label>{{ strapi.RmaSummary.LastUpdatedByLabel }}</label>
            <span>{{ rma.updatedBy }}</span> -->

            <label>{{ strapi.RmaSummary.CustomerStatusLabel }}</label>
            <span>{{ statusName(rma.customer_status) }}</span>

            <label>{{ strapi.RmaSummary.TechshipContactLabel }}</label>
            <span>{{ rma.contact_person }}</span>

            <label>{{ strapi.RmaSummary.RelatedTicketLabel }}</label>
            <p v-if="rma.ticket">
              <a :href="rma.ticket.route.show">#{{ rma.ticket.id }}</a>
              — {{ rma.ticket.title }}
            </p>
            <p v-else>None</p>
          </Form>
        </Card>

        <!-- Description Section -->
        <Card class="grid-item -col-span-6" :class="[{ 'pa-5 -col-span-12': isMobile }, { 'pa-10 -col-span-6': isNotMobile }]">
          <header class="d-flex">
            <VIcon :icon="strapi.DescriptionIcon ?? 'fa-light fa-file'" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ strapi.ProblemDescriptionLabel }}</h6>
          </header>
          <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
          <div v-markdown="rma.description" />
        </Card>

        <!-- Files Section -->
        <!-- <Files v-if="$utils.isNotEmpty(commentFiles)" v-model="commentFiles" :strapi="strapi" disableUpload disableRemove /> -->

        <!-- Add Comment Section -->
        <AddComment :strapi="strapi.Comment" />

        <!-- Comments History -->
        <Comments v-model="rma.comments" :strapi="strapi.Comment" />
      </div>
    </div>
  </Card>
</template>

<script>
import AddComment from './components/AddComment.vue';
import Files from './components/Files.vue';
import Comments from './components/Comments.vue';

export default {
  name: 'RmaRequestShow',
  components: { AddComment, Files, Comments },
  data() {
    return {
      showCreateRequest: false,
      loading: false,
      ...this.$controller.data,
      files: []
    };
  },
  computed: {
    commentFiles() {
      // return [];
      const files = this.rma.comments.reduce((files, comment) => {
        return files.concat(comment.s3Files ?? []);
      }, []);
      console.log('commentFiles:', files);
      return files;
    }
  },
  methods: {
    handleFileInput() {
      const files = this.$refs.fileInput.files;
      this.uploadFiles(files);
    },
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.uploadFiles(files);
    },
    uploadFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = event => {
          this.files.push({
            name: file.name,
            size: file.size,
            type: file.type,
            preview: event.target.result
          });
        };
        reader.readAsDataURL(file);
      }
    },
    statusName(status) {
      const statusNames = {
        1: this.strapi.CustomerStatus.New,
        2: this.strapi.CustomerStatus.InProgress,
        3: this.strapi.CustomerStatus.Closed
      };

      // console.log(status, ':', statusNames[status]);
      return statusNames[status];
    }
  }
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0.5;
}
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  transition: height 0.2s ease-in-out;
  height: 200px;
}
.has-files .dropzone {
  height: auto;
}
.image-preview {
  margin: 10px;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.image-preview img {
  width: 262px;
  height: 306px;
}
</style>
