<template>
  <Select :modelValue="convertValue(modelValue)" :items="items" :label="label" :single-line="$utils.isBlank(label)" @update:modelValue="$emit('update:modelValue', convertValue($event))" />
</template>

<script>
export default {
  name: 'SelectBoolean',
  props: {
    modelValue: {
      default: null
    },
    trueText: {
      type: String,
      default: 'Yes'
    },
    falseText: {
      type: String,
      default: 'No'
    },
    label: {
      type: String,
      default: null
    },
    forceBool: {
      type: Boolean,
      default: false
    },
    valueType: {
      type: String,
      default: 'boolean',
      validator(val) {
        return ['boolean', 'int'].includes(val);
      }
    }
  },
  data() {
    return {
      items: [
        { id: this.valueType == 'boolean' ? true : 1, name: this.trueText },
        { id: this.valueType == 'boolean' ? false : 0, name: this.falseText }
      ]
    };
  },

  methods: {
    convertValue(val) {
      if (!this.forceBool && this.$utils.isNil(val)) {
        // console.log(val);
        return val;
      }
      // console.log(this.valueType == 'int' ? this.valToInt(val) : this.valToBoolean(val));
      return this.valueType == 'int' ? this.valToInt(val) : this.valToBoolean(val);
    },
    valToBoolean(val) {
      return this.$utils.toBoolean(val);
    },
    valToInt(val) {
      const bool = this.valToBoolean(val);
      return bool === true ? 1 : 0;
    }
  }
};
</script>
