<template>
  <!-- TODO: Strapify -->
  <a v-if="priceInfo == 'replacement'" :href="product.replacementArticle.variant?.route.publicShow || product.replacementArticle.route.publicShow">
    <div class="mt-4 mt-md-0">
      <p class="-no-margin text-grey text-center line-clamp-2 line-clamp-md-4">
        Suggested replacement product:
        <br class="d-md-none" />
        <a>{{ product.replacementArticle.name }}</a>
      </p>
    </div>
  </a>
  <!-- TODO: Replace email with strapi data -->
  <p v-else-if="priceInfo === 'contact'" class="-no-margin text-grey text-center">
    Contact
    <a href="mailto:sales@techship.com">sales@techship.com</a>
    for more information
  </p>
</template>
<script>
export default {
  name: 'ReplacmentProductOrContact',
  props: {
    product: {
      type: Object,
      required: true
    },
    priceInfo: {
      type: String,
      required: true
    }
  }
};
</script>
