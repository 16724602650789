// import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { VApp } from 'vuetify/components/VApp';
import { VMain } from 'vuetify/components/VMain';
import { aliases, fa } from 'vuetify/iconsets/fa';

export default {
  install: (Vue, options) => {
    const vuetify = createVuetify({
      components: { VApp, VMain }, // Vuetify tree shaking can't automatically detect VApp since it's used in the .tpl file and not in a .vue file
      defaults: {
        VDialog: {
          scrollStrategy: 'none'
        },
        VIcon: {
          size: 'x-small'
        },
        VBtn: {
          ripple: false
        },
        VRadioGroup: {
          ripple: false
        },
        VRadio: {
          ripple: false
        },
        VTab: {
          ripple: false
        }
      },
      icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
          fa
        }
      },
      theme: {
        defaultTheme: 'techshipTheme',
        themes: {
          techshipTheme: {
            dark: false,
            colors: {
              background: '#7a1331',
              'on-background': '#262626',
              white: '#fff',
              black: '#262626',
              primary: '#f59e00',
              'on-primary': '#fff',
              'primary-light': '#fff5e2',
              'primary-semi-light': '#ffd588',
              secondary: '#7a1331',
              'secondary-dark': '#580e24',
              'secondary-light': '#F2DFE5',
              accent: '#249E6b',
              'validation-green': '#249e6b',
              'validation-red': '#e73c3e',
              'grey-background': '#f5f5f5',
              'grey-light': '#e5e5e5',
              grey: '#bbb',
              'grey-dark': '#808080'
            }
          }
        }
      }
    });
    Vue.use(vuetify);
  }
};
