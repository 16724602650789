<template>
  <VCard :elevation="elevation" :rounded="rounded" :class="{ 'adjust-to-menu': adjustToMenu, 'shadow-hover': shadowHover }">
    <slot v-if="hasSlot('title')" name="title" />
    <slot v-if="hasSlot('subtitle')" name="subtitle" />
    <slot />
    <slot v-if="hasSlot('actions')" name="actions" />
  </VCard>
</template>

<script>
export default {
  name: 'Card',
  props: {
    elevation: {
      type: String,
      default: '0'
    },
    rounded: {
      type: String,
      default: 'lg'
    },
    adjustToMenu: {
      type: Boolean,
      default: false
    },
    shadowHover: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.adjust-to-menu {
  z-index: unset !important;
  overflow: unset !important;
  margin-top: $menu-height-desktop;
  padding-top: calc($menu-height-desktop - 20px);
  @include min-screen-md() {
    padding-top: $menu-height-desktop;
  }
}

:deep(.v-card) {
  .v-expansion-panel-text__wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-expansion-panel {
    padding: 0 !important;
    &::after {
      left: 0 !important;
      right: 0 !important;
    }
    &:first-child {
      &::after {
        top: 0 !important;
      }
    }
    &:last-child {
      &::before {
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }
}

.shadow-hover {
  transition:
    box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 40px 56px rgba(0, 0, 0, 0.08) !important;
    transform: translateY(-5px);
  }
}
</style>
