<template>
  <Card class="d-flex flex-row bg-grey-background h-40px w-100 pl-2 py-1">
    <div class="d-flex flex-basis-50 align-center gap-8">
      <Button iconButton icon="fa-regular fa-minus" aria-label="Decrease quantity" class="hover-dark w-32px h-32px" :disabled="quantity < 1 || loading" iconSize="16" @click="decrease" />
      <TextField :model-value="quantity" class="paragraph bold -no-margin w-95px pa-0" type="number" :clearable="false" hide-spin-buttons @update:modelValue="onManuallySettingQuantity" />
      <Button iconButton icon="fa-regular fa-plus" :disabled="loading" aria-label="Increase quantity" class="hover-dark w-32px h-32px" iconSize="16" @click="increase" />
    </div>
    <div class="d-flex flex-basis-50 align-center justify-center">
      <p class="paragraph -no-margin bold" :class="[priceDirty ? 'text-grey-dark' : '', { 'bold sale-price-text-color': !priceDirty && isOnSale }]">{{ $utils.moneyFormat(price) }}</p>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'ProductPageQuantityInput',
  props: {
    quantity: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    priceDirty: {
      type: Boolean,
      default: false
    },
    isOnSale: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:quantity', 'update:price', 'update:loading'],
  methods: {
    decrease() {
      if (this.quantity > 1) {
        this.$emit('update:quantity', this.quantity - 1);
      }
    },
    increase() {
      this.$emit('update:quantity', this.quantity + 1);
    },
    onManuallySettingQuantity(quantity) {
      if (this.$utils.isBlank(quantity)) {
        return;
      }
      quantity = parseInt(quantity);
      if (quantity < 1) {
        this.$emit('update:quantity', 1);
      } else {
        this.$emit('update:quantity', quantity);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(input) {
  text-align: center;
}

:deep(.v-field) {
  .v-field__overlay {
    background-color: $color-white;
  }
  .v-field__field {
    height: 32px !important;

    .v-field__input {
      padding: 0 !important;
      min-height: 32px !important;
      max-height: 32px !important;
    }
  }
}
.sale-price-text-color {
  color: $color-secondary;
}
</style>
