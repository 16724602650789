<template>
  <div class="d-flex message-text" :class="{ error: disabled, warning: !disabled }">
    <!-- <VIcon :icon="icon()" class="my-auto" /> -->
    <p class="my-auto">
      {{ message }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'CartItemMessage',
  props: {
    endOfLife: {
      type: Boolean,
      required: false,
      default: false
    },
    partialDelivery: {
      type: Number,
      required: false,
      default: null
    },
    inventoryLimited: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    outOfStock: {
      type: Boolean,
      required: false,
      default: false
    },
    backOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      cartStrapi: this.$dom.application.cart
    };
  },
  computed: {
    message() {
      if (this.inventoryLimited > 0) {
        return this.$sprintf(this.cartStrapi.Messages?.InventoryLimited, this.inventoryLimited);
      } else if (this.partialDelivery) {
        return this.$sprintf(this.cartStrapi.Messages?.PartialDelivery, this.partialDelivery);
      } else if (this.backOrder) {
        return this.cartStrapi.Messages?.BackOrder;
      }
      return null;
    }
  },
  methods: {
    icon() {
      if (this.disabled) {
        return 'fa-regular fa-circle-xmark';
      } else {
        return 'fa-regular fa-circle-exclamation';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(p:not(.-no-margin)) {
  margin: 0;
}

.message-text {
  gap: 0.16rem;
  &.warning {
    color: $color-validation-yellow;
  }
  &.error {
    color: $color-validation-red;
  }
}
</style>
