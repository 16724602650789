<template>
  <Button v-if="loginStore.isLoggedIn" iconButton class="cart-button" aria-label="open cart" :icon="`fa-regular fa-basket-shopping ${isBouncing ? 'fa-bounce' : ''}`" color="white" iconSize="20px" @click="toggleCart">
    <span v-if="hasItemsInCart" class="cart-count pa-1 h-15px caption -no-margin d-flex justify-center align-center rounded-circle bg-accent">{{ cartCount }}</span>
  </Button>
</template>

<script>
import { useLoginStore } from '../store/login.js';

export default {
  name: 'CartButton',
  data() {
    return {
      loginStore: useLoginStore(),
      cartCount: 0,
      isBouncing: false
    };
  },
  computed: {
    hasItemsInCart() {
      return this.cartCount > 0;
    }
  },
  created() {
    this.$mitt.on('cart:count', count => {
      this.cartCount = count;
    });
    this.$mitt.on('cart:add', () => {
      this.isBouncing = true;
      setTimeout(() => (this.isBouncing = false), 1500);
    });
  },
  methods: {
    toggleCart() {
      this.$mitt.emit('cart:toggle');
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-count {
  min-width: 16px;
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 2;
  transition: transform $transition-duration $transition-timing;
}

@media (hover: hover) {
  .cart-button:hover .cart-count {
    transform: translate(8px, -8px);
  }
}
</style>
