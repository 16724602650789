<template>
  <Autocomplete ref="tagButton" v-model="selected" return-object hide-details :placeholder="`Select ${data.name}`" flat closable-chips chips multiple :items="data.values" :item-title="item => `${item.value}${addParents(item)}`" clearable :groupId="data.key_name" />
</template>

<script>
export default {
  name: 'FilterAutocomplete',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {},
  watch: {
    selected() {
      this.$emit('onFilterUpdate', { filterKey: this.data.key_name, selected: this.selected });
    }
  },
  created() {
    const searchURL = new URL(window.location);
    const groupParam = searchURL.searchParams.get(this.data.key_name);
    const groupParamArray = groupParam?.split(',');
    groupParamArray?.forEach(value => {
      const groupItem = this.data.values.find(({ id }) => id === parseInt(value));
      if (groupItem) {
        this.selected.push(groupItem);
      }
    });
  },
  methods: {
    addParents(item) {
      return item.parents.length !== 0 ? ` - ${item.parents.join(', ')}` : '';
    },
    clear() {
      this.selected = [];
    },
    setItemTitle(item) {
      return `${item.value} - `;
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-group {
  flex-wrap: wrap;
  gap: 8px;
}
:deep(.v-field__input) {
  padding-left: 0px !important;
}
</style>
