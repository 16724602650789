export default {
  props: {
    name: {
      type: String
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    }
  },
  data() {
    return {
      localErrorMessages: this.errorMessages
    };
  },
  computed: {
    hasErrorMessages() {
      return this.$utils.isNotEmpty(this.localErrorMessages);
    }
  },
  watch: {
    errorMessages: {
      immediate: true,
      handler(newErrorMessages) {
        this.localErrorMessages = newErrorMessages;
      }
    },
    modelValue: {
      immediate: true,
      handler() {
        this.localErrorMessages = [];
      }
    }
  },
  created() {
    if (this.name) {
      this.$mitt.on(`set-error-messages-${this.name}`, errors => {
        this.localErrorMessages = errors;
      });
    }
  }
};
