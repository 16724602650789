<template>
  <div class="filter-top pb-10">
    <div class="visibility-toggle">
      <ToggleButton id="filterVisibility" ref="filterVisibility" icon="fa-regular fa-eye" labelPositionRight inactiveIcon="fa-regular fa-eye" :label="strapi.ShowFilterButtonLabel" @click="drawer = !drawer" />
    </div>
    <VOverlay v-model="drawer" scrim="white" class="bg-white h-screen">
      <div class="d-flex px-10 pt-5">
        <VIcon iconSize="25px" icon="fa-regular fa-xmark" class="mr-4" ariaLabel="Close mobile menu" @click="closeMenu()" />
        <p>{{ strapi.HideFilterButtonLabel }}</p>
      </div>
      <CategoryLinks :data="data" class="tag px-10 w-100" :strapi="strapi" />
    </VOverlay>
  </div>
</template>

<script>
import CategoryLinks from './CategoryLinks.vue';

export default {
  name: 'FilterDropDown',
  components: { CategoryLinks },
  props: {
    sortOnKey: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      required: true
    },
    onToggle: {
      type: Function,
      default: () => {}
    },
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentSortType: this.sortType,
      filterVisible: false,
      isClearFilterVisible: this.clearFilterVisible,
      initialSortValue: 0,
      drawer: false
    };
  },
  watch: {
    clearFilterVisible() {
      this.isClearFilterVisible = this.clearFilterVisible;
      return this.isClearFilterVisible;
    }
  },
  methods: {
    closeMenu() {
      this.drawer = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-top {
  display: flex;
  border-bottom: 1px solid $color-grey-light;
}

.visibility-toggle {
  display: block;
  position: sticky;
}
.v-overlay {
  opacity: 1;
  :deep(.v-overlay__content) {
    width: 100%;
  }
}
</style>
