<template>
  <a v-if="sizesCalculated" :href="strapi.UrlToAllBrands">
    <Carousel ref="carousel" :items="strapi.Logos" :speed="strapi.AnimationSpeed" :direction="strapi.Direction">
      <template #default="{ item }">
        <VImg :src="item.url" :alt="item.name" class="logo" eager :style="{ 'aspect-ratio': item.aspectRatio }" />
      </template>
    </Carousel>
  </a>
</template>

<script>
import Carousel from '@Components/Carousel.vue';

export default {
  name: 'CarouselLogos',
  components: { Carousel },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sizesCalculated: false
    };
  },
  created() {
    this.calculateImageSizes();
  },
  methods: {
    async calculateImageSizes() {
      await Promise.all(
        this.strapi.Logos.map(async logo => {
          const img = await this.getMeta(logo.url);
          logo.aspectRatio = `${img.naturalWidth} / ${img.naturalHeight}`;
        })
      );
      this.sizesCalculated = true;
    },
    getMeta(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = err => reject(err);
        img.src = url;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: 70px;
  width: auto;

  @include is-screen-lg() {
    height: 100px;
  }

  :deep(.v-img__img) {
    position: relative;
    height: 100%;
    width: auto;
  }
}
</style>
