<template>
  <TextField ref="emailTextField" type="email" autocomplete="email" />
</template>

<script>
export default {
  name: 'EmailTextField',
  methods: {
    focus() {
      this.$refs.emailTextField.focus();
    }
  }
};
</script>
