<script setup>
import { ref } from 'vue';
import {isBlank} from "@/utils/default";

const props = defineProps({
  strapi: {
    type: Object,
    required: true,
    default: () => {}
  }
});
const Image = ref(props.strapi.Image[0]);

function width() {
  if (isBlank(props.strapi?.MaxWidth) || props.strapi?.MaxWidth === 100) return 'max-w-100';

  return `max-w-100 max-w-sm-${Math.round((100 - props.strapi?.MaxWidth) / 2) + props.strapi?.MaxWidth} max-w-md-${Math.round((100 - props.strapi?.MaxWidth) / 4) + props.strapi?.MaxWidth} max-w-lg-${props.strapi?.MaxWidth}`;
}

function alignment(){
  if (props.strapi.HorizontalAlignment === 'Left') return 'ml-0';
  if (props.strapi.HorizontalAlignment === 'Right') return 'mr-0';
  if (props.strapi.HorizontalAlignment === 'Center') return '';
  return '';
}
</script>

<template>
  <div class="container" :class="`${width()} ${alignment()}`">
    <div v-if="props.strapi.PreHeader" class="d-flex">
      <p class="text-primary pr-3 paragraph-large text-align">|</p>
      <p class="pre-header text-align">{{ props.strapi.PreHeaderText }}</p>
    </div>
    <figure class="d-flex flex-column">

<!--      <Card class="grid-item" :class="{ '-full-span': strapi.HorizontalAlignment === 'FullWidth', '-col-span-12 -col-span-lg-9 -col-start-lg-4': strapi.HorizontalAlignment === 'Right' }">-->
      <Card>
        <VImg :src="Image.url" :lazy-src="Image.formats.small.url" :aspect-ratio="Image.width / Image.height" />
      </Card>
      <figcaption v-if="props.strapi.Caption" class="mx-auto">{{ strapi.Caption }}</figcaption>
    </figure>
  </div>
</template>

<style lang="scss" scoped>
.figcaption:before {
  content: '';
  display: block;
  width: 2px;
  height: 27px;
  background: $color-primary;
  margin-right: 24px;
}
.pre-header {
  font-size: 1.5rem;
}
</style>
