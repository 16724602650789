<template>
  <Card data-menu-bg="true" adjustToMenu>
    <template #title>
      <div class="container d-flex pt-20 pb-26">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon class="w-55px h-55px" icon="fas fa-comments-question-check" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ focusedFaq.title }}</h1>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-20">
      <div class="container" :class="{ 'pl-0 pr-0': isMobile }">
        <div class="grid">
          <div class="grid-item -col-span-12">
            <ExpansionPanels v-model="openOnLoad">
              <Faq value="focused" eager :faq="focusedFaq" :strapi="{ question: { label: strapi.QuestionLabel }, solution: { label: strapi.SolutionLabel }, permanentLink: { text: strapi.PermanentLinkText } }" />
            </ExpansionPanels>
          </div>
          <div class="grid-item -col-span-12">
            <h3 class="mt-10 ml-10">{{ strapi.RecentFaqTitle }}</h3>
            <ExpansionPanels>
              <Faq v-for="(faq, index) in recentFaqs" :key="index" :faq="faq" :strapi="{ question: { label: strapi.QuestionLabel }, solution: { label: strapi.SolutionLabel }, permanentLink: { text: strapi.PermanentLinkText } }" />
            </ExpansionPanels>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import Faq from '@Components/Faq.vue';

export default {
  name: 'FaqShow',
  components: { ExpansionPanels, Faq },
  data() {
    return {
      loading: false,
      openOnLoad: 'focused',
      ...this.$controller.data
    };
  }
};
</script>

<style lang="scss" scoped></style>
