<template>
  <Swiper
    :modules="modules"
    :slides-per-view="1.3"
    :space-between="12"
    class="mt-10 w-100"
    :breakpoints="{
      600: { spaceBetween: 24, slidesPerView: 2.3 },
      1264: { spaceBetween: 48, slidesPerView: 3.3 }
    }"
    :style="{ overflow: 'hidden' }"
  >
    <SwiperSlide v-for="item in items" :key="item.Title" class="h-auto">
      <ContentItem :model-value="item" :contentType="contentType" />
    </SwiperSlide>
  </Swiper>
</template>

<script>
import ContentItem from '@Components/ContentItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';

export default {
  name: 'SelectedContentSwiper',
  components: { ContentItem, Swiper, SwiperSlide },
  props: {
    contentType: {
      type: String,
      required: true
    },
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modules: [Autoplay]
    };
  }
};
</script>

<style lang="scss" scoped>
:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/autoplay';
}
</style>
