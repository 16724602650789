<template>
  <div>
    <div v-for="link in visibleLinks" :key="link.id" class="link-wrapper mb-1" :class="{ '-active': link.active }">
      <a :href="link?.childArticleCount <= 0 ? 'javascript:void(0)' : getLinkRoute(link)" :aria-label="`go to ${link.name}`" class="link" :class="link?.childArticleCount <= 0 ? 'text-grey pe-none' : ''">{{ link.name }}</a>
      <span class="ml-auto text-grey">{{ link.childArticleCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkList',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    hideEmpty: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Filter out links with no child articles
    visibleLinks() {
      return this.data.filter(link => !this.hideEmpty || link.childArticleCount > 0);
    }
  },
  methods: {
    // Generate link routes from link url and query params
    getLinkRoute(link) {
      const url = `${link.route}`;
      const queryParams = link.queryParams ?? {};
      const query = Object.keys(queryParams)
        .map(key => `${key}=${queryParams[key]}`)
        .join('&');
      return url + (query ? `?${query}` : '');
    }
  }
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  width: 100%;
  margin-left: -14px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 14px;
  padding-top: 2px;
  padding-bottom: 2px;

  &.-active {
    background-color: $color-grey-light;
  }
}

.link {
  border-bottom: 1px solid $color-primary-semi-light;
  &:hover {
    border-bottom: 1px solid $color-primary;
  }
}
</style>
