<template>
  <div class="cart-summary pa-10 d-flex flex-column justify-end">
    <div class="d-flex justify-space-between w-100">
      <p class="paragraph-large">{{ cartStrapi.TotalSummaryLabel }}</p>
      <p class="paragraph-large">{{ $utils.moneyFormat(formatedSum) }}</p>
    </div>
    <p v-if="disabled" class="error-text">{{ cartStrapi.CartButtonDisabled }}</p>
    <Button :disabled="disabled" color="white" class="bg-accent" href="/checkout/" icon="fa-regular fa-chevron-right">
      {{ cartStrapi.CheckoutLabel }}
    </Button>
  </div>
</template>

<script>
export default {
  name: 'CartSummary',
  props: {
    totalSum: {
      type: Number,
      default() {
        return 0;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cartStrapi: this.$dom.application.cart
    };
  },
  computed: {
    formatedSum() {
      if (!Number.isInteger(this.totalSum)) {
        return this.totalSum.toFixed(2);
      }
      return this.totalSum;
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-summary {
  position: fixed;
  background-color: $color-white;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 13;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
}
.error-text {
  color: $color-validation-red;
}
</style>
