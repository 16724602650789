<template>
  <VCheckbox color="primary" density="compact" :hide-details="hideDetails" :error-messages="localErrorMessages" :class="{ 'baseline-align': baseline }" :persistentHint="persistentHint">
    <template v-if="hasSlot('label')" #label>
      <slot name="label" />
    </template>
  </VCheckbox>
</template>

<script>
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'Checkbox',
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    /**
     * @prop {Boolean} baseline - Align checkbox and label on the same baseline used when label is in markdown format
     */
    baseline: {
      type: Boolean,
      default: false
    },
    persistentHint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hideDetails() {
      return this.hasErrorMessages || this.persistentHint ? 'auto' : true;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(label) {
  color: $color-black !important;
  opacity: 1 !important;
}

.baseline-align :deep(.v-checkbox-btn) {
  align-items: baseline !important;
}
</style>
