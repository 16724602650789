import { createApp } from 'vue';
import { toPascalCase } from '@Utils/default';
import GlobalPlugins from '@Plugins/globals/_globals';
import GlobalMixins from '@Mixins/globals/_globals';
import GlobalComponents from '@Components/globals/_globals';
import crsfToken from '@Mixins/crsfToken';
import SEO from '@Mixins/seo';
import Sentry from '@/sentry.js';
export default class Vue {
  constructor() {
    this.boot() && this.setup();
  }
  setup() {
    Sentry.init(this.Vue);
    GlobalPlugins.init(this.Vue);
    GlobalMixins.init(this.Vue);
    GlobalComponents.init(this.Vue);
  }
  boot() {
    this.Vue = createApp({
      delimiters: ['%%', '%%'],
      components: {
        Navigation: require('@Components/Navigation').default,
        Breadcrumbs: require('@Components/Breadcrumbs').default,
        Page: require(`@Views/${window.Techship.application.controller}/${toPascalCase(window.Techship.application.action)}`).default,
        Footers: require('@Components/Footer').default,
        Cart: require('@Components/Cart').default,
        Login: require('@Components/Login').default
      },
      mixins: [SEO, crsfToken],
      mounted() {
        this.$mitt.emit('appMounted');
      }
    });
    this.Vue.config.errorHandler = (err, vm, info) => {
      console.error('Vue Error:', err, vm, info);
      throw err;
    };

    return true;
  }
  mount(elementId = 'vue-app') {
    if (this.Vue) this.Vue.mount(`#${elementId}`);
  }
}
