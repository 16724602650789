<template>
  <div class="variant-dropdown pl-3 pr-3" :class="{ '-not-valid': !valid }">
    <div class="wrapper">
      <button class="button" @click="onToggle">
        <span class="value-label-wrapper">
          <span class="label">{{ getSelectedValueLabel }}</span>
          <div class="arrow ml-auto">
            <i v-if="!expanded" class="fa-regular fa-chevron-down" />
            <i v-if="expanded" class="fa-regular fa-chevron-up" />
          </div>
        </span>
        <div v-if="expanded" v-click-outside="handleClickOutside" class="content">
          <Button v-for="value in listValues" :id="value.id" :key="value.key" :data-key="value.key" :class="{ '-selected': parseInt(value.id) === parseInt(currentSelection) }" class="select-button" @click="onSelect">{{ value.label }}</Button>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  props: {
    id: {
      type: String,
      default: null
    },
    values: {
      type: Array,
      default: null
    },
    initialSelection: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentSelection: 0,
      listValues: [],
      expanded: false,
      valid: true
    };
  },
  computed: {
    getSelectedValueLabel() {
      if (this.currentSelection === -1) {
        return this.label;
      }
      if (this.listValues) {
        return this.listValues[this.currentSelection].label;
      }
      return 'N/A';
    }
  },
  created() {
    this.currentSelection = this.initialSelection;
    this.values.forEach((value, index) => {
      const obj = {
        label: value.label,
        key: value.key,
        selected: index === this.currentSelection ? true : false,
        id: index
      };
      this.listValues.push(obj);
    });
  },
  methods: {
    onSelect(event) {
      this.currentSelection = event.currentTarget.id;
      this.$emit('onUpdate', {
        id: this.id,
        selectionId: event.currentTarget.id,
        selectionKey: event.currentTarget.dataset.key
      });
      this.validate();
    },
    onToggle() {
      this.expanded = this.expanded ? false : true;
    },
    handleClickOutside(event) {
      this.expanded = false;
    },
    validate() {
      if (this.currentSelection === -1) {
        this.valid = false;
      } else {
        this.valid = true;
      }
      return this.valid;
    },
    getSelectedId() {
      return this.currentSelection;
    },
    getSelectedKey() {
      return this.values[this.currentSelection].key;
    }
  }
};
</script>

<style lang="scss" scoped>
.variant-dropdown {
  display: flex;
  width: 100%;
  background-color: $color-grey-light;
  border-radius: 8px;
  height: 40px;

  &.-not-valid {
    border: 1px solid $color-validation-red;
  }
}
.wrapper {
  position: relative;
  width: 100%;
}
.content {
  position: absolute;
  width: calc(100% + 20px);
  display: block;
  background: #fff;
  border: 8px;
  padding: 10px 15px 10px 10px;
  z-index: 2;
  left: -10px;
  top: 40px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.03);
}

.button {
  height: 40px;
  width: 100%;
}

.value-label-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 10px);
  text-align: left;
  height: 21px;
  font-weight: 400;
}

.arrow {
  position: relative;
  top: 2px;
}

.select-button {
  display: block;
  width: 100%;
  height: 24px;
  text-align: left;
  color: $color-grey;
  padding: 0 5px;
  border-radius: 4px;

  &:hover {
    background-color: $color-grey-background;
  }

  &.-selected {
    background-color: $color-primary;
    color: $color-white;
  }
}
</style>
