<template>
  <div>
    <div class="grid">
      <div class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">Bank</p>
        <p class="caption -no-margin">{{ companyBankInformation.name }}</p>
        <p class="caption -no-margin">{{ companyBankInformation.bank_address }}</p>
      </div>
      <div class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">Account holder</p>
        <p class="caption -no-margin">{{ companyBankInformation.company.name }}</p>
      </div>
      <div class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">Account number</p>
        <p class="caption -no-margin">{{ companyBankInformation.account_number }}</p>
      </div>
    </div>
    <VDivider class="my-5" />
    <div class="grid">
      <div v-if="companyBankInformation.iban" class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">IBAN</p>
        <p class="caption -no-margin">{{ companyBankInformation.iban }}</p>
      </div>
      <div v-else-if="companyBankInformation.routing_number" class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">Routing number for ACH transactions</p>
        <p class="caption -no-margin">{{ companyBankInformation.routing_number }}</p>
      </div>
      <div v-if="companyBankInformation.bankgiro" class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">Bankgiro</p>
        <p class="caption -no-margin">{{ companyBankInformation.bankgiro }}</p>
      </div>
      <div v-if="companyBankInformation.bic_number" class="grid-item -col-span-4 d-flex flex-column">
        <p class="paragraph-small -no-margin bold">BIC/SWIFT</p>
        <p class="caption -no-margin">{{ companyBankInformation.bic_number }}</p>
      </div>
    </div>
    <VDivider class="my-5" />
    <div>
      <p class="caption -no-margin">The order will be processed as soon as the money has been transferred to our account.</p>
      <p class="caption -no-margin">No additional fees will be charged.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankTransferInformation',
  props: {
    companyBankInformation: {
      type: Object,
      required: true
    }
  },
  data() {
    return { loading: false };
  }
};
</script>

<style lang="scss" scoped></style>
