<template>
  <SelectApi endpoint="freight_country_states" :itemTitle="itemTitle" :queryBuilder="queryBuilder">
    <template #item="{ item }">
      <VListItem v-bind="item.props" density="compact" :title="formatDisplay(item.item.raw)" />
    </template>
    <template #selection="{ item }">
      {{ formatDisplay(item.item.raw) }}
    </template>
    <template #label="props">
      <slot name="label" v-bind="props" />
    </template>
  </SelectApi>
</template>

<script>
export default {
  name: 'SelectState',
  props: {
    itemTitle: {
      type: [String, Function],
      default: 'name'
    },
    queryBuilder: {
      type: Function,
      default: () => qb => qb.limit(100)
    }
  },
  methods: {
    formatDisplay(opt) {
      return `${opt.name} (${opt.code})`;
    }
  }
};
</script>
