<template>
  <div>
    <div v-scroll-lock="loginStore.isLoginOpen" class="side-bar d-flex flex-column" :class="{ visible: loginStore.isLoginOpen }">
      <div class="login-topbar bg-secondary px-10 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <VIcon v-if="showLogin" icon="far fa-user" size="23px" />
          <VIcon v-else-if="showForgotPassword" icon="far fa-unlock" size="23px" />
          <p v-if="showLogin" class="-no-margin bold ml-3">{{ strapi.Login }}</p>
        </div>
        <Button iconButton iconSize="25px" icon="far fa-xmark" color="white" ariaLabel="Close login" :click="loginStore.toggle" />
      </div>
      <div class="content mt-20 pl-10 pr-10 pt-10 pb-10">
        <LoginPartial v-if="showLogin" @onForgotPassword="onShowForgotPassword" @onLoginContinue="onLoginContinue" @onSuccessfulLogin="onSuccessfulLogin" />
        <LoginForgotPasswordPartial v-else-if="showForgotPassword" @onSuccessfulForgotPassword="onSuccessfulForgotPassword" />
      </div>
    </div>
    <div role="button" aria-label="close login sidebar" class="side-bar-tint d-block" :class="{ visible: loginStore.isLoginOpen }" @click="loginStore.toggle()" />
  </div>
</template>

<script>
import { useLoginStore } from '@Store/login';
import LoginPartial from '@Components/LoginPartial.vue';
import LoginForgotPasswordPartial from '@Components/LoginForgotPasswordPartial.vue';

export default {
  name: 'Login',
  components: { LoginPartial, LoginForgotPasswordPartial },
  data() {
    return {
      loginStore: useLoginStore(),
      strapi: this.$dom.application.login,
      showLogin: true,
      showForgotPassword: false
    };
  },
  computed: {
    isLoginTrayVisible() {
      return this.loginStore.isLoginOpen;
    }
  },
  watch: {
    isLoginTrayVisible() {
      // Reset before open
      if (this.loginStore.isLoginOpen) {
        this.showLogin = true;
        this.showForgotPassword = false;
      }
    }
  },
  created() {
    this.$mitt.on('login:toggle', () => {
      this.loginStore.toggle();
    });
  },
  mounted() {
    if (this.loginStore.shouldShowForgotPasswordSuccesfulResponse) {
      this.showForgotPassword = true;
      this.showLogin = false;
    }
    this.loginStore.clearLocalStorage();

    const redirectUrl = this.getURLParameter('redirect_url');
    // console.log(redirectUrl);
    if (this.$utils.isNotBlank(redirectUrl)) {
      this.loginStore.toggle();
    }
  },
  methods: {
    onSuccessfulLogin() {
      localStorage.setItem('login.open', 'true');
      localStorage.setItem('login.open.showLoginSuccesfulResponse', 'true');
      this.$reload();
    },
    onSuccessfulForgotPassword() {
      localStorage.setItem('login.open', 'true');
      localStorage.setItem('login.open.showForgotPasswordSuccesfulResponse', 'true');
      this.$reload();
    },
    onShowForgotPassword(event) {
      this.showForgotPassword = true;
      this.showLogin = false;
    },
    onShowLogin() {
      this.showForgotPassword = false;
      this.showLogin = true;
    },
    onLoginContinue() {
      this.loginStore.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  overflow-x: hidden;
}
.side-bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  z-index: 11;
  background-color: $color-white;
  transform: translateX(100%);
  transition: transform 0.6s ease;

  @include is-screen-md {
    max-width: 415px;
  }

  &.visible {
    transform: translateX(0);
  }
}

.login-topbar {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
}

.input {
  height: 40px;
  width: 100%;

  :deep(.v-field) {
    border: 1px solid $color-grey-light;
  }
}

.side-bar-tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  z-index: 10;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  transform: translateX(100%);
  transition:
    transform 0s ease 0.2s,
    opacity 0.2s ease-in-out;

  &.visible {
    transform: translateX(0);
    opacity: 1;
    transition:
      transform 0s ease 0.2s,
      opacity 0.2s ease-in-out 0.2s;
  }
}
</style>
