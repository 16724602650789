<template>
  <VForm>
    <template v-if="hasSlot('section1')">
      <div :class="{ 'grid-mobile': isMobile, 'grid-container': grid }" :style="gridStyles">
        <slot name="section1" />
      </div>
      <template v-if="hasSlot('section2')">
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <div v-if="hasSlot('section2')" :class="{ 'grid-mobile': isMobile, 'grid-container': grid }" :style="gridStyles">
          <slot name="section2" />
        </div>
      </template>
    </template>
    <template v-else>
      <div :class="{ 'grid-mobile': isMobile, 'grid-container': grid }" :style="gridStyles">
        <slot />
      </div>
    </template>
    <slot name="actions" />
  </VForm>
</template>

<script>
export default {
  name: 'Form', // eslint-disable-line
  props: {
    isEditing: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Boolean,
      default: false
    },
    columns: {
      type: String,
      default: '1fr'
    },
    gap: {
      type: String,
      default: '16px'
    }
  },
  computed: {
    gridStyles() {
      if (this.grid) {
        return {
          'grid-template-columns': this.columns,
          gap: this.isEditing ? '24px' : this.gap
        };
      }
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-form) {
  position: relative;
}

:deep(label),
:deep(legend) {
  color: $color-grey-dark;
}

.grid-container {
  display: grid !important;

  :deep(.v-input) {
    margin-top: -20px !important;
  }
}

.grid-mobile {
  grid-template-columns: 1fr;
}
</style>
