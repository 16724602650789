<template>
  <div class="toggle-button-wrapper" @click="toggle">
    <span v-if="!labelPositionRight">{{ buttonLabel }}</span>
    <button v-if="icon && inactiveIcon" class="icon-btn" :class="{ '-active': isActive, 'ml-2': !labelPositionRight, 'mr-2': labelPositionRight }">
      <VIcon size="x-small" :icon="buttonIcon" />
    </button>
    <button v-if="!icon && !inactiveIcon" class="toggle-btn" :class="{ '-active': isActive, 'ml-2': !labelPositionRight, 'mr-2': labelPositionRight }" />
    <span v-if="labelPositionRight">{{ buttonLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    id: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    inactiveLabel: {
      type: String,
      default: null
    },
    labelPositionRight: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    inactiveIcon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isActive: this.active
    };
  },
  computed: {
    buttonIcon() {
      if (!this.inactiveIcon) {
        return this.icon;
      }
      return this.isActive ? this.icon : this.inactiveIcon;
    },
    buttonLabel() {
      if (!this.inactiveLabel) {
        return this.label;
      }
      return this.isActive ? this.label : this.inactiveLabel;
    }
  },
  watch: {
    active() {
      this.isActive = this.active;
      return this.isActive;
    }
  },
  created() {
    this.isActive = this.active;
  },
  methods: {
    toggle() {
      this.isActive = this.isActive ? false : true;
      this.$emit('onToggle', { id: this.id, isActive: this.isActive });
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-button-wrapper {
  display: flex;
  cursor: pointer;

  span {
    line-height: 25px;
  }
}
.toggle-btn {
  position: relative;
  display: block;
  width: 40px;
  height: 24px;
  background-color: $color-grey-light;
  border-radius: 100px;
  transition: background-color 0.2s ease-in-out;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }

  &.-active {
    background-color: $color-primary;
    &::after {
      transform: translateX(17px);
    }
  }
}
</style>
