<template>
  <ExpansionPanel :eager="eager" @group:selected="onSelected">
    <template #title>
      <p class="paragraph-small paragraph-md -no-margin bold px-1">{{ $utils.he.decode(modelValue.name) }}</p>
    </template>
    <div class="grid pa-md-5">
      <div class="grid-item -col-span-12 -col-span-md-6">
        <div class="grid">
          <div class="grid-item -col-span-6 -col-span-lg-4 -col-span-xl-3">
            <p class="paragraph-small -no-margin text-grey-dark">Uploaded at</p>
            <p class="paragraph -no-margin">{{ $utils.date(modelValue.created_at) }}</p>
          </div>
          <div class="grid-item -col-span-6 -col-span-lg-4 -col-span-xl-3">
            <p class="paragraph-small -no-margin text-grey-dark">Last updated at</p>
            <p class="paragraph -no-margin">{{ $utils.date(modelValue.updated_at) }}</p>
          </div>
          <div class="grid-item -col-span-6 -col-span-lg-4 -col-span-xl-3">
            <p class="paragraph-small -no-margin text-grey-dark">Version</p>
            <p class="paragraph -no-margin">{{ modelValue.version || 'N/A' }}</p>
          </div>
        </div>
        <div class="grid mt-6">
          <div v-if="$isNotBlank(modelValue.description)" class="grid-item -col-span-12 -col-span-lg-10">
            <p class="paragraph-small -no-margin text-grey-dark">Description</p>
            <p v-markdown="modelValue.description || ''" class="paragraph -no-margin" />
          </div>
          <div v-if="$isNotBlank(modelValue.requirements)" class="grid-item -col-span-12 -col-span-lg-10">
            <p class="paragraph-small -no-margin text-grey-dark">Requirements</p>
            <p v-markdown="modelValue.requirements || ''" class="paragraph -no-margin" />
          </div>
        </div>
        <!-- <div class="grid-item -col-span-12 mt-10"> -->
        <div class="grid-item -col-span-6 mt-10">
          <Button prependIcon="fas fa-download" variant="elevated" size="default" color="secondary" :href="modelValue.route.download" @click.prevent="handleButtonClick">Download</Button>
          <a :href="modelValue.route.publicShow" target="_blank" class="d-block paragraph-small mt-6 underline-primary">Permanent link</a>
        </div>
      </div>
      <div class="grid-item -col-span-12 -col-span-md-6">
        <p class="paragraph-small mb-1 text-grey-dark">Related products</p>
        <template v-if="loading">
          <VSkeletonLoader class="h-20px w-100 max-w-300px" color="grey-background" />
          <VSkeletonLoader class="mt-2 h-20px w-100 max-w-300px" color="grey-background" />
          <VSkeletonLoader class="mt-2 h-20px w-100 max-w-300px" color="grey-background" />
        </template>
        <p v-for="relatedProduct in relatedProducts" v-else-if="$isNotEmpty(relatedProducts)" :key="relatedProduct.id" class="paragraph-small -no-margin mb-2">
          <a v-if="relatedProduct.variant" :href="relatedProduct.variant.route.publicShow" target="_blank" class="underline-primary">{{ relatedProduct.name }}</a>
        </p>
      </div>
    </div>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@Components/ExpansionPanel.vue';

export default {
  name: 'File',
  components: { ExpansionPanel },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    eager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      relatedProducts: []
    };
  },
  async mounted() {
    if (this.eager) {
      await this.loadRelatedProducts();
    }
  },
  methods: {
    handleButtonClick(event) {
      // Push the event to the dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'downloadFileButtonClicked',
        eventCategory: 'Button',
        eventAction: 'Click',
        file: this.modelValue.file_name,
        href: this.modelValue.route.download
      });

      // Navigate to the href after a short delay to ensure the event is sent
      setTimeout(() => {
        window.location.href = this.modelValue.route.download;
      }, 200);
    },
    async loadRelatedProducts() {
      if (this.$isNotEmpty(this.relatedProducts)) return;
      this.loading = true;
      await Promise.all([
        this.get(this.modelValue.route.getRelatedProducts).then(response => {
          if (response) {
            this.relatedProducts = response;
          }
        }),
        new Promise(resolve => setTimeout(resolve, 1000))
      ]);
      this.loading = false;
    },
    async onSelected(state) {
      if (state.value) {
        await this.loadRelatedProducts();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
