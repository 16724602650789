<template>
  <TextField type="tel" autocomplete="tel">
    <template v-if="hasSlot('append-outer')" #append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="hasSlot('append-inner')" #append-inner>
      <slot name="append-inner" />
    </template>
  </TextField>
</template>

<script>
export default {
  name: 'PhoneTextField'
};
</script>

<style lang="scss" scoped>
:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-field--error) {
  background-color: $color-validation-red-light !important;
}
</style>
