<template>
  <VDialog v-model="dialog" min-width="500px" eager width="auto" persistent>
    <Card class="pa-6">
      <VCardTitle class="d-flex flex-column align-center">
        <h4>Secure Payment By</h4>
        <VImg :src="strapi.Logo.url" :alt="strapi.Logo.alternativeText" width="100%" height="100px" :aspect-ratio="strapi.Logo.width / strapi.Logo.height" />
        <p class="paragraph-small -no-margin">
          Your payment will be processed by our payment partner
          <a class="ts-link-underline" href="https://www.stripe.com" aria-label="click to open stripes website in another browser tab" target="_blank">Stripe</a>
          when the order is submitted.
        </p>
      </VCardTitle>
      <VCardText>
        <form id="payment-form">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
        </form>
        <div v-if="hasError" class="d-flex mt-5">
          <VIcon icon="fas fa-circle-xmark" color="red" />
          <p class="paragraph text-error">{{ error }}</p>
        </div>
        <VDivider class="my-5" />
        <Button class="bg-primary w-100" color="white" :disabled="loading" :loading="loading" @click="pay">Pay {{ $moneyFormat(options.amount, options.currency) }}</Button>
        <Button class="bg-secondary w-100 mt-2" color="white" :disabled="loading" @click="dialog = false">Close</Button>
      </VCardText>
    </Card>
  </VDialog>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'StripeDialog',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  emits: ['onOpen', 'onClose'],
  data() {
    return {
      loading: false,
      dialog: false,
      options: {},
      stripe: null,
      elements: null,
      success: false,
      error: '',
      empty: true,
      stripeMounted: false
    };
  },
  computed: {
    hasError() {
      return this.$isNotBlank(this.error);
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$emit('onOpen');
      } else {
        this.$emit('onClose');
      }
    }
  },
  methods: {
    async init(options) {
      this.options = options;
      await this.initStripe();
      this.dialog = true;
    },
    async initStripe() {
      try {
        if (!this.stripe) {
          this.stripe = await loadStripe(this.options.apiKey);
          if (!this.stripe) throw new Error('Could not load Stripe, please reload and try again.');
        }
        const elements = this.stripe.elements({ clientSecret: this.options.clientSecret });
        const paymentElement = elements.create('payment', { layout: 'tabs' });
        paymentElement.mount('#payment-element');
        this.elements = elements;
        await new Promise(r => setTimeout(r, 1000));
      } catch (error) {
        console.error(error);
        // this.$snackbar.error('Could not initialize payment...');
      }
    },
    async pay() {
      if (this.success || !this.elements) return;
      this.loading = true;
      this.error = '';
      try {
        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: { return_url: `https://${this.$host}/checkout/?return=stripe` }
        });

        if (error) {
          this.error = error.type === 'card_error' || error.type === 'validation_error' ? error.message : 'An unexpected error occurred.';
        }
      } catch (error) {
        console.log(error);
        this.error = 'Payment process failed.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
