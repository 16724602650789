<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10 overflow-visible" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="userDetailsName">{{ strapi.NameLabel }}</label>
        <TextField id="userDetailsName" v-model="user.name" name="user.name" :placeholder="strapi.NamePlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="userDetailsEmail">{{ strapi.EmailLabel }}</label>
        <EmailTextField id="userDetailsEmail" v-model="user.email" name="user.email" :placeholder="strapi.EmailPlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="userDetailsPosition">{{ strapi.PositionLabel }}</label>
        <TextField id="userDetailsPosition" v-model="user.position" name="user.position" :placeholder="strapi.PositionPlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="userDetailsMobile">{{ strapi.MobileLabel }}</label>
        <!-- <PhoneTextField id="userDetailsMobile" v-model="user.mobile" name="user.mobile" :placeholder="strapi.MobilePlaceholder" /> -->
        <TelInput id="userDetailsMobile" v-model="user.mobile" name="user.mobile" :placeholder="strapi.MobilePlaceholder" :defaultCountry="$country.code" />
      </div>
    </div>
  </Card>
</template>

<script>
import TelInput from '@Components/TelInput.vue';

export default {
  name: 'UserDetails',
  components: { TelInput },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: { ...this.modelValue }
    };
  },
  watch: {
    user: {
      handler() {
        this.$emit('update:modelValue', { ...this.modelValue, ...this.user });
      },
      deep: true
    }
  }
};
</script>
