<template>
  <div :key="componentKey" class="qty-input">
    <input :disabled="disabled || hideControls" class="paragraph bold -no-margin" type="number" :value="quantity" @input="event => manuallyUpdatedQuantity(partNumber, variant, event.target.value)" @blur="onBlur" />
    <div class="qty-decrease">
      <Button :disabled="disabled || quantity < 2 || loading" iconButton icon="fa-regular fa-minus" aria-label="Decrease quantity" class="hover-dark" :class="{ 'hide-controls': hideControls }" iconSize="16" @click="decreaseQuantity" />
    </div>
    <div class="qty-increase">
      <Button :disabled="disabled || loading" iconButton icon="fa-regular fa-plus" aria-label="Increase quantity" class="hover-dark" :class="{ 'hide-controls': hideControls }" iconSize="16" @click="increaseQuantity()" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { debounce } from '@Utils/default';

export default {
  name: 'QuantityInput',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default() {
        return 1;
      }
    },
    partNumber: {
      type: String,
      required: true,
      default() {
        return null;
      }
    },
    variant: {
      type: String,
      required: true,
      default() {
        return null;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideControls: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      componentKey: ref(0)
    };
  },
  methods: {
    onBlur() {
      // Re render input on blur if user has entered an invalid value
      this.componentKey += 1;
    },
    manuallyUpdatedQuantity: debounce(function (partNumber, variantCode, quantity) {
      console.log('manuallyQuantity', partNumber, variantCode, quantity);
      if (this.$utils.isBlank(quantity)) {
        return;
      }
      this.$mitt.emit('cart:manuallyUpdatedQuantity', {
        partNumber,
        variantCode,
        quantity
      });
      this.$emit('onManuallyUpdatedQuantity', {
        partNumber,
        variantCode,
        quantity
      });
    }, 300),
    getThisArticleAndVariant() {
      return {
        partNumber: this.partNumber,
        variantCode: this.variant
      };
    },
    increaseQuantity() {
      this.$emit('onIncreaseQuantity', this.getThisArticleAndVariant());
      this.$mitt.emit('cart:increaseQuantity', this.getThisArticleAndVariant());
    },
    decreaseQuantity() {
      this.$mitt.emit('cart:decreaseQuantity', this.getThisArticleAndVariant());
      this.$emit('onDecreaseQuantity', this.getThisArticleAndVariant());
    }
  }
};
</script>

<style lang="scss" scoped>
.qty-input {
  position: relative;
  margin-right: 16px;

  input {
    height: 40px;
    width: 100px;
    padding: 3px 0 0 0;
    text-align: center;

    // NOTE: Hide up and down arrows for number input
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @media (hover: none) {
      padding: 0; // Target touch devices to keep value centered in input
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}

.qty-decrease,
.qty-increase {
  position: absolute;
  top: 2px;
  width: 36px;
  height: 36px;
}

.qty-decrease {
  left: 0;
}

.qty-increase {
  right: 0;
}
.hide-controls {
  visibility: hidden;
}
</style>
