import DialogListener from '@Components/DialogListener.vue';
import { isString, isObject } from '@Utils/default';

export default {
  install(Vue) {
    Vue.config.globalProperties.$dialog = {
      send(message, options = {}) {
        Vue.config.globalProperties.$mitt.emit('dialog', {
          message: isString(message) ? { body: message } : message,
          options: options
        });
      },
      success(message, options = {}) {
        this.send(
          {
            title: message.title || 'Success',
            body: isObject(message) ? message.body : message
          },
          { type: 'success', ...options }
        );
      },
      warning(message, options = {}) {
        console.log('at warning', message, options);
        this.send(
          {
            title: message.title || 'Warning',
            body: isObject(message) ? message.body : message
          },
          { type: 'warning', ...options }
        );
      },
      info(message, options = {}) {
        this.send(
          {
            title: message.title || 'Info',
            body: isObject(message) ? message.body : message
          },
          { type: 'info', ...options }
        );
      },
      error(message, options = {}) {
        this.send(
          {
            title: message.title || 'Error',
            body: isObject(message) ? message.body : message
          },
          { type: 'error', timeOut: -1, ...options }
        );
      }
    };

    Vue.component('DialogListener', DialogListener);
  }
};
