export default {
  install: Vue => {
    Vue.directive('trim', {
      beforeMount(el, binding) {
        const defaultOptions = { maxLength: 50, stripHTML: false };
        const options = Object.assign(defaultOptions, binding.value);

        function stripHtmlTags(input) {
          const div = document.createElement('div');
          div.innerHTML = input;
          return div.textContent || div.innerText || '';
        }

        function trimTextContent(node, remainingLength) {
          if (node.nodeType === Node.TEXT_NODE) {
            let text = node.textContent;
            if (options.stripHTML) {
              text = stripHtmlTags(text);
            }
            const trimmedText = text.substring(0, remainingLength);
            node.textContent = remainingLength < text.length ? `${trimmedText}…` : text;
            return Math.max(remainingLength - text.length, 0);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            const childNodes = Array.from(node.childNodes);
            for (const child of childNodes) {
              remainingLength = trimTextContent(child, remainingLength);
              if (remainingLength === 0) {
                // Remove remaining child nodes
                const index = childNodes.indexOf(child);
                for (let i = index + 1; i < childNodes.length; i++) {
                  node.removeChild(childNodes[i]);
                }
                break;
              }
            }
            return remainingLength;
          }
        }

        trimTextContent(el, options.maxLength);
      }
    });
  }
};
