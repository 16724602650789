<template>
  <Card adjustToMenu data-menu-bg="true">
    <div class="container d-flex justify-center align-center pb-10 pb-lg-20 pb-xl-30">
      <Card class="w-100 max-w-md-600px max-w-lg-700px max-w-xl-800px">
        <h2 class="-no-margin">Set password and activate account</h2>
        <Form :disabled="loading" class="d-flex flex-column px-1 mt-5 mt-md-10" grid gap="20px" @submit.prevent="submit">
          <label for="setPasswordAndActivateAccount.password">Password</label>
          <TextField id="setPasswordAndActivateAccount.password" v-model="password" type="password" autofocus name="setPasswordAndActivateAccount.password" hint="Your password must contain minimum 8 characters" />
          <label for="setPasswordAndActivateAccount.confirmPassword">Confirm password</label>
          <TextField id="setPasswordAndActivateAccount.confirmPassword" v-model="confirmPassword" type="password" name="setPasswordAndActivateAccount.confirmPassword" hint="Re-enter your password to confirm" />
          <Button iconButton color="white" class="bg-primary w-100" type="submit" :loading="loading" :disabled="loading">Activate account</Button>
        </Form>
      </Card>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'SetPasswordAndActivateAccountIndex',
  data() {
    return {
      ...this.$controller.data,
      valid: false,
      loading: false,
      password: '',
      confirmPassword: ''
    };
  },
  methods: {
    async submit(event) {
      this.loading = true;
      const response = await this.post('', { setPasswordAndActivateAccount: { password: this.password, confirmPassword: this.confirmPassword } });
      if (!response) {
        this.loading = false;
        return;
      }
      setTimeout(() => {
        this.$redirect('/');
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
