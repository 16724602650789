<template>
  <div>
    <VForm>
      <div class="mb-10">
        <label class="w-200px" for="changePasswordFinancialContactName">{{ strapi.CurrentPasswordLabel }}</label>
        <TextField id="changePasswordFinancialContactName" v-model="currentPassword" type="password" name="CompanyInformation.FinancialContactName" required />
      </div>
      <div class="mb-10">
        <label class="w-200px" for="changePasswordNewPassword">{{ strapi.NewPasswordLabel }}</label>
        <p class="w-400px opacity">{{ strapi.NewPasswordRequirementsLabel }}</p>
        <TextField id="changePasswordNewPassword" v-model="newPassword" type="password" name="CompanyInformation.FinancialContactEmail" required />
      </div>
      <div class="mb-3">
        <label class="w-200px" for="changePasswordRepeatNewPassword">{{ strapi.RepeatNewPasswordLabel }}</label>
        <TextField id="changePasswordRepeatNewPassword" v-model="repeatNewPassword" type="password" name="CompanyInformation.FinancialContactEmail" required />
      </div>
      <div class="d-flex mt-5">
        <Button class="mr-5" color="secondary">
          <VIcon :icon="strapi.SaveButtonIcon" />
          {{ strapi.SaveButtonLabel }}
        </Button>
        <Button color="secondary" @click="handleCancel">
          <VIcon :icon="strapi.CancelButtonIcon" />
          {{ strapi.CancelButtonLabel }}
        </Button>
      </div>
    </VForm>
  </div>
</template>

<script>
export default {
  name: 'ChangePassword',
  props: {
    handleCancel: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    };
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  border: 3px solid $color-secondary;
  color: $color-secondary;
}
.v-btn:hover {
  background-color: $color-secondary;
  :deep(.v-btn__content) {
    color: $color-white;
  }
}
.opacity {
  opacity: 0.5;
}
</style>
