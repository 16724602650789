<template>
  <VRadio :color="color">
    <template v-if="hasSlot('label')" #label>
      <slot name="label" />
    </template>
    <slot />
  </VRadio>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(label) {
  color: $color-black;
  opacity: 1;
}
</style>
