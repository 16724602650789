<template>
  <div v-if="showLoginSuccesfulResponse" class="login-response text-center">
    <div class="d-flex justify-center mt-10 mb-10">
      <i class="icon fa-light fa-circle-check text-primary" />
    </div>
    <h4 class="mt-5">{{ strapi.LoginResponseTitle }}</h4>
    <p>{{ strapi.LoginResponseDescription }}</p>
    <Button color="white" class="mt-5 bg-accent w-100" icon="fa-regular" @click="loginContinue">{{ strapi.Continue }}</Button>
    <div class="bottom text-center">
      <p class="mb-0 bold">{{ strapi.LoginQuestions }}</p>
      <a class="underline-border" :href="`mailto:${strapi.InfoEmail}`" aria-label="open email">{{ strapi.InfoEmail }}</a>
    </div>
  </div>
  <div v-else class="login-form">
    <p class="paragraph-large bold mb-10">{{ strapi.LoginTitle }}</p>
    <VForm v-model="valid" :disabled="loading" @submit.prevent="login">
      <VRow>
        <VCol>
          <label class="mb-5 text-grey-dark" for="loginEmail">{{ strapi.Email }}</label>
          <EmailTextField id="loginEmail" ref="email" v-model="email" type="email" name="login.email" class="input h-40px w-100 mr-sm-8 mb-2 mb-sm-0" :placeholder="strapi.Email" />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="mt-2">
          <label class="mb-5 text-grey-dark" for="loginPassword">{{ strapi.Password }}</label>
          <TextField id="loginPassword" ref="password" v-model="password" type="password" name="login.password" class="input h-40px w-100 mr-sm-8 mb-2 mb-sm-0" :placeholder="strapi.Password" />
        </VCol>
      </VRow>
      <Button color="white" class="mt-5 bg-accent w-100 mt-8" icon="fa-regular" type="submit" :disabled="loading" :loading="loading">{{ strapi.Login }}</Button>
    </VForm>
    <Button :disabled="loading" textButton class="d-flex ml-auto mr-auto mt-5" @click="forgotPassword">{{ strapi.LoginForgotPasswordBtnLabel }}</Button>
    <div class="bottom text-center">
      <p class="bold mb-2">{{ strapi.LoginNoAccountTitle }}</p>
      <p class="paragraph-small -no-margin">{{ strapi.LoginNoAccountDescription }}</p>
      <Button variant="outlined" color="white" href="/register" class="mt-5 w-100 text-center register-btn bg-secondary" icon="fa-regular" :disabled="loading">{{ strapi.Register }}</Button>
      <Button v-if="$isNotEmpty(strapi.LoginMoreDetailsBtnLabel)" :href="strapi.LoginMoreDetailsBtnHref" textButton class="w-auto ml-auto mr-auto mt-5 d-flex" :disabled="loading">{{ strapi.LoginMoreDetailsBtnLabel }}</Button>
    </div>
  </div>
</template>

<script>
import { useLoginStore } from '@Store/login';

export default {
  name: 'LoginPartial',
  emits: ['onForgotPassword', 'onLoginContinue', 'onSuccessfulLogin'],
  data() {
    return {
      strapi: this.$dom.application.login,
      loading: false,
      email: '',
      password: '',
      valid: false,
      loginStore: useLoginStore()
    };
  },
  computed: {
    showLoginSuccesfulResponse() {
      return this.loginStore.shouldShowLoginSuccesfulResponse;
    }
  },
  watch: {
    'loginStore.open'(newVal, oldVal) {
      if (newVal) {
        this.$refs.email.focus();
      }
    }
  },
  mounted() {
    if (this.getURLParameter('login') == 'open') {
      this.loginStore.open = true;
      this.deleteURLParameter('login');
    }
  },
  methods: {
    async login() {
      this.loading = true;
      const response = await this.post('/login/', { login: { email: this.email, password: this.password } });
      // console.log(response);
      if (!response) {
        this.loading = false;
        return;
      }

      const redirectUrl = this.getURLParameter('redirect_url');
      if (redirectUrl) {
        return this.$redirect(redirectUrl);
      }

      if (response.isAdmin) {
        return this.$redirect('/admin/');
      }

      this.$emit('onSuccessfulLogin');
    },
    forgotPassword() {
      this.$emit('onForgotPassword');
    },
    loginContinue() {
      this.$emit('onLoginContinue');
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom {
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;

  @media screen and (min-height: 800px) {
    position: absolute;
    width: calc(100% - 80px);
    bottom: 40px;
  }
}

.register-btn {
  border: 2px solid $color-secondary;
}
.icon {
  font-size: 60px;
}

.input {
  :deep(.v-field) {
    border: 1px solid $color-grey-light;
  }
}
</style>
