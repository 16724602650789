<template>
  <Card class="mt-20">
    <div class="pt-20 container bg-white d-flex flex-column align-center">
      <span class="paragraph-small -no-margin text-grey-dark">{{ $utils.formatDateAsDayMonthYear(news.publishedAt) }}</span>
      <h1 class="text-secondary mw-655px text-center pl-5 pr-5 mt-6">{{ news.Title }}</h1>
      <p class="type-tag mb-15" :class="{ 'text-accent technicalBg': news.Type === 'Technical', 'text-primary generalBg': news.Type === 'General', 'text-secondary productBg': news.Type === 'Product' }">{{ news.Type }}</p>
      <VImg v-if="news.Image" class="rounded-lg mh-560px" width="1060" cover :src="news.Image.url" :alt="news.Image.alternativeText" />
      <VueMarkdown :source="news.Description" :options="options" class="pt-20 pb-20 paragraph-large mw-720px" />
    </div>
    <PageBuilder v-if="strapi.Modules" :modules="strapi.Modules" />
  </Card>
</template>

<script>
import VueMarkdown from 'vue-markdown-render';

export default {
  name: 'PublicNewsShow',
  components: { VueMarkdown },
  data() {
    return {
      loading: false,
      ...this.$controller.data,
      options: {
        html: true,
        breaks: true
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.type-tag {
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
}
.technicalBg {
  background: rgba($color-accent, 0.1);
}
.generalBg {
  background: rgba($color-primary, 0.1);
}
.productBg {
  background: rgba($color-secondary, 0.1);
}
</style>
