<template>
  <Card class="grid-item -col-span-12" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex">
      <VIcon icon="far fa-file-lines" size="24px" class="mr-3" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.FilesTitle || strapi.FilesLabel || 'Files' }}</h6>
    </header>
    <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
    <Dropzone v-model="files" :disableUpload="disableUpload" :disableRemove="disableRemove" :disableDownload="disableDownload" />
  </Card>
</template>

<script>
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'Files',
  components: { Dropzone },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    strapi: {
      type: Object,
      required: true
    },
    disableUpload: {
      type: Boolean,
      default: false
    },
    disableRemove: {
      type: Boolean,
      default: false
    },
    disableDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      files: this.modelValue
    };
  }
};
</script>

<style lang="scss" scoped></style>
