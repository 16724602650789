<template>
  <button v-if="iconButton && icon" :type="type" :aria-label="ariaLabel" class="icon-button d-flex align-center justify-center rounded-lg" :disabled="disabled" @click="click">
    <VIcon :icon="icon" :size="iconSize" :color="color" />
    <slot />
  </button>

  <VBtn v-else-if="href" :type="type" :loading="loading" :variant="variant" :prependIcon="prependIcon" :color="color" :disabled="disabled" rounded="lg" elevation="0" :class="{ 'px-5': variant !== 'plain', 'pl-0 pr-0': variant === 'plain' }" :size="size" :href="href" :style="{ 'min-width': minWidth }" @click="click">
    <VIcon v-if="icon && iconFirst" :icon="icon" :size="iconSize" :class="{ 'mr-4': variant !== 'plain', 'mr-2': variant === 'plain' }" :color="iconColor" :data-color="iconColor" />
    <span class="button" :class="{ plain: variant === 'plain' }">
      <slot />
    </span>
    <VIcon v-if="icon && !iconFirst" :icon="icon" :size="iconSize" :class="{ 'ml-4': variant !== 'plain', 'ml-2': variant === 'plain' }" :color="iconColor" :data-color="iconColor" />
  </VBtn>

  <button v-else-if="textButton" class="underline-border" :type="type" :disabled="disabled">
    <slot />
  </button>

  <button v-else-if="plainNoAnimation" :type="type" :disabled="disabled">
    <slot />
    <VIcon v-if="icon" :icon="icon" :size="size" class="ml-1 menu-icon" />
  </button>

  <VBtn v-else class="px-5" :class="$attrs" :loading="loading" :variant="variant" :color="color" :type="type" :block="block" :prependIcon="prependIcon" :disabled="disabled" rounded="lg" elevation="0" :size="size" :style="{ 'min-width': minWidth }" @click="click">
    <VIcon v-if="icon && iconFirst" :icon="icon" :size="iconSize" :class="{ 'mr-4': variant !== 'plain', 'mr-2': variant === 'plain' }" :color="iconColor" :data-color="iconColor" />
    <span class="button" :class="{ plain: variant === 'plain' }">
      <slot />
    </span>
    <VIcon v-if="icon && !iconFirst" :icon="icon" :size="iconSize" :class="{ 'ml-4': variant !== 'plain', 'ml-2': variant === 'plain' }" :color="iconColor" :data-color="iconColor" />
  </VBtn>
</template>

<script>
export default {
  name: 'Button', // eslint-disable-line
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: String,
      default: 'large'
    },
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: 'x-small'
    },
    click: {
      type: Function,
      default: () => {}
    },
    href: {
      type: String,
      default: null
    },
    iconButton: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: '0'
    },
    iconFirst: {
      type: Boolean,
      default: false
    },
    textButton: {
      type: Boolean,
      default: false
    },
    plainNoAnimation: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn__content {
  .button {
    display: flex;
    align-items: center;
  }
}

.icon-button {
  width: 36px;
  height: 36px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(.hover-dark) {
    @include icon-button-hover;
  }

  &.hover-dark {
    @include icon-button-hover($color-grey-light, false);
  }
}

.v-btn--variant-plain {
  opacity: 1;

  @include plain-button-hover;
}

.underline-border {
  border-bottom: 1px solid $color-primary-semi-light;
  &:hover {
    border-color: $color-primary;
  }
}
.menu-icon {
  opacity: 50%;
}

.v-btn--variant-outlined {
  border: 2px solid;

  &.text-secondary:hover {
    background-color: $color-secondary !important;
    color: $color-white !important;
  }

  &.text-primary:hover {
    background-color: $color-primary !important;
    color: $color-white !important;
  }
}
</style>
