<template>
  <Card adjustToMenu data-menu-bg="true" class="bg-grey-background">
    <template #title>
      <div class="container d-flex flex-column pt-20 pb-10 bg-grey-background">
        <div class="d-flex">
          <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
            <VIcon :icon="strapi.RmaIcon" size="x-large" />
          </div>
          <h1 class="justify-center align-center text-secondary -no-margin">{{ strapi.NewRMARequestTitle }}</h1>
        </div>
      </div>
    </template>

    <div class="wrapper bg-grey-background pb-20">
      <div class="container">
        <Form :disabled="loading" @submit.prevent="onSubmit">
          <div class="grid">
            <!-- Type Section -->
            <Card class="grid-item -col-span-12 mt-10" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon :icon="strapi.TermsIcon" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">{{ strapi.TermsTitle }}</h6>
                <!-- TODO: Change to a richText tag -->
              </header>
              <div v-markdown="strapi.TermsText" />
            </Card>

            <Card class="grid-item -col-span-12 mt-1" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon :icon="strapi.DescriptionIcon" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">{{ strapi.DescriptionLabel }}</h6>
              </header>

              <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />

              <!--TODO: Replace all placeholder attributes with strapi strings  -->
              <div class="grid">
                <!-- Left Column -->
                <div class="grid-item d-grid gap-24" :class="[{ '-col-span-12': isMobile }, { '-col-span-6': isNotMobile }]">
                  <div class="grid-item -col-span-12">
                    <fieldset>
                      <legend>* {{ strapi.ReasonForRMARequestLabel }}</legend>
                      <RadioGroup
                        id="rmaSelectReason"
                        v-model="rma.type"
                        :error-messages="errors.type"
                        @change="
                          checkFieldValid('type');
                          checkFieldValid('ticket');
                        "
                      >
                        <Radio v-for="reason in rmaReasons" :key="reason.id" :label="reason.title" :value="reason.id" class="mr-2" />
                      </RadioGroup>
                    </fieldset>
                  </div>

                  <div class="grid-item -col-span-12">
                    <fieldset>
                      <legend>* {{ strapi.ReturnTypeLabel }}</legend>
                      <RadioGroup id="rmaSelectReason" v-model="rma.return_type" inline :error-messages="errors.returnType" @changed="checkFieldValid('returnType')">
                        <Radio v-for="returnType in rmaReturnTypes" :key="returnType.id" :label="returnType.title" :value="returnType.id" class="mr-2" />
                      </RadioGroup>
                    </fieldset>
                  </div>

                  <div class="grid-item -col-span-12">
                    <label>* {{ strapi.DescriptionLabel }}</label>
                    <Textarea id="rmaDescription" v-model="rma.description" :error-messages="errors.description" :disabled="!enableDescriptionInput || loading" :placeholder="strapi.DescriptionPlaceholder" rows="10" name="description" flat auto-grow @blur="checkFieldValid('description')" />
                  </div>
                </div>

                <!-- Right Column -->
                <div class="grid-item d-grid gap-24" :class="[{ '-col-span-12': isMobile }, { '-col-span-6': isNotMobile }]">
                  <div class="grid-item -col-span-12">
                    <label>{{ `${rma.type == 1 ? '* ' : ''}${strapi.MatchingSupportTicketLabel}` }}</label>
                    <SelectTicket id="rmaTicket" v-model="ticket" :placeholder="strapi.SupportTicketPlaceholder" :error-messages="errors.ticket" returnObject @blur="checkFieldValid('ticket')" />
                  </div>

                  <div class="grid-item -col-span-12">
                    <label>* {{ strapi.OrderNumberLabel }}</label>
                    <SelectRMAOrder id="rmaOrder" :key="orderKey" v-model="order" :disabled="!enableOrderSelect || loading" :error-messages="errors.order" :placeholder="strapi.OrderPlaceholder" returnObject @blur="checkFieldValid('order')" />
                  </div>

                  <div class="grid-item -col-span-12">
                    <label>* {{ strapi.ArticleLabel }}</label>
                    <SelectRMAOrderLine id="rmaOrderLine" :key="orderLineKey" v-model="orderLine" :disabled="!enableOrderLineSelect || loading" :error-messages="errors.article" :salesOrderNo="order?.No" :placeholder="strapi.ArticlePlaceholder" name="orderLine" returnObject @blur="checkFieldValid('article')" />
                  </div>

                  <div class="grid-item -col-span-12">
                    <label>* {{ strapi.ArticleQuantityLabel }}</label>
                    <VTextField v-model="quantity" :disabled="!enableQuantitySelect || loading" :error-messages="errors.quantity" hide-details single-line type="number" @blur="checkFieldValid('quantity')" />
                  </div>

                  <div v-if="orderLine?.article?.has_imei" class="grid-item -col-span-12">
                    <label>* {{ strapi.ImeiCodeLabel }}</label>
                    <Textarea id="rmaImeisString" v-model="imeisString" :disabled="!enableImeiInput || loading" :error-messages="errors.imei" :placeholder="strapi.IMEIPlaceholder" rows="10" name="imeisString" flat auto-grow @blur="checkFieldValid('imei')" />
                    <label>[{{ rma.imeis?.length ?? 0 }}] Valid IMEI(s) identified</label>
                  </div>
                </div>

                <!-- TODO: Submit and Cancel buttons -->
                <!-- Create Section -->
                <div class="grid-item -col-span-12 gap-13 justify-self-end">
                  <Button class="bg-white mr-3" variant="outlined" :disabled="loading" color="secondary">{{ strapi.CancelLabel }}</Button>
                  <Button class="bg-secondary" :disabled="loading" :loading="loading" color="white" type="submit">{{ strapi.SubmitBtnLabel }}</Button>
                </div>
              </div>
            </Card>
          </div>
        </Form>
      </div>
    </div>
  </Card>
</template>

<script>
import DropDown from '@Components/my_account/DropDown.vue';

export default {
  name: 'RmaRequestNew',
  components: { DropDown },
  data() {
    return {
      loading: false,
      ...this.$controller.data,

      rma: {
        type: null,
        description: null,
        ticket_id: null,
        order_number: null,
        article_id: null,
        article_variant_id: null,
        article_quantity: null,
        return_type: null,
        imeis: null
      },

      rmaReasons: [
        { id: 1, title: this.$controller.data.strapi.RmaRequestReasons.RmaReasonWarranty },
        { id: 2, title: this.$controller.data.strapi.RmaRequestReasons.RmaReasonGoodwill },
        { id: 3, title: this.$controller.data.strapi.RmaRequestReasons.RmaReasonMisdelivery }
      ],

      rmaReturnTypes: [
        { id: 1, title: this.$controller.data.strapi.RmaRequestReturnTypes.CreditLabel },
        { id: 2, title: this.$controller.data.strapi.RmaRequestReturnTypes.ReplacementLabel }
      ],

      ticket: null,
      description: '',
      imeisString: '',
      order: null,
      orderLine: null,
      quantity: null,
      orderLineKey: 0,
      orderKey: 0,

      errors: {
        order: [],
        ticket: [],
        imei: [],
        description: [],
        returnType: [],
        type: [],
        article: [],
        quantity: []
      }
    };
  },
  computed: {
    ticketSelected() {
      return this.$utils.isNotEmpty(this.ticket);
    },
    orderSelected() {
      return this.$utils.isNotEmpty(this.order);
    },
    orderLineSelected() {
      return this.$utils.isNotEmpty(this.orderLine);
    },
    enableOrderSelect() {
      return this.rma.type != 1 || this.ticketSelected;
    },
    enableOrderLineSelect() {
      return this.enableOrderSelect && this.orderSelected;
    },
    enableQuantitySelect() {
      return this.enableOrderSelect && this.enableOrderLineSelect && this.orderLineSelected;
    },
    enableImeiInput() {
      return this.enableOrderSelect && this.enableOrderLineSelect && this.enableQuantitySelect;
    },
    enableDescriptionInput() {
      return this.enableOrderSelect;
    }
  },
  watch: {
    description() {
      this.rma.description = this.description;
      this.checkFieldValid('description');
    },
    order() {
      this.orderLine = null;
      this.orderLineKey++;
      this.checkFieldValid('order');
      // this.checkFieldValid('article');
      // console.log('Order updated - Nulled Orderline');
      // console.log(this.order);
    },
    orderLine() {
      this.quantity = this.orderLine?.Quantity ?? 0;
      // this.checkFieldValid('article');
    },
    quantity() {
      this.checkFieldValid('quantity');
    },
    imeisString() {
      this.rma.imeis = this.imeisString.match(/\b\d{15}\b/g) || [];
      this.checkFieldValid('imei');
    }
  },
  mounted() {
    setTimeout(() => {
      // console.log(this.strapi);
      this.showModal = true;
    }, 200);

    // TODO: Make this safe incase rmaReasons is empty?
    this.rma.type = this.rmaReasons[0].id;
    this.rma.return_type = this.rmaReturnTypes[0].id;
  },
  methods: {
    onCancel() {
      this.showModal = false;
      setTimeout(() => {
        this.$emit('onCancel');
      }, 1000);
    },

    clearFieldErrors(field) {
      this.errors[field] = [];
    },

    checkFieldValid(field) {
      // TODO: Replace with strapi strings

      // Specific rules for each field
      // Conditions are the rules that must be met for the field to be valid
      const rules = {
        ticket: [{ message: 'Warranty RMAs require a matching support ticket', condition: this.rma.type != 1 || !!this.ticket }],

        order: [{ message: 'You must select an Order', condition: !!this.order }],

        article: [{ message: 'Article is Required', condition: !!this.orderLine }],

        quantity: [
          { message: 'Article Quantity is Required', condition: !!this.quantity },
          { message: 'RMA Quantity more than purchase line qty', condition: this.quantity <= this.orderLine?.Quantity },
          { message: 'RMA Quantity cannot be less than 1', condition: this.quantity > 0 }
        ],

        imei: [{ message: 'For this article, IMEIs are Required', condition: this.rma.imeis?.length > 0 || !this.orderLine?.article?.has_imei }],

        returnType: [{ message: 'Return Type is Required', condition: this.rma.return_type in this.returnTypes }],

        type: [{ message: 'Reason is Required', condition: this.rma.type in this.types }],

        description: [{ message: 'Description is Required', condition: this.rma.description?.length > 0 }]
      };

      this.clearFieldErrors(field);

      let fieldValid = true;
      rules[field]?.forEach(rule => {
        if (!rule.condition) {
          // console.log(field, '-', rule.message);
          fieldValid = false;
          this.errors[field].push(rule.message);
        }
      });

      return fieldValid;
    },

    checkFormValid() {
      let formValid = true;
      Object.keys(this.errors).forEach(field => {
        formValid &= this.checkFieldValid(field);
      });

      // console.log('Form is', formValid ? '' : 'NOT', 'valid');
      return formValid;
    },

    async onSubmit() {
      if (!this.checkFormValid()) {
        return;
      }
      this.loading = true;

      // TODO: These maybe should be done in watchers? Or straight up on the models?
      this.rma.imeis = this.imeisString.match(/\b\d{15}\b/g) || [];

      this.rma.article_id = this.orderLine?.article.id;
      this.rma.article_quantity = parseInt(this.quantity);
      this.rma.article_variant_id = this.orderLine?.articleVariant?.id;

      this.rma.order_number = this.order?.No;

      this.rma.ticket_id = this.ticket?.id;

      // console.log(this.rma);

      const response = await this.post('/account/rma/', this.rma);

      // console.log(response);
      if (!response) {
        this.loading = false;
        return;
      }
      this.$snackbar.success('Successfully created RMA');
      this.redirectWithTimeout(response.route);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -78px;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  display: block;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  &.-visible {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.error-field {
  font-size: 12;
}

.-no-margin {
  margin: 0;
}

:deep(.fa-dot-circle) {
  color: $color-primary;
}

.container-wrapper {
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 15;
  transform: translateY(100%);
  transition:
    opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out 0.2s;
  &.-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
