<template>
  <div class="filter-expandable-block" :class="{ '-epxanded': isExpanded }">
    <div class="label-wrapper mb-2" @click="onToggle">
      <p class="paragraph-large bold -no-margin">{{ generateLabel() }}</p>
      <!-- <Button iconButton class="expand-btn" :class="{ '-collapsed': !isExpanded }" /> -->
    </div>
    <div class="content" :class="{ '-hidden': !isExpanded }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterExpandableBlock',
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    unit: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isExpanded: this.expanded
    };
  },
  watch: {
    expanded() {
      this.isExpanded = this.expanded;
      return this.isExpanded;
    }
  },
  methods: {
    generateLabel() {
      return this.unit ? `${this.label} [${this.unit}]` : this.label;
    }
    // onToggle() {
    //   this.isExpanded = this.isExpanded ? false : true;
    //   this.$emit('onExpandChange', { isExpanded: this.isExpanded });
    // }
  }
};
</script>

<style lang="scss" scoped>
.label-wrapper {
  display: flex;
  width: 100%;
  // cursor: pointer;
}

.content {
  &.-hidden {
    display: none;
  }
}
.expand-btn {
  position: relative;
  display: block;
  margin-left: auto;
  width: 14px;
  height: 14px;
  top: 4px;

  &::before,
  &::after {
    position: relative;
    content: '';
    display: block;
    background: $color-black;
    transition: transform 0.2s ease-in-out;
    border-radius: 4px;
  }

  &::before {
    width: 14px;
    height: 2px;
    top: 8px;
  }

  &::after {
    width: 2px;
    height: 14px;
    left: 6px;
    transform: scale(0);
  }

  &.-collapsed {
    &::after {
      transform: scale(1);
    }
  }
}
</style>
