<template>
  <div class="productCatImgWrapper" :class="{ hover: hover, animateUp: animateUp }">
    <VImg aspect-ratio="1" class="productCatImg rounded-circle" cover :style="`background-image: url('${image}');`" />
    <div class="productIconWrapper rounded-circle">
      <VIcon class="productIcon text-primary" :class="icon" size="30px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductRoundImage',
  props: {
    image: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    animateUp: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.productCatImg {
  position: relative;
  border: 12px solid white;
  box-shadow: 0px 2px 20px 2px rgba(100, 100, 100, 0);
  transform: translateY(0);
  transition:
    box-shadow 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform 0.8s ease-in-out;
  }
}

.productCatImgWrapper {
  position: relative;
  transform: scale(0.95);
}

.productIconWrapper {
  position: absolute;
  top: calc(50% - (27% / 2));
  left: calc(50% - (27% / 2));
  width: 27%; // 27% area of the big circle, figma
  height: 27%;
  background: white;
  text-align: center;
  transform: scale(1) translateY(0);
  transition: transform 0.5s ease-in-out;
}

.productIcon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.hover {
  &.animateUp {
    .productCatImg {
      transform: translateY(-5px);
      box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.2);
    }
  }

  .productCatImg {
    &:after {
      transform: scale(1.2);
    }
  }

  .productIconWrapper {
    transform: scale(0.9) translateY(-5px);
  }
}
</style>
