<template>
  <Card class="grid-item -col-span-12" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex">
      <VIcon :icon="strapi.CommentsHistoryIcon" size="24px" class="mr-3" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.CommentsHistoryTitle }}</h6>
    </header>
    <template v-for="comment in comments" :key="comment.id">
      <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
      <Form grid :isEditing="false" columns="250px auto" gap="40px">
        <div>
          <div class="d-flex flex-column">
            <label>{{ strapi.AuthorLabel }}</label>
            <span>{{ comment.commenter.name }}</span>
          </div>

          <div class="d-flex flex-column mt-4">
            <label>{{ strapi.DateAndTimeLabel }}</label>
            <span>{{ $utils.formatDateAsDayMonthYear(comment.updated_at, 'full') }}</span>
          </div>
        </div>

        <div>
          <div v-markdown="comment.body" />
          <Dropzone :model-value="comment.s3Files" disableUpload disableRemove />
        </div>
      </Form>
    </template>
  </Card>
</template>

<script>
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'Comments',
  components: { Dropzone },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      comments: this.modelValue
    };
  }
};
</script>

<style lang="scss" scoped></style>
