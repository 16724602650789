<template>
  <Carousel :items="items" :speed="strapi.AnimationSpeed" :direction="strapi.Direction">
    <template #default="{ item }">
      <h2 class="text-primary ma-0 pa-0" v-html="item" />
    </template>
  </Carousel>
</template>

<script>
import Carousel from '@Components/Carousel.vue';
export default {
  name: 'CarouselText',
  components: { Carousel },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      return this.strapi.Texts.split(/\s*,\s*/).map(word => `<span>${word}</span>`);
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 88px !important;

  @include is-screen-md {
    font-size: 116px !important;
  }

  @include is-screen-lg {
    font-size: 172px !important;
  }
}

.slider {
  height: 100px;

  @include is-screen-lg {
    height: 200px;
  }

  :deep(.slide) {
    width: fit-content;
    height: auto;

    span {
      padding: 0 8px;
      @include is-screen-lg {
        padding: 0 24px;
      }
    }
  }
}
</style>
