<template>
  <Card adjustToMenu data-menu-bg="true">
    <template #title>
      <div class="container d-flex flex-column pt-20 pb-26 bg-white">
        <div class="d-flex">
          <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
            <VIcon :icon="strapi.Icon" size="x-large" />
          </div>
          <h1 class="text-secondary -no-margin">
            {{ strapi.Title }}
            <span class="text-grey">{{ ticket.id }}</span>
          </h1>
        </div>
        <p class="text-black mt-10 mr-2 fs-32">{{ ticket.title }}</p>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-20">
      <div class="container grid">
        <!-- Details Section -->
        <Card class="grid-item -col-span-6" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
          <header class="d-flex">
            <VIcon :icon="strapi.DetailSummaryIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ strapi.DetailSummaryTitle }}</h6>
          </header>
          <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
          <Form :disabled="loading" grid :isEditing="false" columns="1fr 1fr">
            <label>{{ strapi.DetailSummary.Title }}</label>
            <p class="-no-margin">{{ ticket.title }}</p>

            <label>{{ strapi.DetailSummary.Type }}</label>
            <p class="-no-margin">{{ ticket.combinedTypeName }}</p>

            <!-- <label>{{ strapi.DetailSummary.DateAndTime }}</label> -->
            <label>Created</label>
            <p class="-no-margin">{{ $formatDateAsDayMonthYear(ticket.created_at, 'full') }}</p>

            <label>Updated</label>
            <p class="-no-margin">{{ $formatDateAsDayMonthYear(ticket.updated_at, 'full') }}</p>

            <template v-if="ticket.article">
              <!-- <label>{{ strapi.DetailSummary.ArticleProduct }}</label> -->
              <label>Product</label>
              <a class="underline-primary" :href="ticket.article.route.publicShow" target="_blank">{{ ticket.article.name }}</a>
            </template>
            <template v-else-if="$isNotBlank(ticket.manual_article)">
              <label>Product</label>
              <p class="-no-margin">{{ ticket.manual_article }}</p>
            </template>

            <label>{{ strapi.DetailSummary.Status }}</label>
            <p class="-no-margin">{{ ticket.status }}</p>

            <label>{{ strapi.DetailSummary.MailingList }}</label>
            <p class="-no-margin">{{ customerCCs }}</p>

            <template v-for="field in ticket.values" :key="field.id">
              <label>{{ field.ticketTypeField.label }}</label>
              <p class="-no-margin">{{ field.text }}</p>
            </template>
          </Form>
        </Card>

        <!-- Description Section -->
        <Card class="grid-item -col-span-6" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
          <header class="d-flex">
            <VIcon :icon="strapi.DescriptionIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ strapi.DescriptionTitle }}</h6>
          </header>
          <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
          <div v-markdown="ticket.description" />
        </Card>

        <!-- Files Section -->
        <Files v-if="$isNotEmpty(ticket.s3files)" v-model="ticket.s3files" :strapi="strapi" disableUpload disableRemove />

        <!-- Add Comment Section -->
        <!-- TODO: Should probb use the status id -->
        <AddComment v-if="ticket.isOpenStatus" :strapi="strapi" />

        <!-- Comments History -->
        <Comments v-model="ticket.comments" :strapi="strapi" />
      </div>
    </div>
  </Card>
</template>

<script>
import AddComment from './components/AddComment.vue';
import Files from './components/Files.vue';
import Comments from './components/Comments.vue';

export default {
  name: 'SupportRequestShow',
  components: { AddComment, Files, Comments },
  data() {
    return {
      loading: false,
      openStates: ['New', 'In process'],
      ...this.$controller.data
    };
  },
  computed: {
    customerCCs() {
      return this.$map(this.ticket.customerCC, user => user.name).join(', ');
    }
  }
};
</script>

<style lang="scss" scoped></style>
