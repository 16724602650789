<template>
  <Card class="search-result-card pt-30" data-menu-bg="true">
    <template #title>
      <div class="container">
        <div class="d-flex align-center">
          <div class="bg-primary d-flex rounded-circle justify-center align-center align-self-start">
            <VIcon icon="far fa-search" class="w-55px h-55px" size="x-large" />
          </div>
          <h1 class="text-secondary -no-margin ml-4" v-html="$sprintf(strapi.Title, query)" />
        </div>
        <p class="paragraph-small -no-margin mt-4 text-grey-dark">{{ $sprintf(strapi.ResultsFound, totalRecords) }}</p>
      </div>
    </template>
    <div class="mt-18">
      <div class="search-result-tabs-container d-flex align-center justify-space-between container pb-4">
        <VTabs v-model="tab" show-arrows>
          <VTab v-for="(result, index) in results" :key="index" :value="index" sliderColor="primary" variant="plain" :ripple="false" class="px-0" hideSlider>
            <span class="paragraph ma-0">{{ result.label }}</span>
            <span class="paragraph-small ma-0 ml-1">({{ result.records }})</span>
          </VTab>
        </VTabs>
        <a class="d-none d-md-flex font-weight-bold align-center" aria-label="go to contact us" :href="strapi.Tab.Href">
          {{ strapi.Tab.Text }}
          <VIcon v-if="strapi.Tab.Icon" :icon="strapi.Tab.Icon" class="ml-2" color="secondary" />
        </a>
      </div>
      <VCardText class="pt-16 px-0">
        <div ref="scrollTarget" class="container">
          <VWindow v-model="tab">
            <VWindowItem v-for="(result, index) in results" :key="index" :value="index">
              <VProgressCircular v-if="loading && index != 'products'" :size="75" width="5" color="primary" bg-color="white" indeterminate class="d-block ma-auto mb-16 mt-12" />
              <Component :is="index" v-show="!loading || index == 'products'" v-model:loading="loading" :active="index === tab" :previousTab="previousTab" :itemsOnPage="itemsPerPage" :paginationTotalVisible="paginationTotalVisible" class="search-result-content" :items="result.items" :records="result.records" @paginate="paginate" />
            </VWindowItem>
          </VWindow>
        </div>
      </VCardText>
    </div>
  </Card>
</template>

<script>
import Products from './components/Products.vue';
import Downloads from './components/Downloads.vue';
import News from './components/News.vue';
import Faqs from './components/Faqs.vue';
import BlogArticles from './components/BlogArticles.vue';

export default {
  name: 'SearchSiteIndex',
  components: { Products, Downloads, News, Faqs, BlogArticles },
  data() {
    return {
      loading: false,
      tab: 'products',
      previousTab: undefined,
      paginationTotalVisible: 6,
      ...this.$controller.data
    };
  },
  watch: {
    tab(newVal, oldVal) {
      this.scrollToTop();
      this.previousTab = oldVal;
      this.updateURLParameter('type', newVal);
    }
  },
  created() {
    const type = this.getURLParameter('type');
    if (type) {
      this.tab = type;
    }
  },
  methods: {
    async paginate(page) {
      this.scrollToTop();
      this.loading = true;
      const response = await this.post('/search/paginate/', {
        query: this.query,
        type: this.tab,
        page: page
      });
      if (!response) return;
      this.results[this.tab].items = response.items;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-tabs) {
  .v-btn {
    font-size: initial;
    position: relative;
    transition: opacity 0.3s;

    &:not(:first-of-type) {
      margin-left: 40px !important;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transform: translateX(-50%);
      transition: width 0.3s;
    }

    &:hover::before,
    &.v-tab--selected::before {
      width: 100%;
    }
  }

  .v-tab--selected {
    opacity: 1;
  }
}

.search-result-tabs-container {
  position: sticky;
  top: 0;
  background-color: $color-white;
  opacity: 0.9;
  z-index: 3;
}

:deep(.search-result-content) {
  & > div {
    width: 100%;
  }
  .v-pagination {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.search-result-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-card-text {
  background-color: $color-grey-light;
}

.search-result-card {
  z-index: unset !important;
  overflow: unset !important;
  margin-top: $menu-height-desktop;
}
</style>
