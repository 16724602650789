<template>
  <div>
    <PageBuilder :modules="strapi.Modules" />
    <ProductListing :categories="brandCategories" :filterAttributes="filterAttributes" :sortingStrategies="sortingStrategies" :strapi="strapiFilterSort" />
  </div>
</template>

<script>
import ProductListing from '@Components/ProductListing.vue';

export default {
  name: 'ProductListBrand',
  components: { ProductListing },
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  },
  computed: {
    brandCategories() {
      return this.$controller.data.categories.map(category => {
        const brandKey = `${this.$controller.data.brandKey}`;
        return {
          ...category,
          queryParams: {
            [brandKey]: this.$controller.data.brandId
          }
        };
      });
    }
  }
};
</script>
