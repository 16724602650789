import { throttle } from 'throttle-debounce';

export default {
  data() {
    return {
      isMobile: false,
      localMobileBreakpoint: 1024
    };
  },
  computed: {
    isNotMobile() {
      return !this.isMobile;
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.throttleCheckScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttleCheckScreenSize);
  },
  methods: {
    checkScreenSize() {
      if (this.isMobile && window.innerWidth > this.localMobileBreakpoint) {
        this.isMobile = false;
      } else if (!this.isMobile && window.innerWidth <= this.localMobileBreakpoint) {
        this.isMobile = true;
      }
    },
    throttleCheckScreenSize() {
      throttle(300, this.checkScreenSize());
    }
  }
};
