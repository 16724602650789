import * as _dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

_dayjs.extend(relativeTime);
_dayjs.extend(isToday);
_dayjs.extend(localizedFormat);

/**
 *
 * @param {[String, _dayjs]} date
 * @returns {_dayjs} dayjs object
 */
export function dayjs(date) {
  if (!date) return _dayjs();
  if (!(date instanceof dayjs)) date = _dayjs(date);
  return date;
}

/**
 *
 * @param {[String, _dayjs]} date
 * @param {String} format
 * @returns {String} Date or N/A
 */
export function date(date, format) {
  if (!date) return 'N/A';
  if (!format || format == 'full') {
    format = format == 'full' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  }
  date = dayjs(date);
  return date.format(format);
}

/**
 *
 * @param {[String, _dayjs]} date
 * @returns {String} Date or N/A
 */
export function dateFromNow(date) {
  if (!date) return '';
  date = dayjs(date);
  return date.fromNow();
}

/**
 * Get the current date and time in MySQL database format ('YYYY-MM-DD HH:mm:ss').
 *
 * @returns {String} Current timestamp in MySQL DB format
 */
export function now() {
  return date(dayjs(), 'YYYY-MM-DD HH:mm:ss');
}

/**
 * Format date as "DD MMM YYYY".
 *
 * @param {[String, _dayjs]} date
 * @returns {String} Date in "DD MMM YYYY" format or "N/A"
 */
export function formatDateAsDayMonthYear(date, format) {
  if (!date) return 'N/A';
  date = dayjs(date);
  format = format === 'full' ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY';
  return date.format(format);
}
