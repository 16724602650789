<template>
  <div class="heroLight">
    <div class="container">
      <div class="grid w-100 pt-20 pb-10">
        <div class="grid-item -col-span-12 -col-span-lg-7">
          <!-- Intentionally used font-weight-bold instead of bold here due to dark text on light background appears less bold than the opposite and this class has weight 700 and bold has 600 -->
          <h1 class="h1 text-secondary font-weight-bold w-100">{{ strapi.Heading }}</h1>
          <p v-if="strapi.Description" class="preamble -no-margin text-left">{{ strapi.Description }}</p>
          <Button v-if="strapi.Button && strapi.Button.Href" :href="strapi.Button.Href" variant="outlined" minWidth="180px" class="mt-8">
            {{ strapi.Button.Label }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroLight',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.heroLight {
  margin-top: $menu-height-desktop;
}

.v-btn {
  border: 2px solid $color-secondary;
  :deep(.v-btn__content) {
    color: $color-secondary;
  }
}

.v-btn:hover {
  background-color: $color-secondary;
  :deep(.v-btn__content) {
    color: $color-white;
  }
}

.image {
  padding: 40px 0 40px;

  @include is-screen-lg() {
    padding: 0 0 80px;
  }
}
</style>
