<template>
  <Form :disabled="loading" :isEditing="true" grid @submit.prevent="apply">
    <label for="financialContact">{{ strapi.FinancialContactNameLabel }}</label>
    <TextField id="financialContact" v-model="name" name="applyForInvoicePayment.name" />
    <label for="financialContactEmail">{{ strapi.FinancialContactEmailLabel }}</label>
    <EmailTextField id="financialContactEmail" v-model="email" name="applyForInvoicePayment.email" />
    <label for="requestedCreditLimit">{{ strapi.RequestedCreditLimitLabel }}</label>
    <Select id="requestedCreditLimit" v-model="selectedCreditLimit" name="applyForInvoicePayment.creditLimit" :items="$controller.data.creditLimitOptions" />
    <label for="paymentTerms">{{ strapi.PaymentTermsLabel }}</label>
    <Select id="paymentTerms" v-model="selectedPaymentTerm" name="applyForInvoicePayment.paymentTerm" :items="$controller.data.paymentTermOptions" />
    <template #actions>
      <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
      <footer class="d-flex" :class="{ 'flex-column': isMobile }">
        <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" color="secondary" variant="outlined" :disabled="loading" type="submit" :loading="loading">
          {{ strapi.ApplyButtonLabel }}
        </Button>
        <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="closeApplyForInvoicePayment">
          {{ strapi.CancelButtonLabel }}
        </Button>
      </footer>
    </template>
  </Form>
</template>

<script>
export default {
  name: 'ApplyForInvoicePayment',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      name: this.customer.contact_person,
      email: this.customer.finance_email || this.customer.main_contact_email || this.$user.email,
      selectedCreditLimit: null,
      selectedPaymentTerm: null
    };
  },
  created() {
    this.triggerAccountIsEditing(true);
  },
  beforeUnmount() {
    this.triggerAccountIsEditing(false);
  },
  methods: {
    async apply() {
      this.loading = true;
      this.response = await this.post('apply-for-invoice-payment/', {
        applyForInvoicePayment: {
          name: this.name,
          email: this.email,
          creditLimit: this.selectedCreditLimit,
          paymentTerm: this.selectedPaymentTerm
        }
      });
      if (!this.response) {
        this.loading = false;
        return;
      }

      this.reloadWithTimeout();
    },
    closeApplyForInvoicePayment() {
      this.$emit('onCloseApplyForInvoicePayment');
    },
    triggerAccountIsEditing(val) {
      this.$mitt.emit('account.updateIsEditing', { component: this.$options.name, isEditing: val });
    }
  }
};
</script>
