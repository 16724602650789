<template>
  <div :class="`${$attrs.class || ''} d-flex flex-column`">
    <slot name="label" class="text-grey-dark mb-1" :for="id" />
    <VSelect v-bind="otherAttrs" :id="id" :items="formattedItems" :error-messages="localErrorMessages" elevation="0" :bg-color="finalBgColor" :class="{ 'no-default-bg-color': !bgColor }" :hide-details="hasErrorMessages ? 'auto' : true" :item-title="itemTitle" :item-value="itemValue" density="compact" persistent-placeholder :rules="rules" variant="solo" @update:modelValue="$emit('update:modelValue', $event)" />
  </div>
</template>

<script>
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'Select', // eslint-disable-line
  mixins: [displayErrorsOnInputFieldsFromRequest],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null
    },
    items: {
      default: () => []
    },
    bgColor: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    itemTitle: {
      type: [String, Function],
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDirty: false
    };
  },
  computed: {
    otherAttrs() {
      const { class: _, ...rest } = this.$attrs;
      return rest;
    },
    formattedItems() {
      if (this.$utils.isObject(this.items) || this.$utils.some(this.items, item => this.$utils.isNotObject(item))) {
        return this.$utils.map(this.items, (value, key) => ({
          id: parseInt(key),
          name: value
        }));
      } else {
        return this.items;
      }
    },
    finalBgColor() {
      return this.bgColor || (this.isDirty ? 'grey-light' : 'grey-background');
    },
    rules() {
      return this.required ? [v => !!v || 'Field is required'] : [];
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isDirty = this.$utils.isNotBlank(newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-select) {
  .v-field {
    box-shadow: none !important;
  }
}

:deep(.v-field--error) {
  outline: 1px solid $color-validation-red;
}
:deep(.no-default-bg-color .v-select--error) {
  background-color: $color-validation-red-light !important;
}
</style>
