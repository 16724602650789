<template>
  <div
    class="container"
    :class="{
      'pb-6 pb-lg-10': strapi.SizeImage === 'Small',
      'pb-8 pb-lg-16': strapi.SizeImage !== 'Small'
    }"
  >
    <Card
      class="overflow-visible"
      :class="{
        // 'bg-lg-secondary': strapi.BackgroundColor === 'Dark',
        // 'bg-lg-transparent': strapi.BackgroundColor === 'None',
        'bg-secondary': strapi.BackgroundColor === 'Dark' && !strapi.ImageOutsideContainer,
        'bg-white': strapi.BackgroundColor === 'Light' && !strapi.ImageOutsideContainer,
        'bg-transparent': strapi.BackgroundColor === 'None' || strapi.ImageOutsideContainer
      }"
    >
      <div class="grid">
        <div
          class="grid-item -col-span-12"
          :class="{
            '-col-span-md-4': strapi.SizeImage === 'Small',
            '-col-span-md-6': strapi.SizeImage !== 'Small',
            'align-right': strapi.PlacementImage === 'Right',
            'y-padding-top': strapi.BackgroundColor !== 'None',
            'x-padding-right': strapi.PlacementImage === 'Right' && strapi.BackgroundColor !== 'None',
            'x-padding-left': strapi.PlacementImage !== 'Right' && strapi.BackgroundColor !== 'None'
          }"
        >
          <Card>
            <figure class="d-flex flex-column">
              <VImg :src="strapi.Image.Image.url" :alt="strapi.Image.Alt" cover class="h-100" :aspect-ratio="imageRatio" />
              <div class="flex justify-space-between w-100">
                <figcaption v-if="strapi?.Image?.Caption" class="font-italic">{{ strapi.Image.Caption }}</figcaption>
              </div>
            </figure>
          </Card>
        </div>
        <div
          ref="textBlockRef"
          class="text-block grid-item -col-span-12 d-flex flex-column"
          :class="{
            '-col-span-md-8': strapi.SizeImage === 'Small',
            '-col-span-md-6 ': strapi.SizeImage !== 'Small',
            'y-padding-bottom': strapi.BackgroundColor !== 'None',
            'x-padding-left': strapi.PlacementImage === 'Right' && strapi.BackgroundColor !== 'None',
            'x-padding-right': strapi.PlacementImage !== 'Right' && strapi.BackgroundColor !== 'None',
            'justify-center': strapi.BackgroundColor === 'None',
            'pr-md-4 pr-lg-10': strapi.PlacementImage === 'Right' && strapi.BackgroundColor === 'None',
            'pl-md-4 pl-lg-10': strapi.PlacementImage !== 'Right' && strapi.BackgroundColor === 'None',
            'h-100': !strapi.ImageOutsideContainer
          }"
        >
          <span
            v-if="strapi.ImageOutsideContainer && strapi.BackgroundColor !== 'None'"
            ref="textBgRef"
            class="text-block-bg rounded-lg"
            :class="{
              'bg-secondary': strapi.BackgroundColor === 'Dark',
              'bg-white': strapi.BackgroundColor === 'Light'
            }"
          />
          <div class="mb-5">
            <h2 class="h1" :class="{ 'text-secondary': strapi.BackgroundColor !== 'Dark', 'text-white': strapi.BackgroundColor === 'Dark' }">{{ strapi.Text.Title }}</h2>
            <p class="paragraph-large" :class="{ 'text-black': strapi.BackgroundColor !== 'Dark', 'text-white': strapi.BackgroundColor === 'Dark' }">{{ strapi.Text.Description }}</p>
          </div>
          <div v-for="link in strapi.Link" :key="link" class="mb-3" :class="{ 'd-flex flex-1-0 align-end': strapi.BackgroundColor !== 'None' }">
            <Button v-if="link.Href" icon="fa-regular fa-arrow-right" iconFirst iconColor="primary" variant="plain" :href="link.Href" :color="strapi.BackgroundColor === 'Dark' ? 'white' : 'black'">{{ link.LinkLabel }}</Button>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { throttle } from 'throttle-debounce';

export default {
  name: 'ImageText',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    imageRatio() {
      return this.strapi.ImageOutsideContainer ? 7 / 9 : 1 / 1;
    }
  },
  mounted() {
    this.setBgHeight();
    window.addEventListener('resize', this.throttledEventListener);
  },
  unmounted() {
    window.removeEventListener(this.throttledEventListener);
  },
  methods: {
    setBgHeight() {
      if (!this.$refs.textBgRef) return;
      let bgHeight = 0;
      if (window.innerWidth > 959) {
        bgHeight = `${this.$refs.textBlockRef.clientHeight}px`;
      } else {
        bgHeight = '100%';
      }
      this.$refs.textBgRef.style.height = bgHeight;
    },
    throttledEventListener() {
      throttle(300, this.setBgHeight());
    }
  }
};
</script>

<style lang="scss" scoped>
.align-right {
  @include is-screen-md() {
    order: 2;
  }
}

.text-block-bg {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -1;
  left: 0;
  top: 0;
}
.y-padding-top {
  padding-top: 24px;

  @include is-screen-md() {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include is-screen-lg() {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.y-padding-bottom {
  padding-bottom: 24px;

  @include is-screen-md() {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @include is-screen-lg() {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
.x-padding-right {
  padding-right: 24px;
  padding-left: 24px;

  @include is-screen-md() {
    padding-right: 40px;
    padding-left: 8px;
  }

  @include is-screen-lg() {
    padding-right: 80px;
    padding-left: 20px;
  }
}

.x-padding-left {
  padding-left: 24px;
  padding-right: 24px;

  @include is-screen-md() {
    padding-left: 40px;
    padding-right: 8px;
  }

  @include is-screen-lg() {
    padding-left: 80px;
    padding-right: 20px;
  }
}
</style>
