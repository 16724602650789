<template>
  <div v-if="$isNotEmpty(items)" class="container pb-35">
    <div class="w-100 d-flex flex-column flex-sm-row align-start align-sm-center justify-sm-space-between flex-wrap">
      <h3 v-if="strapi.Heading" class="mr-3">{{ strapi.Heading }}</h3>
      <Button v-if="strapi.Button && strapi.Button.Href" :href="strapi.Button.Href" class="bg-secondary" color="white" icon="fa-regular fa-chevron-right">
        {{ strapi.Button.Label }}
      </Button>
    </div>
    <SelectedContentSwiper v-if="strapi && items" :items="items" :contentType="strapi.ContentType" />
  </div>
</template>

<script>
import SelectedContentSwiper from '@Components/SelectedContentSwiper.vue';

export default {
  name: 'SelectedContent',
  components: { SelectedContentSwiper },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      items: null
    };
  },
  contentType() {
    return this.strapi.ContentType;
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      const type = this.strapi.ContentType.toLowerCase().replace(/\s/g, '-');
      const response = await this.get(`/api/content-type/${type}/`, { limit: this.strapi.Limit });
      if (!response) return;
      this.items = response.items;
    }
  }
};
</script>

<style lang="scss" scoped></style>
