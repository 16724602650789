<template>
  <TextField :model-value="modelValue" type="url" @blur="formatOnBlur" @update:modelValue="$emit('update:modelValue', $event)">
    <slot />
  </TextField>
</template>

<script>
export default {
  name: 'WebsiteTextField',
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  methods: {
    formatOnBlur() {
      if (this.$utils.isNotBlank(this.modelValue)) {
        this.$emit('update:modelValue', this.format(this.modelValue));
      }
    },
    format(url) {
      let formattedUrl = url;

      // Ensure the URL starts with 'https://'
      if (!/^https?:\/\//i.test(formattedUrl)) {
        formattedUrl = `https://${formattedUrl}`;
      }

      // // Ensure the URL has a domain ending, default to '.com'
      // if (!/\.[a-z]{2,}$/.test(formattedUrl)) {
      //   formattedUrl += '.com';
      // }

      return formattedUrl;
    }
  }
};
</script>
