<template>
  <Card adjustToMenu data-menu-bg="true" class="bg-grey-background">
    <template #title>
      <div class="container d-flex align-center pb-10 pb-md-20 pt-md-10 bg-grey-background">
        <div class="d-none d-md-flex bg-primary w-55px h-55px rounded-circle justify-center align-center mr-4">
          <VIcon :icon="strapi.Icon" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ strapi.Title }}</h1>
      </div>
    </template>
    <div class="wrapper bg-grey-background pb-10 pb-md-20">
      <div class="container">
        <Form :disabled="loading" @submit.prevent="create">
          <div class="grid">
            <!-- Type Section -->
            <div class="grid-item -col-span-12">
              <VBtnToggle v-model="ticket.type" rounded="lg" mandatory>
                <Button v-for="ticketType in ticket_types" :key="ticketType.id" :value="ticketType" rounded="lg" name="ticket.type_id" class="text-secondary bold min-w-150px min-w-md-200px">{{ ticketType.name }}</Button>
              </VBtnToggle>
            </div>

            <!-- Subtype Section -->
            <Card class="grid-item -col-span-12 mt-5 mt-md-10" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon icon="fas fa-arrow-turn-down-right" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">{{ strapi.TicketSubTypeTitle }}</h6>
              </header>
              <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
              <RadioGroup v-model="ticket.subType" inline>
                <Radio v-for="subType in ticket.type.sub_types" :key="subType.id" :label="subType.name" :value="subType" class="mr-4" name="ticket.sub_type_id" />
              </RadioGroup>
            </Card>

            <!-- Details Section -->
            <Card class="grid-item -col-span-12 mt-5 mt-md-10" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon icon="far fa-file-lines" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">{{ strapi.TicketDetailsTitle }}</h6>
              </header>
              <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
              <div class="grid">
                <div class="grid-item -col-span-12 -col-span-md-6 d-grid gap-24">
                  <div v-if="isFieldActive('title')">
                    <label for="ticketTitle">{{ formatLabel('title', strapi.TitleLabel) }}</label>
                    <TextField id="ticketTitle" v-model="ticket.title" :placeholder="getPlaceholder('title')" name="ticket.title" />
                  </div>
                  <div v-if="isFieldActive('description')">
                    <label for="ticketDescription">{{ formatLabel('description', strapi.DescriptionLabel) }}</label>
                    <Textarea id="ticketDescription" v-model="ticket.description" :placeholder="getPlaceholder('description')" rows="10" name="ticket.description" flat auto-grow />
                  </div>
                </div>
                <div class="grid-item -col-span-12 -col-span-md-6 d-grid gap-24">
                  <fieldset v-if="isFieldActive('article_id')">
                    <legend>{{ formatLabel('article_id', strapi.SelectFromAllArticleLabel) }}</legend>
                    <RadioGroup id="ticketSelectFromArticleOriginType" v-model="selectFromArticleOrigin" inline>
                      <Radio v-for="selectFromArticleOriginType in selectFromArticleOriginTypes" :key="selectFromArticleOriginType.id" :label="selectFromArticleOriginType.title" :value="selectFromArticleOriginType" class="mr-2" />
                    </RadioGroup>
                  </fieldset>
                  <div v-if="isFieldActive('article_id')">
                    <label for="ticketArticle">{{ formatLabel('article_id', strapiArticleLabel) }}</label>
                    <SelectArticle v-if="isActiveSelectAllArticles" id="ticketArticle" v-model="ticket.article" placeholder="Find article" name="ticket.article_id" returnObject />
                    <SelectPurchasedArticle v-else-if="isActiveSelectPurchasedArticles" id="ticketArticle" v-model="selectedPurchasedArticle" placeholder="Find purchased article" name="ticket.article_id" returnObject />
                    <TextField v-else-if="isActiveSelectEnterArticleManually" id="ticketArticle" v-model="ticket.manual_article" placeholder="Enter article" name="ticket.article_id" />
                  </div>
                  <div v-for="customField in customFields" :key="customField">
                    <label :for="`ticket${$toPascalCase(customField.column)}`">{{ formatLabel(customField.column, customField.label) }}</label>
                    <TextField :id="`ticket${$toPascalCase(customField.column)}`" v-model="ticket[customField.column]" :name="`ticket.${customField.column}`" :placeholder="getPlaceholder(customField.column)" />
                  </div>
                </div>
              </div>
            </Card>

            <!-- Files Section -->
            <Card class="grid-item -col-span-12 mt-5 mt-md-10" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
              <header class="d-flex">
                <VIcon icon="far fa-file-lines" size="24px" class="mr-3" />
                <h6 class="paragraph-large font-weight-regular">{{ strapi.FilesLabel || 'Files' }}</h6>
              </header>
              <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
              <Dropzone v-model="ticket.s3files" disableDownload endpoint="/account/support/upload-file/" />
            </Card>

            <!-- Create Section -->
            <div class="grid-item -col-span-12 gap-13 justify-self-end">
              <Button class="bg-white mr-3" variant="outlined" color="secondary" :disabled="loading" :href="strapi.CancelButton.Href">{{ strapi.CancelButton.Label }}</Button>
              <Button class="bg-secondary" color="white" :disabled="loading" :loading="loading" type="submit">{{ strapi.CreateTicketButton.Label }}</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </Card>
</template>

<script>
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'SupportRequestNew',
  components: { Dropzone },
  data() {
    const defaultArticleOrigin = { id: 0, title: 'All articles' };
    return {
      loading: false,
      ...this.$controller.data,
      selectFromArticleOriginTypes: [defaultArticleOrigin, { id: 1, title: 'Purchased articles' }, { id: 2, title: 'Enter article manually' }],
      selectFromArticleOrigin: defaultArticleOrigin,
      selectedPurchasedArticle: null
    };
  },
  computed: {
    fields() {
      return this.ticket.subType?.fields || [];
    },
    customFields() {
      return this.$filter(this.fields, field => field.isCustom);
    },
    strapiArticleLabel() {
      if (this.isActiveSelectAllArticles) {
        return this.strapi.SelectFromAllArticleLabel;
      } else if (this.isActiveSelectPurchasedArticles) {
        return this.strapi.SelectFromAllPurchasedArticlesLabel;
      } else if (this.isActiveSelectEnterArticleManually) {
        return this.strapi.EnterArticleManuallyLabel;
      } else {
        return '';
      }
    },
    isActiveSelectAllArticles() {
      return this.selectFromArticleOrigin.id === 0;
    },
    isActiveSelectPurchasedArticles() {
      return this.selectFromArticleOrigin.id === 1;
    },
    isActiveSelectEnterArticleManually() {
      return this.selectFromArticleOrigin.id === 2;
    }
  },
  watch: {
    selectedPurchasedArticle(purchasedArticle) {
      if (!purchasedArticle) return;
      this.ticket.article = purchasedArticle.article;
      this.ticket.article_id = purchasedArticle.article?.id || null;
    },
    selectFromArticleOrigin(val) {
      this.ticket.article = null;
      this.ticket.manual_article = null;
    },
    'ticket.type'(type) {
      this.ticket.type_id = type?.id || null;
      this.ticket.subType = this.$first(type?.sub_types) || null;
    },
    'ticket.subType'(subType) {
      this.ticket.sub_type_id = subType?.id || null;
    },
    'ticket.article'(article) {
      this.ticket.article_id = article?.id || null;
    }
  },
  methods: {
    formatLabel(column, label) {
      return this.isMandatory(column) ? `* ${label}` : label;
    },
    isFieldActive(column) {
      return this.$contains(this.fields, field => field.column === column);
    },
    getPlaceholder(column) {
      return this.$find(this.fields, field => field.column === column)?.placeholder || '';
    },
    isMandatory(column) {
      return this.$find(this.fields, field => field.column === column)?.isMandatory || false;
    },
    async create() {
      // console.log(this.ticket);
      this.loading = true;
      const response = await this.post('/account/support/', { ticket: this.ticket });
      // console.log(response);
      if (!response) {
        this.loading = false;
        return;
      }
      this.$snackbar.success('Successfully created ticket');
      this.redirectWithTimeout(response.ticket.route.show);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-btn-toggle) {
  background-color: $color-grey-light;
  border: 2px solid darken($color-grey-light, 2%);

  &:hover {
    background-color: darken($color-grey-light, 2%) !important;
  }

  .v-btn {
    background-color: transparent;

    &.v-btn--active {
      background-color: $color-white;
    }

    .v-btn__overlay {
      background-color: transparent !important;
      opacity: 0 !important;
    }
  }
}
</style>
