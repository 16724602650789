<template>
  <div class="rounded-lg container">
    <GoogleMap api-key="AIzaSyAFSJPs65kWIXLNebHmPVcXHNjKqEbEFJk" :fullscreenControl="false" :streetViewControl="false" :mapId="props.strapi.MapId ?? '174936736fbf44db'" :mapTypeControl="false" style="width: 100%; height: 500px; border-radius: 8px" :center="{ lat: Number(props.strapi.MapCenterCoordinates.Latitude), lng: Number(props.strapi.MapCenterCoordinates.Longitude) }" :zoom="Number(props.strapi.StartZoom) ?? 3">
      <Marker v-for="marker in props.strapi.Markers" :key="marker.id" :options="{ position: { lat: Number(marker.Latitude), lng: Number(marker.Longitude) }, title: marker.Title, label: marker.Label }" />
    </GoogleMap>
  </div>
</template>
<script setup>
import { GoogleMap, Marker } from 'vue3-google-map';

const props = defineProps({
  strapi: {
    type: Object,
    default: () => {}
  }
});
</script>
<style lang="scss" scoped>
:deep(.mapdiv) {
  border-radius: 8px;
}
</style>
