<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12">
        <Dropzone v-model="files" :strapi="strapi.Dropzone" :allowedExtensions="['pdf']" :maxFiles="50" />
      </div>
    </div>
  </Card>
</template>

<script>
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'SalesTaxExemption',
  components: { Dropzone },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      files: []
    };
  },
  watch: {
    files: {
      handler(newFiles) {
        console.log(newFiles);
        this.$emit('update:modelValue', newFiles);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getFilesInFormData() {
      const formData = new FormData();
      this.$utils.each(this.files, (file, index) => {
        console.log(file);
        formData.append(`file${index}`, file.raw); // Append the raw file to the FormData
      });
      console.log(formData);
      return formData;
    }
  }
};
</script>

<style lang="scss" scoped></style>
