<template>
  <div>
    <h2 class="h2 mb-14">{{ modelValue.label }}</h2>
    <ExpansionPanels v-show="!loading" v-model="panel">
      <Faq v-for="(faq, index) in paginatedFAQs" :key="index" :faq="faq" />
    </ExpansionPanels>
    <Pagination :model-value="paginationPage" :length="paginationPages" :total-visible="6" class="mt-14" @update:modelValue="onPaginate" />
  </div>
</template>

<script>
import Pagination from '@Components/globals/Pagination.vue';
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import Faq from '@Components/Faq.vue';

export default {
  name: 'Faqs',
  components: { Pagination, ExpansionPanels, Faq },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:loading'],
  data() {
    return {
      panel: null,
      itemPerPage: 10,
      paginationPage: 1
    };
  },
  computed: {
    paginatedFAQs() {
      const startIndex = (this.paginationPage - 1) * this.itemPerPage;
      const endIndex = startIndex + this.itemPerPage;
      return this.modelValue.faqs?.slice(startIndex, endIndex) || [];
    },
    totalFAQs() {
      return this.modelValue.faqs?.length || 0;
    },
    paginationPages() {
      return Math.max(Math.ceil(this.totalFAQs / this.itemPerPage), 0);
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal === undefined) {
          this.paginationPage = parseInt(this.getURLParameter('page')) || 1;
        } else if (newVal) {
          this.paginationPage = 1;
        }

        if (newVal) {
          this.updateURLParameter('page', this.paginationPage);
        }
      }
    }
  },
  methods: {
    onPaginate(newPage) {
      this.panel = null;
      this.$emit('update:loading', true);
      setTimeout(() => {
        this.paginationPage = newPage;
        this.updateURLParameter('page', newPage);
        this.$emit('update:loading', false);
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped></style>
