<template>
  <div />
</template>

<script>
export default {
  name: 'ProductCategory',
  data() {
    return {
      ...this.$controller.data
    };
  }
};
</script>
