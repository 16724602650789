<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex flex-column">
      <div class="d-flex">
        <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
        <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
      </div>
    </header>
    <ExpansionPanels>
      <User v-for="user in users" :key="user.id" :user="user" :strapi="strapi" />
    </ExpansionPanels>
    <AddUser />
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import AddUser from './AddUser.vue';
import User from './User.vue';

export default {
  name: 'Users',
  components: { ExpansionPanels, AddUser, User },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      users: { ...this.modelValue }
    };
  }
};
</script>

<style lang="scss" scoped></style>
