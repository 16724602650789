<script setup>
import Button from "@/components/globals/Button.vue";
import { useClipboard } from '@vueuse/core'
import {ref} from "vue";
import {isNotBlank} from "@/utils/default";

const props = defineProps({
  strapi: {
    type: Object,
    required: true,
    default: () => {}
  },
  title: {
    type: String,
    required: false,
    default: () => {}
  }
});

const currentUrl = ref(window.location.href);
const { copy } = useClipboard({ currentUrl });

const mailBody = ref(encodeURIComponent(sprintf(props.strapi?.MailBody, currentUrl.value)));

const mailSubject = ref();

if (isNotBlank(props.title)) {
  mailSubject.value = encodeURIComponent(sprintf(props.strapi?.MailSubject, props.title));
} else {
  mailSubject.value = encodeURIComponent(sprintf(props.strapi?.MailSubject, '').trim());
}
</script>

<template>
  <div class="d-flex flex-wrap ga-4 mt-6">
    <Button :href="`https://www.linkedin.com/shareArticle?url=${currentUrl}`" target="_blank" rel="noopener noreferrer" class="flex-grow-1 flex-md-grow-0" variant="outlined" :icon="props.strapi?.LinkedInIcon" color="#0072B1" iconSize="small">
      {{ props.strapi?.LinkedInLabel}}
    </Button>
    <Button class="flex-grow-1 flex-md-grow-0" variant="outlined" :icon="props.strapi?.MailIcon" :href="`mailto:?subject=${mailSubject}&body=${mailBody}`" color="accent">
      {{ props.strapi?.MailLabel }}
    </Button>
    <Button class="flex-grow-1 flex-md-grow-0" variant="outlined" color="secondary" :icon="props.strapi?.CopyIcon" @click="copy(currentUrl)" >
      {{ props.strapi?.CopyLabel }}
    </Button>
  </div>
</template>
