<template>
  <div class="d-flex flex-column pt-lg-10 bg-white w-100">
    <Swiper
      :id="photoswipeId"
      :modules="modules"
      :slides-per-view="1"
      :space-between="12"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }"
      class="mt-20 ml-0 mr-0 mySwiper2"
      :thumbs="{ swiper: thumbsSwiper, clickable: true }"
      :style="{ overflow: 'hidden' }"
      :mousewheel="{ forceToAxis: true, thresholdDelta: 20 }"
      :loop="images.length > 1"
    >
      <SwiperSlide v-for="(image, index) in images" :key="image" class="h-auto d-flex justify-center align-center">
        <a :key="index" :href="image.src" target="_blank" rel="noreferrer" :data-pswp-width="5000" :data-pswp-height="5000" @mouseover="handleMouseOver">
          <VImg :src="image.src" class="h-290px w-290px max-w-290px h-lg-390px w-lg-390px max-w-lg-390px" eager />
        </a>
      </SwiperSlide>
      <div class="swiper-button-next">
        <VIcon icon="fa-solid fa-arrow-right" color="black" class="align-self-center swiper-button" size="24" />
      </div>
      <div class="swiper-button-prev">
        <VIcon icon="fa-solid fa-arrow-left" color="black" class="align-self-center swiper-button" size="24" />
      </div>
    </Swiper>
    <Swiper v-if="images.length > 1" :spaceBetween="10" :slidesPerView="3" :freeMode="true" watchSlidesProgress :modules="modules" class="mySwiper h-100px w-100 d-flex mt-5 mt-lg-10" @swiper="setThumbsSwiper">
      <SwiperSlide v-for="image in images" :key="image" class="d-flex w-50px h-50px">
        <VImg :src="image.src" class="w-50px h-50px" eager />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Mousewheel, FreeMode, Thumbs } from 'swiper/modules';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

export default {
  name: 'ProductImageSwiperComponent',
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modules: [Navigation, Mousewheel, FreeMode, Thumbs],
      thumbsSwiper: null,
      photoswipeId: 'photoswipe',
      imageWidth: window.innerWidth, // or some other dynamic calculation
      imageHeight: window.innerHeight // or some other dynamic calculation
    };
  },
  mounted() {
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: `#${this.photoswipeId}`,
        children: 'a',
        secondaryZoomLevel: 0.5,
        pswpModule: () => import('photoswipe')
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {
    handleMouseOver(event) {
      event.preventDefault();
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    }
  }
};
</script>

<style lang="scss">
.pswp img {
  max-width: none;
  height: auto !important;
}
</style>

<style lang="scss" scoped>
@import 'photoswipe/style.css';

:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/navigation';
  @import 'swiper/scss/free-mode';
  @import 'swiper/scss/thumbs';
}

.swiper-button-prev,
.swiper-button-next {
  &:after {
    content: '';
  }
}

.mySwiper {
  :deep(.swiper-wrapper) {
    justify-content: center;
  }
  .swiper-slide {
    cursor: pointer;
    opacity: 0.6;
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
}
</style>
