<template>
  <Swiper
    :modules="modules"
    :slides-per-view="1.2"
    :space-between="12"
    class="mt-20 w-100"
    :pagination="{ el: '.swiper-pagination', type: 'bullets', clickable: true }"
    :autoplay="{ delay: 2500, disableOnInteraction: true }"
    :breakpoints="{
      600: { spaceBetween: 24, slidesPerView: 2.3 },
      960: { spaceBetween: 30, slidesPerView: 3.3 },
      1264: { spaceBetween: 36, slidesPerView: 4.3 },
      1904: { spaceBetween: 48, slidesPerView: 5.3 }
    }"
    :style="{ overflow: 'hidden' }"
  >
    <SwiperSlide v-for="item in items" :key="item.id" class="h-auto">
      <ProductCategoryItem :item="item" class="grid" />
    </SwiperSlide>
    <VContainer>
      <VRow align="center" justify="center">
        <VCol class="swiper-pagination w-200px h-2px mt-10 mb-10 mb-md-20" cols="12" md="8" lg="4" />
      </VRow>
    </VContainer>
  </Swiper>
</template>

<script>
import ProductCategoryItem from '@Components/ProductCategoryItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper/modules';

export default {
  name: 'ProductCategoriesSwiper',
  components: { ProductCategoryItem, Swiper, SwiperSlide },
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modules: [Pagination, Autoplay]
    };
  }
};
</script>

<style lang="scss" scoped>
:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/autoplay';
}

.swiper-pagination {
  display: inline-flex;

  :global(.swiper-pagination-bullet) {
    display: flex;
    height: 2px;
    background: $color-grey;
    opacity: 0.4;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    transition: flex-grow 0.4s ease-in-out;
  }

  :global(.swiper-pagination-bullet-active) {
    display: flex;
    height: 2px;
    background: $color-grey;
    flex-grow: 3;
    opacity: 1;
    justify-content: center;
    align-items: center;
  }
}
</style>
