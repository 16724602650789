<template>
  <PageBuilder v-if="$utils.isNotEmpty(strapi.related.Modules)" :modules="strapi.related.Modules" />
</template>

<script>
export default {
  name: 'ProductCategoryShow',
  data() {
    return { loading: false, ...this.$controller.data };
  }
};
</script>
