<template>
  <Card class="pt-10 px-10 pb-14 mb-5 mb-lg-10">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="paragraph" for="businessDescriptionActivityDescription">{{ strapi.ActivityDescriptionLabel }}</label>
        <Textarea id="businessDescriptionActivityDescription" v-model="customer.activity_description" name="customer.activity_description" :placeholder="strapi.ActivityDescriptionPlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="paragraph" for="businessDescriptionMainField">{{ strapi.MainFieldLabel }}</label>
        <SelectCustomerCategory
          id="businessDescriptionMainField"
          :key="customerInvoiceAddress.country_id"
          v-model="category"
          :name="customerCategoryHasChildren ? null : 'customer.category_id'"
          :placeholder="strapi.MainFieldPlaceholder"
          returnObject
          :queryBuilder="
            qb =>
              qb
                .where('only_parents', true)
                .where('for_country', customerInvoiceAddress.country_id || $country.id)
                .with('children')
          "
        />
        <Select v-if="customerCategoryHasChildren" id="businessDescriptionChildMainField" :key="category.id" :items="category.children" class="mt-5" :name="customerCategoryHasChildren ? 'customer.category_id' : null" @update:modelValue="onSelectChildCategory" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="paragraph" for="businessDescriptionPrimaryMarket">{{ strapi.PrimaryMarketLabel }}</label>
        <RadioGroup v-model="customer.primary_market" name="customer.primary_market">
          <Radio v-for="(label, index) in customerPrimaryMarketOptions" :key="index" :label="label" :value="parseInt(index)" />
        </RadioGroup>
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'BusinessDescription',
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    customerPrimaryMarketOptions: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    customerInvoiceAddress: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customer: { ...this.modelValue },
      category: null
    };
  },
  computed: {
    customerCategoryHasChildren() {
      return this.$utils.isNotEmpty(this.category?.children);
    }
  },
  watch: {
    category(newCategory, oldCategory) {
      if (this.customerCategoryHasChildren) {
        this.customer.category_id = null;
      } else {
        this.customer.category_id = newCategory?.id || null;
      }
    },
    customer: {
      handler() {
        this.$emit('update:modelValue', { ...this.modelValue, ...this.customer });
      },
      deep: true
    }
  },
  methods: {
    onSelectChildCategory(val) {
      this.customer.category_id = val;
    }
  }
};
</script>

<style lang="scss" scoped></style>
