<template>
  <Card class="grid-item -col-span-12 py-5 px-10">
    <ExpansionPanels>
      <ExpansionPanel>
        <template #title>
          <div class="d-flex">
            <VIcon :icon="strapi.AddCommentIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular -no-margin">{{ strapi.AddCommentTitle }}</h6>
          </div>
        </template>
        <VExpansionPanelText>
          <Form :disabled="loading" @submit.prevent="create">
            <div class="grid">
              <div class="grid-item -col-span-12 -col-span-lg-6">
                <label for="addCommentBody">{{ strapi.CommentLabel }}</label>
                <Textarea id="addCommentBody" v-model="comment.body" rows="10" name="comment.body" :placeholder="strapi.CommentPlaceholder" flat auto-grow />
              </div>
              <div class="grid-item -col-span-12 -col-span-lg-6">
                <label for="addCommentFiles">Add files</label>
                <Dropzone id="addCommentFiles" v-model="comment.files" name="comment.files" endpoint="/account/support/upload-file/" :maxFiles="25" />
              </div>
              <div class="grid-item -col-span-12 justify-self-end">
                <Button class="bg-secondary" color="white" :disabled="loading" :loading="loading" type="submit">Add comment</Button>
              </div>
            </div>
          </Form>
        </VExpansionPanelText>
      </ExpansionPanel>
    </ExpansionPanels>
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import ExpansionPanel from '@Components/ExpansionPanel.vue';
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'AddComment',
  components: { ExpansionPanels, ExpansionPanel, Dropzone },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  emits: ['onCommentCreated'],
  data() {
    return {
      loading: false,
      comment: {
        body: '',
        files: []
      }
    };
  },
  methods: {
    async create() {
      this.loading = true;
      const response = await this.post('comment/', { comment: this.comment });
      if (!response) {
        this.loading = false;
        return;
      }
      this.$emit('onCommentCreated');
      this.$snackbar.success('Successfully added comment');
      this.reloadWithTimeout();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:last-child::before,
.v-expansion-panel:last-child::after {
  display: none;
}
</style>
