import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

export default {
  install: (app, options) => {
    app.directive('scroll-lock', {
      mounted(el, binding) {
        if (binding.value) {
          disableBodyScroll(el, { reserveScrollBarGap: false });
        }
      },
      updated(el, binding) {
        if (binding.value) {
          disableBodyScroll(el, { reserveScrollBarGap: false });
        } else {
          enableBodyScroll(el);
        }
      },
      unmounted(el) {
        enableBodyScroll(el);
        clearAllBodyScrollLocks();
      }
    });
  }
};
