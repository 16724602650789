import Alert from '@Components/Alert.vue';
import { isObject } from '@Utils/default';

export default {
  install(Vue) {
    Vue.config.globalProperties.$alert = {
      send(message, type, options) {
        Vue.config.globalProperties.$mitt.emit('alert', {
          message: { ...message, text: isObject(message.text) ? '' : message.text },
          type: type,
          options: options
        });
      },
      success(message, options = {}) {
        // Vue.config.globalProperties.$mitt.emit('clearOtherToasts');
        this.send({ title: message?.title ?? 'Success', text: message?.text ?? message }, 'success', options);
      },
      warning(message, options = {}) {
        this.send({ title: message?.title ?? 'Warning', text: message?.text ?? message }, 'warning', { timeOut: 0, ...options });
      },
      info(message, options = {}) {
        this.send({ title: message?.title ?? 'Info', text: message?.text ?? message }, 'info', options);
      },
      error(message, options = {}) {
        this.send({ title: message?.title ?? 'Error', text: message?.text ?? message }, 'error', { timeOut: 0, ...options });
      }
    };

    Vue.component('Alert', Alert);
  }
};
