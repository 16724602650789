<template>
  <PageBuilder v-if="strapi.Contact" :modules="strapi.Contact" />
</template>

<script>
export default {
  name: 'ContactUsIndex',
  data() {
    return { loading: false, ...this.$controller.data };
  }
};
</script>
