<template>
  <div>
    <div class="grid">
      <ContentItem v-for="item in items" :key="item.id" class="grid-item -col-span-12 -col-span-sm-6 -col-span-md-4 h-510px" :model-value="item" contentType="News" />
    </div>
    <Pagination v-model="paginationPage" :length="paginationPages" :total-visible="paginationTotalVisible" @update:modelValue="paginate" />
  </div>
</template>

<script>
import Pagination from './Pagination.vue';
import ContentItem from '@Components/ContentItem.vue';

export default {
  name: 'News',
  components: { Pagination, ContentItem },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    records: {
      type: Number,
      required: true
    },
    paginationTotalVisible: {
      type: Number,
      required: true
    },
    itemsOnPage: {
      type: Number,
      required: true
    },
    previousTab: {
      type: String,
      default: undefined
    }
  },
  emits: ['paginate'],
  data() {
    return {
      paginationPage: 1
    };
  },
  computed: {
    paginationPages() {
      return Math.max(Math.ceil(this.records / this.itemsOnPage), 0);
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.updateURLParameter('page', this.paginationPage);
      }
    }
  },
  created() {
    if (!this.previousTab) {
      this.paginationPage = parseInt(this.getURLParameter('page')) || this.paginationPage;
    } else {
      this.updateURLParameter('page', this.paginationPage);
    }
  },
  methods: {
    paginate() {
      this.$emit('paginate', this.paginationPage);
    }
  }
};
</script>
