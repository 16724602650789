import { toPascalCase } from '@Utils/default';

export default class GlobalComponents {
  static init(Vue) {
    // Require in a component context
    const requireComponent = require.context('.', false, /[\w-]+\.vue$/);
    requireComponent.keys().forEach(fileName => {
      // Get component config
      const componentConfig = requireComponent(fileName);
      // Get PascalCase name of component
      const componentName = toPascalCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''));
      // Register component globally
      Vue.component(componentName, componentConfig.default || componentConfig);
    });
  }
}
