<template>
  <Card class="pa-5 pa-lg-10">
    <VTabs v-model="tab">
      <VTab value="currentOrders" sliderColor="primary" variant="plain" class="px-0" :ripple="false" hideSlider>Current Orders</VTab>
      <VTab value="invoices" sliderColor="primary" variant="plain" class="px-0" :ripple="false" hideSlider>Invoices</VTab>
    </VTabs>
    <VCardText class="px-0 pb-0">
      <VWindow v-model="tab">
        <VWindowItem value="currentOrders">
          <CurrentOrders />
        </VWindowItem>
        <VWindowItem value="invoices">
          <Invoices />
        </VWindowItem>
      </VWindow>
    </VCardText>
  </Card>
</template>

<script>
import CurrentOrders from './CurrentOrders.vue';
import Invoices from './Invoices.vue';

export default {
  name: 'OrderHistory',
  components: { CurrentOrders, Invoices },
  props: {
    // strapi: {
    //   type: Object,
    //   required: true
    // }
  },
  data() {
    return {
      tab: null
    };
  }
};
</script>

<style lang="scss" scoped>
@include max-screen-sm() {
  :deep(.v-slide-group__content) {
    justify-content: center;
  }
}
</style>
