<template>
  <VDialog v-model="dialog" min-width="500px" eager width="auto" persistent>
    <Card class="pa-6">
      <VCardTitle class="d-flex flex-column align-center">
        <h4>Secure Payment By</h4>
        <VImg :src="strapi.Logo.url" :alt="strapi.Logo.alternativeText" width="100%" height="80px" :aspect-ratio="strapi.Logo.width / strapi.Logo.height" />
        <p class="paragraph-small -no-margin mt-5">
          Your payment will be processed by our payment partner
          <a class="ts-link-underline" href="https://www.paypal.com" aria-label="click to open paypals website in another browser tab" target="_blank">PayPal</a>
          when the order is submitted.
        </p>
      </VCardTitle>
      <VCardText>
        <VProgressCircular v-if="loading" :size="75" width="5" color="secondary" bg-color="white" indeterminate class="d-block ma-auto my-10" />
        <div v-else id="paypal-button-container">
          <!--PayPal injects the Payment Element-->
        </div>
        <VDivider class="my-5" />
        <Button class="bg-secondary w-100 mt-2" color="white" :disabled="loading" @click="dialog = false">Close</Button>
      </VCardText>
    </Card>
  </VDialog>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';

export default {
  name: 'PayPalDialog',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  emits: ['onOpen', 'onClose'],
  data() {
    return {
      loading: false,
      dialog: false,
      options: {},
      paypal: null
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.$emit('onOpen');
      } else {
        this.$emit('onClose');
      }
    }
  },
  methods: {
    async init(options) {
      this.options = options;
      await this.initPayPal();
      this.dialog = true;
    },
    async initPayPal() {
      try {
        if (!this.paypal) {
          this.paypal = await loadScript({ 'client-id': this.options.clientId, currency: this.options.currency });
          if (!this.paypal) throw new Error('Could not load PayPal, please reload and try again.');
        }
        await this.renderPayPalButton(); // Render PayPal button after script is loaded
      } catch (error) {
        console.error(error);
        // this.$snackbar.error('Could not initialize payment...');
      }
    },
    renderPayPalButton() {
      if (this.paypal) {
        this.paypal
          .Buttons({
            createOrder: () => this.createOrder(),
            onApprove: data => this.onApprove(data),
            onCancel: data => window.location.replace(`/checkout/paypal/payment/cancel/?ref=${data.orderID}`),
            onError: error => this.onError(error)
          })
          .render('#paypal-button-container');
      }
    },
    async createOrder() {
      try {
        const response = await this.post('/checkout/paypal/create/', { orderId: this.options.orderId });
        return response.data.id;
      } catch (error) {
        throw new Error('Could not initialize payment via PayPal');
      }
    },
    async onApprove(data) {
      this.loading = true;
      try {
        const response = await this.post('/checkout/paypal/capture/', { paypalOrderID: data.orderID });
        this.$redirect(`/checkout/?return=paypal&payment_ref=${response.id}`);
      } catch (error) {
        this.loading = false;
        throw new Error('Could not finalize payment via PayPal');
      }
    },
    async onError(error) {
      // Implement error handling logic here
      console.log(error);
      // Close dialog and show error message
      this.dialog = false;
      this.$snackbar.error('Could not finalize payment via PayPal, please try again or use another payment method.');
      // const response = await this.post('/checkout/paypal/payment/error/', { ref: });
    }
  }
};
</script>

<style lang="scss" scoped></style>
