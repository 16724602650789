<template>
  <Card data-menu-bg="true" adjustToMenu>
    <template #title>
      <div class="container d-flex pt-20 pb-26">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon icon="fas fa-comments-question-check" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ category }}</h1>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-20" :class="{ 'pt-10': isMobile }">
      <div class="container" :class="{ 'pl-0 pr-0': isMobile }">
        <FilterDropDown v-if="isMobile" :strapi="strapi" :data="categories" :class="{ 'ml-10': isMobile }" />
        <div class="grid">
          <div v-if="isNotMobile" class="grid-item -col-span-3">
            <div class="d-flex flex-column mr-10">
              <CategoryLinks :data="FaqCategories" :strapi="strapi" />
            </div>
          </div>
          <div class="grid-item -col-span-9" :class="{ '-col-span-12': isMobile }">
            <ExpansionPanels>
              <ExpansionPanel v-for="faq in faqs" :key="faq.id" :title="faq.title">
                <div class="grid">
                  <div class="grid-item -col-span-6" :class="{ '-col-span-12': isMobile }">
                    <span class="text-grey-dark">{{ strapi.QuestionLabel }}</span>
                    <p v-markdown="faq.question" class="font-weight-medium" />
                    <a :href="faq.route.publicShow" aria-label="Url for that FAQ" class="underline-primary d-block">{{ strapi.PermanentLinkText }}</a>
                  </div>
                  <div class="grid-item -col-span-6">
                    <span class="text-grey-dark">{{ strapi.SolutionLabel }}</span>
                    <p v-markdown="faq.solution" />
                  </div>
                </div>
              </ExpansionPanel>
            </ExpansionPanels>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import ExpansionPanel from '@Components/ExpansionPanel.vue';
import CategoryLinks from './components/CategoryLinks.vue';
import FilterDropDown from './components/FilterDropDown.vue';

export default {
  name: 'CategoryIndex',
  components: { ExpansionPanels, ExpansionPanel, CategoryLinks, FilterDropDown },
  data() {
    return {
      loading: false,
      ...this.$controller.data
    };
  }
};
</script>

<style lang="scss" scoped></style>
