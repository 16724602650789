<template>
  <div>
    <div class="shop-cart d-flex flex-column" :class="{ visible: open }">
      <CartTopbar />
      <CartEmpty v-if="!hasItems" />
      <CartContent v-if="hasItems" :scrollLock="open" :cartItems="items" />
      <CartSummary v-if="hasItems" :disabled="!isCheckoutAllowed" :totalSum="totalPrice" />
    </div>
    <div role="button" aria-label="close shopping cart" class="shop-cart-tint d-block" :class="{ visible: open }" @click="closeCart()" />
  </div>
</template>

<script>
import CartTopbar from '@Components/CartTopbar.vue';
import CartContent from '@Components/CartContent.vue';
import CartEmpty from '@Components/CartEmpty.vue';
import CartSummary from '@Components/CartSummary.vue';
import { useLoginStore } from '@Store/login.js';
import { debounce } from '@Utils/default';

export default {
  name: 'Cart',
  components: { CartTopbar, CartContent, CartEmpty, CartSummary },
  data() {
    return {
      open: false,
      items: [],
      loggedIn: useLoginStore(),
      totalPrice: 0
    };
  },
  computed: {
    hasItems() {
      return this.items.length > 0;
    },
    isCheckoutAllowed() {
      if (this.items.length === this.items.filter(item => !item.isAllowedForCheckout).length) {
        return false;
      }
      return true;
    }
  },
  created() {
    if (this.loggedIn.isLoggedIn) {
      this.getCart();
    }
    this.$mitt.on('cart:open', () => {
      this.openCart();
    });
    this.$mitt.on('cart:close', () => {
      this.closeCart();
    });
    this.$mitt.on('cart:toggle', () => {
      this.toggleCart();
    });
    this.$mitt.on('cart:add', args => {
      this.addToCart(args.partNumber, args.variantCode, args.quantity, args.snackbar || false);
    });
    this.$mitt.on('cart:remove', article => {
      this.removeCartItem(article.partNumber, article.variantCode);
    });
    this.$mitt.on('cart:manuallyUpdatedQuantity', args => {
      this.pushCartItemDebounce(args.partNumber, args.variantCode, args.quantity, args.snackbar || false);
    });
    this.$mitt.on('cart:increaseQuantity', article => {
      this.increaseQuantity(article.partNumber, article.variantCode);
    });
    this.$mitt.on('cart:decreaseQuantity', article => {
      this.decreaseQuantity(article.partNumber, article.variantCode);
    });
  },
  methods: {
    pushCartItemDebounce: debounce(async function (part_number, variant_code, quantity, snackbar) {
      await this.pushCartItem(part_number, variant_code, quantity, snackbar);
    }, 500),

    async pushCartItem(part_number, variant_code, quantity, snackbar) {
      const response = await this.post('/cart/edit/', {
        part_number: part_number,
        variant_code: variant_code,
        quantity: quantity
      });
      if (!response) return;
      this.items = response.cart.cartItems;
      this.totalPrice = response.cart.totalPrice;
      this.$mitt.emit('cart:updated');
      this.emitCartCount();
      if (snackbar) {
        this.$snackbar.success('Product successfully added to cart');
      }
    },

    closeCart() {
      this.open = false;
    },
    openCart() {
      this.open = true;
    },
    toggleCart() {
      this.open = !this.open;
    },
    addToCart(partNumber, variantCode, quantity, snackbar = false, debounce = true) {
      const pushFnc = debounce ? this.pushCartItemDebounce : this.pushCartItem;

      if (this.$isNotLoggedIn) {
        this.$mitt.emit('login:toggle');
        return;
      }
      const existingCartItem = this.findCartItem(partNumber, variantCode);
      if (existingCartItem) {
        existingCartItem.quantity += quantity;
        pushFnc(existingCartItem.article.part_number, existingCartItem.articleVariant.code, existingCartItem.quantity, snackbar);
      } else {
        //this.items.push({ quantity: quantity });
        pushFnc(partNumber, variantCode, quantity, snackbar);
      }
    },
    async increaseQuantity(partNumber, variantCode, snackbar = false) {
      const currentCartItem = this.findCartItem(partNumber, variantCode);
      if (currentCartItem) {
        currentCartItem.quantity++;
        await this.pushCartItemDebounce(currentCartItem.article.part_number, currentCartItem.articleVariant.code, currentCartItem.quantity, snackbar);
      }
    },
    async decreaseQuantity(partNumber, variantCode, snackbar = false) {
      const currentCartItem = this.findCartItem(partNumber, variantCode);
      if (currentCartItem && currentCartItem.quantity > 1) {
        currentCartItem.quantity--;
        await this.pushCartItemDebounce(currentCartItem.article.part_number, currentCartItem.articleVariant.code, currentCartItem.quantity, snackbar);
      }
    },
    findCartItem(partNumber, variantCode) {
      return this.items.find(item => item.article.part_number === partNumber && item.articleVariant.code === variantCode);
    },
    emitCartCount() {
      let quantity = 0;
      this.items.forEach(item => {
        if (item.messages?.find(message => message.type === 'error')) {
          return;
        }
        quantity += item.quantity;
      });
      this.$mitt.emit('cart:count', quantity);
    },
    async removeCartItem(partNumber, variantCode) {
      const itemsCopy = JSON.stringify(this.items);
      this.items = this.items.filter(item => item.article.part_number !== partNumber || item.articleVariant.code !== variantCode);
      const response = await this.delete('/cart/delete/', {
        part_number: partNumber,
        variant_code: variantCode
      });
      if (!response) {
        this.items = JSON.parse(itemsCopy);
      }
      this.items = response.cart.cartItems;
      this.totalPrice = response.cart.totalPrice;
      this.emitCartCount();
    },
    async getCart() {
      const response = await this.get('/cart/');
      if (!response) {
        return;
      }
      this.items = response.cart.cartItems;
      this.totalPrice = response.cart.totalPrice;
      this.emitCartCount();
    }
  }
};
</script>

<style lang="scss" scoped>
.shop-cart {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  z-index: 11;
  background-color: $color-white;
  transform: translateX(100%);
  transition: transform 0.6s ease;

  @include is-screen-md {
    max-width: 415px;
  }

  &.visible {
    transform: translateX(0);
  }
}

.shop-cart-tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  z-index: 10;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  transform: translateX(100%);
  transition:
    transform 0s ease 0.2s,
    opacity 0.2s ease-in-out;

  &.visible {
    transform: translateX(0);
    opacity: 1;
    transition:
      transform 0s ease 0.2s,
      opacity 0.2s ease-in-out 0.2s;
  }
}
</style>
