<template>
  <Card class="pa-10" :class="{ 'pl-5 pr-5': isMobile }">
    <div class="d-flex justify-space-between" :class="{ 'flex-column': isMobile }">
      <div class="d-flex">
        <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
        <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
      </div>
      <Button class="paragraph rounded-lg py-2 d-flex justify-center bg-secondary" :class="{ 'px-5 w-300px': isMobile, 'px-15': !isMobile }" color="white" :href="strapi.CreateRMAButtonHref">
        <VIcon :icon="strapi.CreateRMAButtonIcon" size="20px" class="mr-1" />
        {{ strapi.CreateRMAButtonLabel }}
      </Button>
    </div>
    <VCardText class="px-0 pb-0 position-relative">
      <VDataTableServer hover v-bind="options" :loading="loading" @update:options="loadRmas" @click:row="onRowClick">
        <template #item.number="{ item }">
          <span class="bold">{{ `${item.number}` }}</span>
        </template>
        <template #tfoot>
          <tr>
            <td>
              <TextField v-model="query" type="search" placeholder="Search" class="position-md-absolute w-auto min-w-md-275px min-w-lg-350px bottom-5px" />
            </td>
          </tr>
        </template>
      </VDataTableServer>
    </VCardText>
  </Card>
</template>

<script>
import { debounce } from '@Utils/default';

export default {
  name: 'RMARequest',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      query: '',
      options: {
        search: '',
        itemsLength: 0,
        headers: [
          {
            title: this.strapi.NumberLabel,
            sortable: true,
            key: 'number'
          },
          {
            title: this.strapi.AuthorLabel,
            sortable: true,
            key: 'author'
          },
          {
            title: this.strapi.StatusLabel,
            sortable: false,
            key: 'customer_status',
            value: item => this.statusName(item.customer_status)
          },
          {
            title: this.strapi.UpdatedAtLabel,
            sortable: true,
            key: 'updated_at',
            value: item => this.$utils.formatDateAsDayMonthYear(item.updated_at, 'full')
          },
          {
            title: this.strapi.CreatedAtLabel,
            sortable: true,
            key: 'created_at',
            value: item => this.$utils.formatDateAsDayMonthYear(item.created_at, 'full')
          }
        ]
      }
    };
  },
  watch: {
    query: debounce(function (query) {
      this.options.search = query;
    }, 500)
  },
  methods: {
    async loadRmas(payload) {
      // console.log('Payload: ', payload);
      this.loading = true;
      const response = await this.get('/account/rma/listRMARequests/', payload);
      this.loading = false;
      if (!response) return;
      // console.log('Response:', response);
      this.options = { ...this.options, ...payload, ...response };
      // console.log('Options:', this.options);
    },
    onRowClick(event, data) {
      // console.log('Redirect...');
      this.$redirect(data.item.route);
    },
    statusName(status) {
      const statusNames = {
        1: this.strapi.CustomerStatus.New,
        2: this.strapi.CustomerStatus.InProgress,
        3: this.strapi.CustomerStatus.Closed
      };

      // console.log(status, ':', statusNames[status]);
      return statusNames[status];
    }
  }
};
</script>

<style lang="scss" scoped></style>
