<template>
  <div class="simple-dropdown ml-2 mr-2">
    <span class="text-grey">{{ label }}</span>
    <div class="wrapper">
      <button class="button ml-2" @click="onToggle">
        <span class="value-label-wrapper">
          <span class="label">{{ getSelectedValueLabel }}</span>
          <div class="arrow ml-auto">
            <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.94023 0.645029L3.00023 2.58503L1.06023 0.645029C0.865234 0.450029 0.550234 0.450029 0.355234 0.645029C0.160234 0.840029 0.160234 1.15503 0.355234 1.35003L2.65023 3.64503C2.84523 3.84003 3.16023 3.84003 3.35523 3.64503L5.65023 1.35003C5.84523 1.15503 5.84523 0.840029 5.65023 0.645029C5.45523 0.455029 5.13523 0.450029 4.94023 0.645029Z" fill="#BBBBBB" />
            </svg>
          </div>
        </span>
        <div v-if="expanded" class="content">
          <button v-for="value in listValues" :id="value.id" :key="value.key" :data-key="value.key" :class="{ '-selected': parseInt(value.id) === parseInt(currentSelection) }" class="select-button" @click="onSelect">{{ value.label }}</button>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleDropDown',
  props: {
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    values: {
      type: Array,
      default: null
    },
    initialSelection: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentSelection: 0,
      listValues: [],
      expanded: false
    };
  },
  computed: {
    getSelectedValueLabel() {
      if (this.listValues) {
        return this.listValues[this.currentSelection].label;
      }
      return 'N/A';
    }
  },
  created() {
    this.currentSelection = this.initialSelection;
    this.values.forEach((value, index) => {
      const obj = {
        label: value.label,
        key: value.key,
        selected: index === this.currentSelection ? true : false,
        id: index
      };
      this.listValues.push(obj);
    });
  },
  methods: {
    onSelect(event) {
      this.currentSelection = event.currentTarget.id;
      this.$emit('onUpdate', {
        id: this.id,
        selectionId: event.currentTarget.id,
        selectionKey: event.currentTarget.dataset.key
      });
    },
    onToggle() {
      this.expanded = this.expanded ? false : true;
    }
  }
};
</script>

<style lang="scss" scoped>
.simple-dropdown {
  display: flex;
}
.wrapper {
  position: relative;
}
.content {
  position: absolute;
  width: 200px;
  display: block;
  background: #fff;
  border: 8px;
  padding: 10px 15px 10px 10px;
  z-index: 2;
  left: -20px;
  top: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.03);
}

.button {
  height: 25px;
}

.value-label-wrapper {
  position: relative;
  display: flex;
  width: 120px;
  overflow: hidden;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 10px);
  text-align: left;
  height: 21px;
  font-weight: 400;
}

.arrow {
  position: relative;
  top: 7px;
}

.select-button {
  display: block;
  width: 100%;
  height: 24px;
  text-align: left;
  color: $color-grey;
  padding: 0 5px;
  border-radius: 4px;

  &:hover {
    background-color: $color-grey-background;
  }

  &.-selected {
    background-color: $color-primary;
    color: $color-white;
  }
}
</style>
