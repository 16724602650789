<template>
  <div v-if="snackbar">
    <VSnackbar :model-value="true" :class="[{ 'slide-in': snackbar.active }, { 'slide-out': !snackbar.active }]" :color="backgroundColor" rounded="large" multi-line location="bottom left" :timeout="snackbar.timeOut" @update:model-value="close">
      <div class="d-flex w-100 w-md-fit-content min-w-md-300px">
        <VIcon v-if="icon" :icon="icon" :color="iconColor" size="x-large" />
        <div class="ml-4">
          <p v-if="snackbar.title" class="paragraph bold -no-margin">{{ snackbar.title }}</p>
          <p v-if="snackbar.body" class="paragraph-small -no-margin" v-html="snackbar.body" />
        </div>
      </div>
      <VBtn icon="fas fa-xmark" variant="plain" :ripple="false" size="x-small" position="absolute" @click="close" />
    </VSnackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    timeOut: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      snackbar: this.$dom.application.snackbar || null
    };
  },
  computed: {
    icon() {
      if (this.snackbar.icon) return this.snackbar.icon;
      switch (this.snackbar.type) {
        case 'success':
          return 'far fa-check-circle';
        case 'error':
          return 'far fa-exclamation-circle';
        case 'warning':
          return 'far fa-exclamation-triangle';
        case 'info':
          return 'far fa-info-circle';
        default:
          return null;
      }
    },
    backgroundColor() {
      return this.snackbar.bgColor || this.snackbar.type || 'primary';
    },
    iconColor() {
      return 'white';
      // return this.snackbar.color || this.snackbar.type || 'white';
    }
  },
  created() {
    this.$mitt.on('snackbar', this.handleMessageEvent);
    if (this.snackbar) {
      this.addSnackbar(this.snackbar);
    }
  },
  methods: {
    handleMessageEvent(payload) {
      if (payload.redirect) {
        this.$redirect(payload.redirect || '/');
        return;
      }

      if (payload.message) {
        this.addSnackbar(payload);
      }
    },
    addSnackbar(payload) {
      if (payload.options.googleEventTrigger) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ...payload.options.googleEventTrigger });
      }
      this.snackbar = { ...payload.message, timeOut: this.timeOut, ...payload.options, active: false };
      setTimeout(() => {
        this.snackbar.active = true;
      }, 200);
    },
    close() {
      this.snackbar.active = false;
      setTimeout(() => {
        this.snackbar = null;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-btn) {
  top: 5px;
  right: 5px;
}

:deep(.v-snackbar__wrapper) {
  padding: 16px;
}
</style>

<style lang="scss">
.v-snackbar {
  transform: translateY(100%);
  transition: transform 1s cubic-bezier(0.2, 0.8, 0.2, 1.2);
}

.slide-in {
  transform: translateY(0);
}

.slide-out {
  transform: translateY(100%);
  transition: transform 0.5s;
}
</style>
