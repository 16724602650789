<template>
  <VDataTableServer hover v-bind="options" :loading="loading" @update:options="fetch" @click:row="onRowClick">
    <template #item.no="{ item }">
      <span class="bold">{{ item.no }}</span>
    </template>
    <template #item.printInvoice="{ item }">
      <a class="underline-primary" :href="item.route.printInvoice" target="_blank" aria-label="download invoice">Download</a>
    </template>
    <template #tfoot>
      <tr>
        <td>
          <TextField v-model="query" type="search" placeholder="Search" class="position-md-absolute w-auto min-w-md-275px min-w-lg-350px bottom-5px" />
        </td>
      </tr>
    </template>
  </VDataTableServer>
</template>

<script>
import { debounce } from '@Utils/default';

export default {
  name: 'Invoices',
  data() {
    return {
      loading: false,
      query: '',
      options: {
        search: '',
        itemsLength: 0,
        itemsPerPage: 25,
        headers: [
          {
            title: 'No.',
            key: 'no'
          },
          {
            title: 'Invoice',
            sortable: false,
            key: 'printInvoice'
          },
          {
            title: 'Order Reference',
            key: 'order_no'
          },
          {
            title: 'Date',
            key: 'created_at',
            value: item => this.$utils.formatDateAsDayMonthYear(item.created_at, 'full')
          }
        ]
      }
    };
  },
  watch: {
    query: debounce(function (query) {
      this.options.search = query;
    }, 500)
  },
  methods: {
    async fetch(payload) {
      this.loading = true;
      const response = await this.get('/account/order-history/invoices/', payload);
      this.loading = false;
      if (!response) return;
      this.options = { ...this.options, ...payload, ...response };
    },
    onRowClick(event, data) {
      this.$redirect(`${data.item.route.printInvoice}`, { newTab: true });
    }
  }
};
</script>

<style lang="scss" scoped></style>
