<template>
  <li class="cart-item w-100 pt-5 pb-3 d-flex flex-row flex-wrap" :class="{ 'flex-column align-center': isMobile }">
    <a :href="cartItem.articleVariant.route.publicShow">
      <VImg class="w-65px h-65px rounded-lg" :class="{ 'mb-2': isMobile }" :src="cartItem.article.primaryImage.src" />
    </a>
    <div class="cart-item-info line-clamp-1" :class="{ 'd-flex flex-column align-center': isMobile, 'pl-5': !isMobile }">
      <a :href="cartItem.articleVariant.route.publicShow" class="paragraph -no-margin bold text-truncate" :class="{ 'w-100': isMobile }">
        {{ cartItem.article.name }}
        <VTooltip location="top" activator="parent">{{ cartItem.article.name }}</VTooltip>
      </a>
      <p class="paragraph-small -no-margin text-grey-dark text-truncate">Art: {{ cartItem.article.part_number }}</p>
      <p class="paragraph-small -no-margin text-grey-dark text-truncate">
        {{ `${cartItem.articleVariant.code} - ${cartItem.articleVariant.vendor_part_number}` }}
        <Tooltip location="bottom" activator="parent">{{ `${cartItem.articleVariant.code} - ${cartItem.articleVariant.vendor_part_number}` }}</Tooltip>
      </p>
      <StockIndicator v-if="cartItem.articleVariant" :estRestockAt="cartItem.articleVariant?.est_restock_at" :inStock="cartItem.articleVariant.inStock" />
      <CartItemMessage v-if="showMessages()" :endOfLife="cartItem.article?.is_end_of_life" :disabled="disabled" :outOfStock="!cartItem.articleVariant?.inStock" :inventoryLimited="cartItem.inventoryLimited" :backOrder="cartItem.backOrder" />
      <div class="d-flex flex-row justify-space-between">
        <p v-if="$isNotInCheckout && cartItem.price" class="paragraph-small -no-margin text-grey-dark">{{ $moneyFormat(cartItem.price) }} /pc</p>
      </div>
    </div>
    <div v-if="$isInCheckout" class="d-flex align-center justify-space-between justify-md-end mt-6 w-100">
      <div class="min-w-md-175px text-end">
        <p class="-no-margin">{{ cartItem.quantity }} pcs</p>
      </div>
      <div class="min-w-md-175px text-end">
        <p v-if="cartItem.price" class="-no-margin">{{ $moneyFormat(cartItem.price) }} /pc</p>
      </div>
      <div class="d-flex justify-end min-w-md-175px">
        <p class="-no-margin mr-3">Total</p>
        <p :class="{ 'hide-element': !cartItem.totalPrice }" class="-no-margin">{{ $moneyFormat(cartItem.totalPrice) }}</p>
      </div>
    </div>
    <div v-else class="d-flex align-center justify-space-between w-100 mt-6">
      <CartQuantityInput :hideControls="hideControls" :partNumber="cartItem.article.part_number" :variant="cartItem.articleVariant.code" :quantity="cartItem.quantity" :loading="loading" @onManuallyUpdatedQuantity="onManuallyUpdatedQuantity" @onDecreaseQuantity="onDecreaseQuantity" @onIncreaseQuantity="onIncreaseQuantity" />
      <p class="flex-grow-1 text-center mr-4 -no-margin">{{ cartStrapi.TotalLabel }}</p>
      <p :class="{ 'hide-element': !cartItem.totalPrice }" class="-no-margin mr-2">{{ $moneyFormat(cartItem.totalPrice) }}</p>
      <Button iconButton class="hover-dark" :class="{ 'hide-element': hideControls }" iconSize="small" icon="fa-regular fa-trash-can" ariaLabel="Remove cart item" :disabled="loading" @click="remove()" />
    </div>
  </li>
</template>

<script>
import CartQuantityInput from '@Components/CartQuantityInput.vue';
import CartItemMessage from '@Components/cart/CartItemMessage.vue';
import StockIndicator from '@Components/StockIndicator.vue';

export default {
  name: 'CartItem',
  components: { CartQuantityInput, CartItemMessage, StockIndicator },
  props: {
    cartItem: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cartStrapi: this.$dom.application.cart,
      disabled: false
    };
  },
  watch: {
    cartItem: function (newValue) {
      this.disabled = !newValue.isAllowedForCheckout;
    }
  },
  mounted() {
    this.disabled = !this.cartItem.isAllowedForCheckout;
  },
  methods: {
    hasError() {
      return this.cartItem.messages?.find(message => message.type === 'error');
    },
    price() {
      let unitPrice = null;
      this.cartItem.articleVariant.prices.forEach(price => {
        if (price.quantity <= this.cartItem.quantity) {
          unitPrice = price.price;
        }
      });
      return unitPrice;
    },
    remove() {
      this.$mitt.emit('cart:remove', { partNumber: this.cartItem.article.part_number, variantCode: this.cartItem.articleVariant.code });
      this.$emit('onRemove', { partNumber: this.cartItem.article.part_number, variantCode: this.cartItem.articleVariant.code });
    },
    onIncreaseQuantity(article) {
      this.$emit('onIncreaseQuantity', { partNumber: article.partNumber, variantCode: article.variantCode });
    },
    onDecreaseQuantity(article) {
      this.$emit('onDecreaseQuantity', { partNumber: article.partNumber, variantCode: article.variantCode });
    },
    onManuallyUpdatedQuantity(article) {
      this.$emit('onManuallyUpdatedQuantity', { partNumber: article.partNumber, variantCode: article.variantCode, quantity: article.quantity });
    },
    showMessages() {
      if (this.cartItem.article?.is_end_of_life || this.cartItem.inventoryLimited || this.cartItem.partialDelivery || !this.cartItem.isAllowedForCheckout || this.cartItem.backOrder || !this.cartItem.articleVariant?.inStock) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cart-item-info {
  width: calc(100% - 65px);
}
.hide-element {
  visibility: hidden;
}
</style>
