<template>
  <ExpansionPanel :title="faq.title" icon="far fa-message-question">
    <div class="grid">
      <div class="grid-item -col-span-12">
        <p class="paragraph text-grey-dark mb-2">{{ strapi.question?.label || 'Question' }}</p>
        <p v-markdown="faq.question || ''" class="paragraph-large font-weight-medium" />
      </div>
      <div class="grid-item -col-span-12">
        <p class="paragraph text-grey-dark mb-2">{{ strapi.solution?.label || 'Solution' }}</p>
        <p v-markdown="faq.solution || ''" class="paragraph -no-margin" />
      </div>
      <div class="grid-item -col-span-12">
        <a :href="faq.route.publicShow" aria-label="Url for a specific FAQ" class="paragraph underline-primary d-block">{{ strapi.permanentLink?.label || 'Permanent Link' }}</a>
      </div>
    </div>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@Components/ExpansionPanel.vue';

export default {
  name: 'Faq',
  components: { ExpansionPanel },
  props: {
    faq: {
      type: Object,
      required: true
    },
    strapi: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped></style>
