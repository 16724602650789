<template>
  <div class="main-wrapper bg-white mt-20 pt-10">
    <div class="container">
      <div class="d-flex flex-row align-center py-30">
        <div class="bg-primary d-flex align-center justify-center rounded-circle px-3 py-3 mr-6">
          <VIcon color="white" icon="fa-light fa-file" size="x-large" />
        </div>
        <h1 class="text-secondary my-0">
          {{ strapi.title }}
          <span class="text-grey">{{ rmaNo }}</span>
        </h1>
      </div>
    </div>
    <div class="wrapper bg-grey-background py-10">
      <div class="container pt-5 pb-5 grid">
        <VCard class="item grid-item -col-span-lg-6 -col-span-12 py-10 px-10">
          <p class="paragraph pb-3">
            <VIcon icon="fal fa-file-lines" class="mr-3" />
            {{ strapi.RMASummaryLabel }}
          </p>
          <div v-for="(item, index) in summary" :key="index" class="grid sunmmary my-0 py-2">
            <p class="grid-item -col-span-lg-5 text-grey -col-span-12 py-0 my-0">{{ item.label }}</p>
            <p v-if="!item.url" class="grid-item -col-span-lg-7 -col-span-12 py-0 my-0">{{ item.value }}</p>
            <a v-if="item.url" :href="item.url" class="underline-primary grid-item -col-span-lg-7 -col-span-12 py-0 my-0">{{ item.value }}</a>
          </div>
        </VCard>
        <VCard class="item grid-item -col-span-lg-6 -col-span-12 py-10 px-10">
          <p class="paragraph pb-3">
            <VIcon icon="fal fa-file-lines" class="mr-3" />
            {{ strapi.DescriptionLabel }}
          </p>
          <p>
            {{ description }}
          </p>
        </VCard>
        <VCard class="item grid-item -col-span-12 py-10 px-10">
          <p class="paragraph pb-3">
            <VIcon icon="fa-light fa-comments" class="mr-3" />
            {{ strapi.CommentsLabel }}
          </p>
          <p class="text-grey">{{ strapi.NoCommentsText }}</p>
        </VCard>
      </div>
      <a class="back paragraph-large underline-primary mx-auto mt-10" href="http://dev.techship.com/rma-request">{{ strapi.BackLabel }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCreateRequest: false,
      loading: false,
      ...this.$controller.data,
      rmaNo: 'R43298',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed eros facilisis, consectetur tortor eu, condimentum ipsum. Nunc a ipsum et justo commodo interdum vel a lectus. ',
      summary: [
        {
          label: 'Number',
          value: 'R43298'
        },
        {
          label: 'Date',
          value: '16 August 2022 - 10:56:15'
        },
        {
          label: 'Status',
          value: 'Closed'
        },
        {
          label: 'Order Reference',
          value: '343224'
        },
        {
          label: 'Techshop Contact',
          value: 'Camilla Cederlund'
        },
        {
          label: 'Article',
          value: '11456 - Cellular Antenna Joystick ',
          url: 'http://'
        },
        {
          label: 'Article Quantity',
          value: 1
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  position: relative;
  transform: translateY(-80px);
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.wrapper {
  display: grid;
}

.item {
  box-shadow: none;
}

.paragraph {
  border-bottom: 1px solid $color-grey-light;
}

.sunmmary {
  gap: 1px;
  @include is-screen-lg() {
    gap: 24px;
  }
}
</style>
