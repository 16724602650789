<template>
  <div>
    <h2 class="-no-margin">Product Details & Tech Facts</h2>
    <div class="grid mt-14">
      <div v-if="$utils.isNotBlank(modelValue.description)" class="grid-item -col-span-12 -col-span-md-4">
        <p class="paragraph-large bold">Description</p>
        <div v-markdown="modelValue.description" />
      </div>
      <div class="grid-item" :class="[modelValue.description ? '-col-span-12 -col-span-md-8' : '-col-span-12']">
        <MasonryWall :items="modelValue.attributes" :ssr-columns="4" :column-width="200" :gap="16">
          <template #default="{ item }">
            <div class="block bg-white pa-3 rounded-lg">
              <p class="paragraph bold mb-1">{{ item.name }}</p>
              <p v-if="item.type === 'boolean'" class="paragraph -no-margin">{{ item.value ? 'Yes' : 'No' }}</p>
              <p v-else-if="item.type === 'range'" class="paragraph -no-margin">{{ `${item.value[0]} - ${item.value[1]}` }} {{ item.unit }}</p>
              <p v-else-if="item.type === 'numeric'" class="paragraph -no-margin">{{ item.value }} {{ item.unit }}</p>
              <div v-else>
                <span v-for="(innerValue, i) in item.value" :key="i" class="d-block">{{ innerValue }} {{ item.unit }}</span>
              </div>
            </div>
          </template>
        </MasonryWall>
      </div>
    </div>
  </div>
</template>

<script>
import MasonryWall from '@yeger/vue-masonry-wall'; // eslint-disable-line

export default {
  name: 'Details', // eslint-disable-line
  components: { MasonryWall },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  watch: {
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.deleteURLParameter('page');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  list-style-position: inside;
}

.list li::marker {
  color: $color-primary;
}
</style>
