import print from 'vue3-print-nb';
import { vfmPlugin } from 'vue-final-modal';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

export default class GlobalPlugins {
  static init(Vue) {
    // Require in a plugin context
    const requirePlugin = require.context('.', false, /[\w]+\.js$/);
    requirePlugin.keys().forEach(fileName => {
      if (fileName == './_globals.js') return;
      // Get plugin
      const plugin = requirePlugin(fileName);
      // Register mixin globally
      Vue.use(plugin.default || plugin);
    });

    this.manuallyRegisterPlugins(Vue);
  }
  static manuallyRegisterPlugins(Vue) {
    Vue.use(print);
    Vue.use(vfmPlugin);
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
  }
}
