<template>
  <div v-scroll-lock="dialog">
    <VDialog v-model="dialog" width="500">
      <template #activator="{ props }">
        <Button :disabled="editingIsDisabled" class="mt-10" color="secondary" v-bind="props" :prependIcon="strapi.AddUserButtonIcon">
          {{ strapi.AddUserButtonLabel }}
        </Button>
      </template>
      <Card class="pa-12">
        <VCardTitle>
          <h4>{{ strapi.DialogTitle }}</h4>
        </VCardTitle>
        <Form :disabled="loading" @submit.prevent="save">
          <VCardText>
            <label for="addUserName">{{ strapi.NameLabel }}</label>
            <TextField id="addUserName" v-model="user.name" autofocus name="user.name" class="mb-4" autocomplete="name" />
            <label for="addUserEmail">{{ strapi.EmailLabel }}</label>
            <EmailTextField id="addUserEmail" v-model="user.email" name="user.email" class="mb-4" />
            <label for="addUserPhone">{{ strapi.PhoneLabel }}</label>
            <PhoneTextField id="addUserPhone" v-model="user.phone" name="user.phone" class="mb-4" />
            <label for="addUserMobile">{{ strapi.MobilePhoneLabel }}</label>
            <PhoneTextField id="addUserMobile" v-model="user.mobile" name="user.mobile" class="mb-4" />
            <label for="addUserPosition">{{ strapi.PositionLabel }}</label>
            <TextField id="addUserPosition" v-model="user.position" name="user.position" />
          </VCardText>
          <VCardActions>
            <Button class="bg-secondary" variant="text" :loading="loading" color="white" type="submit">
              {{ strapi.SaveButtonLabel }}
            </Button>
            <Button variant="outlined" color="secondary" :disabled="loading" @click="cancel">{{ strapi.CancelButtonLabel }}</Button>
          </VCardActions>
        </Form>
      </Card>
    </VDialog>
  </div>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'AddUser',
  mixins: [singleSectionEditingHandling],
  data() {
    return {
      dialog: false,
      loading: false,
      user: {}
    };
  },
  watch: {
    dialog(val) {
      this.user = {};
      this.isEditing = val;
    }
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.post('user/', { user: this.user });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    cancel() {
      this.isEditing = false;
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  border: 3px solid $color-secondary;
  color: $color-secondary;
}
.v-btn:hover {
  background-color: $color-secondary;
  :deep(.v-btn__content) {
    color: $color-white;
  }
}

.v-input {
  height: 47px !important;
}
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -78px;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  display: block;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  &.-visible {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.container-wrapper {
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 15;
  transform: translateY(100%);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out 0.2s;
  &.-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
