<template>
  <VRadioGroup :inline="inline" :hide-details="hideDetails" :error-messages="localErrorMessages" :persistentHint="persistentHint">
    <slot />
  </VRadioGroup>
</template>

<script>
import displayErrorsOnInputFieldsFromRequest from '@Mixins/displayErrorsOnInputFieldsFromRequest';

export default {
  name: 'RadioGroup',
  mixins: [displayErrorsOnInputFieldsFromRequest],
  props: {
    inline: {
      type: Boolean,
      default: true
    },
    persistentHint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hideDetails() {
      return this.hasErrorMessages || this.persistentHint ? 'auto' : true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
