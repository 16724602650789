<template>
  <div class="d-flex align-center">
    <a v-if="$isLoggedIn" href="/account/" aria-label="Go to my account page"><Button iconButton icon="fa-regular fa-user" color="white" iconSize="small" /></a>
    <!-- For not logged in users: Use only user icon on mobile to make everything fit in nav bar -->
    <Button v-else-if="$isNotLoggedIn && isMobile" iconButton icon="fa-regular fa-user" color="white" iconSize="20px" aria-label="Login/Register" @click="loginStore.toggle()" />
    <!-- On desktop there is room for the wide green button  -->
    <Button v-else-if="$isNotLoggedIn && isNotMobile" variant="tonal" color="text-white" size="small" class="bg-accent" height="36" prependIcon="fa-regular fa-user" @click="loginStore.toggle()">Login/Register</Button>
  </div>
</template>

<script>
import { useLoginStore } from '@Store/login';

export default {
  name: 'MyAccountButton',
  data() {
    return {
      loading: false,
      loginStore: useLoginStore()
    };
  }
};
</script>

<style lang="scss" scoped></style>
