import { RequestAbortedError, methods } from '@/request';
import { isNotEmpty } from '@Utils/default';

export default {
  methods: {
    async request(url = '', params = {}, method, cancelToken) {
      const requestMethod = methods[method] || null;
      if (!requestMethod) {
        throw new Error('Invalid method');
      }

      try {
        const response = await requestMethod(url, params, cancelToken);
        if (response.snackbar) {
          this.$snackbar.send(response.snackbar.message, response.snackbar.options, response.snackbar.redirect);
          delete response.snackbar;
        }

        if (response.dialog) {
          this.$dialog.send(response.dialog.message, response.dialog.options, response.snackbar.redirect);
          delete response.dialog;
        }

        return response;
      } catch (result) {
        if (window.Techship.application.isDev) {
          console.log('Request error');
          console.error(result);
        }
        if (!(result instanceof RequestAbortedError)) {
          const errors = result.errors || [];
          const warnings = result.warnings || [];
          // Ensure params.handleWarning is properly checked against the existence of the param.
          const handleError = true;
          const handleWarning = 'handleWarning' in params ? params.handleWarning : true;

          if (handleError && isNotEmpty(errors)) {
            return this.errorHandler(errors, this);
          } else if (handleWarning && isNotEmpty(warnings)) {
            return this.warningHandler(warnings, this);
          }
        }
        // TODO: Check if this is necessary
        // if (result.message) {
        //   this.$snackbar.error(result.message);
        // }

        return false;
      }
    },
    get(url = '', params = {}, cancelToken) {
      return this.request(url, params, 'GET', cancelToken);
    },
    post(url = '', params = {}, cancelToken) {
      return this.request(url, params, 'POST', cancelToken);
    },
    put(url = '', params = {}, cancelToken) {
      return this.request(url, params, 'PUT', cancelToken);
    },
    delete(url = '', params = {}, cancelToken) {
      return this.request(url, params, 'DELETE', cancelToken);
    },

    async errorHandler(errors, viewModel = {}) {
      this.handleContentErrors(errors);
    },
    async validateModel(model, data) {
      const validationResponse = await this.post(`/api/${model}/validate/`, {
        data: {
          [model]: data
        }
      });
      if (validationResponse && validationResponse.data.valid) {
        return true;
      }
      return false;
    }
  }
};
