<template>
  <div>
    <div v-if="menu" class="dimmed-background" />
    <VMenu v-model="menu" :close-on-content-click="false" location="bottom">
      <template #activator="{ props }">
        <div v-bind="props" class="w-auto" role="search">
          <VTextField ref="searchInput" v-model="query" type="search" :class="[{ highlight: menu }]" :placeholder="strapi.Placeholder" aria-label="Search" prepend-inner-icon="far fa-search" density="compact" variant="plain" :loading="loading" hide-details clearable persistent-placeholder @keydown.enter="navigateToSearchResults" @focus="onFocus">
            <template #clear>
              <VIcon icon="fas fa-circle-xmark" size="small" class="mr-2" @click="onClear" />
            </template>
          </VTextField>
        </div>
      </template>
      <Card rounded="t-0 b-lg">
        <VContainer v-scroll-lock="true" class="pt-6 pb-8 px-4 px-md-10 max-h-500px max-h-md-700px search-container overflow-y-auto">
          <!-- <VContainer class="pt-6 pb-8 px-4 px-md-10 max-h-500px max-h-md-700px search-container"> -->
          <template v-if="$utils.isNotEmpty(results)">
            <VCardSubtitle class="d-flex">
              <span class="text-grey-dark">{{ $sprintf(strapi.ResultsFound, totalRecords, query) }}</span>
              <a class="underline-primary text-black" aria-label="view all search results" :href="searchResultQuery()">{{ strapi.ViewAll }}</a>
            </VCardSubtitle>
            <!-- <VList v-scroll-lock="true" class="pa-0 pb-10"> -->
            <VList class="pa-0 pb-10">
              <template v-for="(result, index) in results" :key="index">
                <VDivider class="mt-7 mb-6" />
                <span class="text-grey-dark paragraph-small">{{ result.label }} ({{ result.records }})</span>
                <div class="mt-2">
                  <VListItem v-for="(item, _index) in result.items" :key="_index" class="pl-0 py-1" :href="item.url" min-height="30px">
                    <div class="list-item-container d-flex align-start">
                      <VIcon size="11" color="primary" icon="fas fa-chevron-right" class="slide-icon" />
                      <p class="font-weight-bold fs-15 ma-0 slide-text">{{ item.title }}</p>
                    </div>
                  </VListItem>
                </div>
                <a v-if="result.records > 3" aria-label="show more results" class="underline-primary text-black paragraph-small" :href="searchResultQuery(index)">{{ strapi.More }}</a>
              </template>
            </VList>
          </template>
          <p v-else class="paragraph-small text-grey-dark ma-0" v-html="strapi.HelpText" />
        </VContainer>
      </Card>
    </VMenu>
  </div>
</template>

<script>
import { debounce } from '@Utils/default';

export default {
  name: 'SearchBar',
  emits: ['onMenuChange'],
  data() {
    return {
      loading: false,
      strapi: this.$dom.application.searchBar,
      endpoint: '/search',
      searchResult: [],
      query: '',
      menu: false,
      querySetFirstTime: false
    };
  },
  computed: {
    totalRecords() {
      return this.searchResult.totalRecords;
    },
    results() {
      return this.searchResult.results;
    }
  },
  watch: {
    menu(val) {
      // console.log('Serchbar menu', val);
      this.$emit('onMenuChange', val);
    },
    results() {
      this.menu = true;
    },
    query: debounce(function (query) {
      if (this.querySetFirstTime) {
        // If the query is being set for the first time, reset the flag and return without fetching items
        this.querySetFirstTime = false;
        return;
      }
      this.fetchItems(query);
    }, 500)
  },
  created() {
    const query = this.getURLParameter('query');
    if (query) {
      this.querySetFirstTime = true;
      this.query = query;
    }
  },
  methods: {
    onFocus() {
      this.fetchItems(this.query);
    },
    searchResultQuery(type) {
      return `/search/?query=${encodeURIComponent(this.query)}&type=${type ?? 'products'}&page=1`;
    },
    navigateToSearchResults() {
      this.$redirect(this.searchResultQuery());
    },
    onClear() {
      this.query = '';
    },
    async fetchItems(query) {
      if (this.$utils.isBlank(query)) {
        this.searchResult = [];
        return;
      }
      this.loading = true;
      const response = await this.post(`${this.endpoint}/`, { query: query });
      if (!response) return;
      this.searchResult = response;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dimmed-background {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 0;
  z-index: 1000;
}

:deep(.v-input.highlight .v-field) {
  background: $color-secondary;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  @include is-screen-md() {
    min-width: 478px;
  }

  @include is-screen-lg() {
    min-width: unset;
  }

  &::before {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

:deep(.v-field) {
  position: relative;
  overflow: initial;
  background: transparent;
  border-radius: 8px;
  color: $color-white;
  z-index: 1001;
  height: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-body-background, 0.08);
    z-index: -1;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  .v-field__clearable {
    padding: 0;
    display: flex;
    align-items: center;
    opacity: 0.3 !important;
    &:hover {
      opacity: 1 !important;
    }
  }
  .v-field__field input {
    background-color: transparent;
    color: $color-primary;
    caret-color: $color-white;
    padding-top: 13px;
    &::placeholder {
      color: $color-white;
      opacity: 0.5 !important;
    }
    .v-field__field:hover {
      height: 200px;
      width: 100%;
    }
  }
  .v-field__prepend-inner {
    padding-top: 6px;
    padding-left: 12px;
    padding-bottom: 6px;
    padding-right: 0px;

    display: flex;
    align-items: center;
    .v-icon {
      opacity: 1 !important;
    }
  }
}

:deep(.v-overlay__content) {
  max-width: min-content !important;
}

:deep(.v-card-subtitle) {
  opacity: 1 !important;
  justify-content: space-between;
  padding: 0;
}

:deep(.v-list-item__content) {
  p {
    font-size: 15px !important;
  }
}

:deep(.v-list-item__overlay) {
  display: none;
}

.list-item-container {
  position: relative;

  &:hover {
    .slide-text,
    .slide-icon {
      transform: translateX(15px);
    }
  }
  .slide-text {
    position: relative !important;
  }

  .slide-icon,
  .slide-text {
    transition: all 0.3s;
    transform: translateX(0);
  }

  .slide-icon {
    left: -15px;
    top: 3px;
    position: absolute !important;
  }
}
</style>
