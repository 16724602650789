<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex">
      <VIcon :icon="strapi.SectionIcon" size="25px" class="mr-3" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.SectionTitle }}</h6>
    </header>
    <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
    <Form :disabled="loading" grid :columns="isEditing ? '1fr' : isMobile ? '2fr 1fr' : '1fr 1fr'" :isEditing="isEditing" @submit.prevent="save">
      <label for="subscriptionNewsletter">{{ strapi.NewsletterSubscriptionLabel }}</label>
      <SelectBoolean v-if="isEditing" id="subscriptionNewsletter" v-model="user.newsletterSubscription" forceBool name="subscriptions.newsletterSubscription" />
      <span v-else>{{ user.newsletterSubscription ? 'Yes' : 'No' }}</span>
      <label for="abandonedCartReminder">{{ strapi.AbandonedCartReminderLabel }}</label>
      <SelectBoolean v-if="isEditing" id="abandonedCartReminder" v-model="abandonedCartReminderComputed" name="subscriptions.abandonedCardReminder" />
      <span v-else>{{ user.disabled_abandoned_cart_reminder_at ? 'No' : 'Yes' }}</span>
      <template #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer v-if="isEditing" class="d-flex" :class="{ 'flex-column': isMobile }">
          <Button color="secondary" variant="outlined" :disabled="loading" :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" :block="isMobile" :loading="loading" @click="save">
            {{ strapi.SaveButtonLabel }}
          </Button>
          <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="cancel">
            {{ strapi.CancelButtonLabel }}
          </Button>
        </footer>
        <footer v-else class="d-flex" :class="{ 'flex-column': isMobile }">
          <Button color="secondary" variant="outlined" :block="isMobile" :disabled="editingIsDisabled" :prependIcon="strapi.EditButtonIcon" @click="edit">
            {{ strapi.EditButtonLabel }}
          </Button>
        </footer>
      </template>
    </Form>
  </Card>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'Subscriptions',
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: { ...this.modelValue },
      loading: false,
      newletterCategories: null
    };
  },
  computed: {
    abandonedCartReminderComputed: {
      get() {
        return this.user.disabled_abandoned_cart_reminder_at === null;
      },
      set(value) {
        this.user.disabled_abandoned_cart_reminder_at = value ? null : true;
      }
    }
  },
  methods: {
    edit() {
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.user = { ...this.modelValue };
    },
    async save() {
      this.loading = true;
      this.response = await this.post('subscriptions/', {
        subscriptions: {
          newsletterSubscription: this.$utils.toBoolean(this.user.newsletterSubscription),
          abandonedCartReminder: this.user.disabled_abandoned_cart_reminder_at === null ? false : true
        }
      });

      if (!this.response) {
        this.loading = false;
        return;
      }

      this.reloadWithTimeout();
    }
  }
};
</script>

<style lang="scss" scoped></style>
