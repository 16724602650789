export default {
  install: Vue => {
    Vue.directive('strip-html', {
      beforeMount(el) {
        function stripHtmlTags(input) {
          return input.replace(/<[^>]*>/g, '');
        }

        function stripHtmlFromNode(node) {
          if (node.nodeType === Node.TEXT_NODE) {
            node.textContent = stripHtmlTags(node.textContent);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            Array.from(node.childNodes).forEach(stripHtmlFromNode);
          }
        }

        stripHtmlFromNode(el);
      }
    });
  }
};
